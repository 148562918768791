import React from 'react'
import { withRouter } from "react-router-dom";

class Logout extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let confirmLogout = window.confirm(`Logout dari Aplikasi ?`)

    if (confirmLogout) {
      localStorage.removeItem("user");
      this.props.history.push('/login');
      //window.location.reload();
    }
  }

  render() {
    return(
      <></>
    )
  }
}

export default withRouter(Logout)