import React from 'react'
import { Button, Table, Row, Col, Badge } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class SimulasiArbitScanForm extends React.Component {
    state = {
        contractAddress: '',
        tokenHistory: null
     }

    componentDidMount() {
     }

    tokenHistory = () => {
        if ( this.props.url_explorer ) {
            fetch(API_URL + `ext/token-history`, {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    url_explorer: this.props.url_explorer,
                })
            })
            .then(async(res) => {
                const tokenHistory = await res.json()
                this.setState({tokenHistory})
            })
            .catch(err => console.log("tokenHistory Error: " + err))
        } else console.log('url_Explorer kosong')
    }

    decimal3 = (x) => Number.parseFloat(x).toFixed(3);

     render() {
        let dataTokenHistory = ''

        if ( this.state.tokenHistory !== null ) {
            dataTokenHistory = this.state.tokenHistory.map(history => {
                const shortTxHash = history.shortTxHash
                const timestamp = history.timestamp
                const from = history.from
                const to = history.to
                const quantity = this.decimal3(history.quantity)
					 const url_txn = `https://etherscan.io/tx/${history.transactionHash}`
    
                return (
                    <tr key={history.transactionHash} style={{ fontSize: 13, fontWeight: 'normal'}}>
                        <td> <a href={url_txn} target={'_blank'}> {shortTxHash} </a> </td>
                        <td>{timestamp}</td>
                        <td>{from}</td>
                        <td>{to}</td>
                        <td align="right">{quantity}</td>
                    </tr>
                )
            });
        }
        
        return (
            <>
                <Row form>
                    <Col md={12} className="text-center text-md-center">
                        <Button color="primary" size="sm" onClick={() => this.tokenHistory()}>Refresh</Button> {' '}
                        <a href={`${this.props.url_explorer}`} target={'_blank'}>
                            View Explorer
                            {/* <Badge color="warning" style={{ cursor: "pointer" }}> View Explorer </Badge> */}
                        </a>

                        <Table responsive hover striped size="sm" dark>
                            <thead>
                                <tr>
                                    <th> TxnHash </th>
                                    <th> Age </th>
                                    <th> From </th>
                                    <th> To </th>
                                    <th> Quantity </th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataTokenHistory}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        )
     }
}

export default SimulasiArbitScanForm