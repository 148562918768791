import React from 'react'
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class ExchangeMarketForm extends React.Component {
   state = {
      exchange_market_id:0,
      koin:'',
      pair:'', 
      koin_desimal: '',
      koin_id: '',
      pair_id: '',
      url_koin_cmc:'',
      url_explorer:'',
      koin_desimal:0,
      pair_desimal:0,
      convert_to:'',
      network:'',
      aktif:false
   }

   onChange = e => {
      this.setState({[e.target.name]: e.target.value})
      console.log(`aktif: ${this.state.aktif}`)
   }

   handleChecked = (e) => {
      this.setState(initialState => {
			return { aktif: !initialState.aktif }
		});
    }

   submitExchangeMarketFormEdit = e => {
      e.preventDefault()
      fetch(API_URL + `exchange-market/${this.state.exchange_market_id}`, {
         method: 'PUT',
         headers: authHeader(),
         body: JSON.stringify({
            exchange_market_id: this.state.exchange_market_id,
            koin: this.state.koin,
            pair: this.state.pair,
            url_koin_cmc: this.state.url_koin_cmc,
            url_explorer: this.state.url_explorer,
            koin_id: this.state.koin_id,
            pair_id: this.state.pair_id,
            koin_desimal: this.state.koin_desimal,
            pair_desimal: this.state.pair_desimal,
            aktif: this.state.aktif
         })
      })
      .then(response => response.json())
      .then(market=>{
         //if (Array.isArray(market)) {
            //this.props.updateExchangeMarketState(market)
            this.props.toggle()
         //} else { console.log("failure update exchange-market") }
      })
      .catch(err=>console.log(err))
   }

   componentDidMount() {
      if (this.props.market) {
         const { exchange_market_id, koin, pair, koin_id, pair_id, koin_desimal, pair_desimal, url_koin_cmc, url_explorer, convert_to, network, aktif } = this.props.market
         this.setState({exchange_market_id, koin, pair, koin_id, pair_id, koin_desimal, pair_desimal, url_koin_cmc, url_explorer, convert_to, network, aktif})
      }
   }

   render() {
      return (
         <>
         <Form onSubmit={this.props.market ? this.submitExchangeMarketFormEdit : this.submitExchangeMarketFormAdd}>
            <Row form>
               <Col md={6}>
                  <FormGroup>
                     <Label for="koin">Koin</Label>
                     <Input type="text" name="koin" id="koin" onChange={this.onChange} value={this.state.koin===null ? '' : this.state.koin} />
                  </FormGroup>
               </Col>
               <Col md={6}>
                  <FormGroup>
                     <Label for="pair">Pair</Label>
                     <Input type="text" name="pair" id="pair" onChange={this.onChange} value={this.state.pair===null ? '' : this.state.pair} />
                  </FormGroup>
               </Col>
            </Row>
            <Row form>
               <Col>
                  <FormGroup>
                     <Label for="url_koin_cmc">Url CMC</Label>
                     <Input type="text" name="url_koin_cmc" id="url_koin_cmc" onChange={this.onChange} value={this.state.url_koin_cmc===null ? '' : this.state.url_koin_cmc} />
                  </FormGroup>
               </Col>
            </Row>
            <Row form>
               <Col>
                  <FormGroup>
                     <Label for="url_explorer">Url Explore</Label>
                     <Input type="text" name="url_explorer" id="url_explorer" onChange={this.onChange} value={this.state.url_explorer===null ? '' : this.state.url_explorer} />
                  </FormGroup>
               </Col>
            </Row>

            <Row form>
               <Col md={6}>
                  <FormGroup>
                  <Label for="koin_id">Koin ID</Label>
                     <Input type="text" name="koin_id" onChange={this.onChange} value={this.state.koin_id===null ? '' : this.state.koin_id} />
                  </FormGroup>
               </Col>
               <Col md={6}>
               <FormGroup>
                  <Label for="koin_id">Pair ID</Label>
                     <Input type="text" name="pair_id" onChange={this.onChange} value={this.state.pair_id===null ? '' : this.state.pair_id} />
                  </FormGroup>
               </Col>
            </Row>

            <Row form>
               <Col md={6}>
                  <FormGroup check>
                  <Label for="convert_to">Convert To</Label>
                     <Input type="text" name="convert_to" onChange={this.onChange} value={this.state.convert_to===null ? '' : this.state.convert_to} />
                  </FormGroup>
               </Col>
               <Col md={6}>
                  <FormGroup check>
                     <Label for="network"> Network </Label>
                     <Input type="text" name="network" onChange={this.onChange} value={this.state.network===null ? '' : this.state.network} />
                  </FormGroup>
               </Col>
            </Row>

            <Row form>
               <Col md={6}>
                  <FormGroup check>
                  <Label for="koin_desimal">Koin Desimal</Label>
                     <Input type="text" name="koin_desimal" onChange={this.onChange} value={this.state.koin_desimal===null ? '' : this.state.koin_desimal} />
                  </FormGroup>
               </Col>
               <Col md={6}>
                  <FormGroup check>
                  <Label for="aktif"> . </Label>
                     <Input type="checkbox" name="aktif" id="aktif" onChange={this.handleChecked} checked={this.state.aktif == 0 ? false : this.state.aktif } />
                     <Label for="aktif">Aktif</Label>
                  </FormGroup>
               </Col>
            </Row>
            <Row form>
               <Col md={6}>{' '}</Col>
               <Col md={6}> <Button>Simpan</Button> </Col>
            </Row>
         </Form>
         </>
      )
   }
}

export default ExchangeMarketForm