import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import WalletForm from '../Forms/Wallet.Form'

export default class WalletModal extends Component {
    constructor(props) {
		super(props)
		this.state = { modal: false }
    }
    
    toggle = () => {
		this.setState(prevState => ({
			modal: !prevState.modal
		}))
    }
    
    render() {
        const closeBtn = <button className="close" onClick={this.toggle}> &times; </button>
		const label = this.props.buttonLabel
		let button = ''
        let title = ''
        
        if (label === "Edit") {
			button = 
				<Button color="warning"
					onClick={this.toggle}
					style={{ float: "left", marginRight: "10px"}} >
					{label}
				</Button>
				title = "Edit Wallet"
		} else {
			button = 
				<Button color="success"
					onClick={this.toggle}
					style={{ float: "left", marginRight: "10px" }}>
						{label}
				</Button>
				title = "Add Wallet"
        }
        
        return (
			<>
				{button}
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
					<ModalHeader toggle={this.toggle} close={closeBtn}> {title}	</ModalHeader>
					<ModalBody>
						<WalletForm 
							addWalletToState={this.props.addWalletToState}
							updateWalletState={this.props.updateWalletState}
							getWallets={this.props.getWallets}
							toggle={this.toggle}
                            wallet={this.props.wallet}
                            exchange_id={this.props.exchange_id} />
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.toggle}>Submit</Button>
						<Button color="secondary" onClick={this.toggle}>Batal</Button>
					</ModalFooter>
				</Modal>
			</>
        )
    }
}