import React from 'react'
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class ExchangeForm extends React.Component {
	state = {
		exchange_id: '',
		nama_exchange: '',
		url: '',
		api_endpoint: '',
		alias: '',
		aktif: 0,
		convert_to: '',
		network: ''
	}

	onChange = e => {
		this.setState({[e.target.name]: e.target.value})
	}

	submitExchangeFormAdd = e => {
		e.preventDefault()
		fetch(API_URL , {
			method: 'post',
			headers: authHeader(),
			body: JSON.stringify({
				exchange_id: this.state.exchange_id,
				nama_exchange: this.state.nama_exchange,
				url: this.state.url,
				api_endpoint: this.state.api_endpoint,
				alias: this.state.alias,
				aktif: this.state.aktif,
				convert_to: this.state.convert_to,
				network: this.state.network
			})
		})
		.then(response => response.json())
		.then(exchange => {
			if (Array.isArray(exchange)) {
				this.props.addExchangeToState(exchange[0])
				this.props.toggle()
			} else { console.log("failure") }
		})
		.catch(err => console.log( err ))
	}

	submitExchangeFormEdit = e => {
		e.preventDefault()
		fetch(API_URL + `exchange/${this.state.exchange_id}`, {
			method: 'put',
			headers: authHeader(),
			body: JSON.stringify({
				exchange_id: this.state.exchange_id,
				nama_exchange: this.state.nama_exchange,
				url: this.state.url,
				api_endpoint: this.state.api_endpoint,
				alias: this.state.alias,
				aktif: this.state.aktif,
				convert_to: this.state.convert_to,
				network: this.state.network
			})
		})
		.then(response => response.json())
		.then(exchange => {
			//if (Array.isArray(exchange)) {
				console.log(`exchange updated: `)
				this.props.updateExchangeState(exchange)
				this.props.toggle()
			//} else { console.log("failure") }
		})
		.catch( err => console.log(err) )
	}

	onChangeExchangeAktif = (event) => {
		this.setState(initialState => {
			return { aktif: !initialState.aktif }
		});
	}

	componentDidMount() {
		// if exchange exist , populate the state with proper data 
		if (this.props.exchange) {
			const { exchange_id, nama_exchange, url, api_endpoint, alias, aktif, convert_to, network } = this.props.exchange
			this.setState({exchange_id, nama_exchange, url, api_endpoint, alias, aktif, convert_to, network})
		}
	}

	render() {
		return (
			<>
			<Form onSubmit={this.props.exchange ? this.submitExchangeFormEdit : this.submitExchangeFormAdd}>
				<Row form>
					<Col md={6}>
						<FormGroup>
							<Label for="exchange_id">I D</Label>
							<Input type="text" name="exchange_id" id="exchange_id" 
								onChange={this.onChange} value={this.state.exchange_id === null ? '' : this.state.exchange_id}
								disabled = { (this.state.exchange_id === '') ? "" : "disabled" } />
						</FormGroup>
					</Col>
					<Col md={6}>
						<FormGroup>
							<Label for="nama_exchange">Nama</Label>
							<Input type="text" name="nama_exchange" id="nama_exchange" 
								onChange={this.onChange} value={this.state.nama_exchange === null ? '' : this.state.nama_exchange} />
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={6}>
						<FormGroup>
							<Label for="url">U R L</Label>
							<Input type="text" name="url" id="url"
								onChange={this.onChange} value={this.state.url === null ? '' : this.state.url} />
						</FormGroup>
					</Col>
					<Col md={6}>
						<FormGroup>
							<Label for="api_endpoint">Api Endpoint</Label>
							<Input type="text" name="api_endpoint" id="api_endpoint"
								onChange={this.onChange} value={this.state.api_endpoint === null ? '' : this.state.api_endpoint} />
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={6}>
						<FormGroup>
							<Label for="convert_to">Konversi</Label>
							<Input type="text" name="convert_to" id="convert_to"
								onChange={this.onChange} value={this.state.convert_to === null ? '' : this.state.convert_to} />
						</FormGroup>
					</Col>
					<Col md={6}>
						<FormGroup>
							<Label for="network">Network</Label>
							<Input type="text" name="network" id="network"
								onChange={this.onChange} value={this.state.network === null ? '' : this.state.network} />
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={6}>
						<FormGroup>
							<Label for="alias">Alias Exchange</Label>
							<Input type="text" name="alias" id="alias"
								onChange={this.onChange} value={this.state.alias === null ? '' : this.state.alias} />
						</FormGroup>
					</Col>
					<Col md={6}>
						<FormGroup check>
							<Input type="checkbox" name="aktif" id="aktif" 
								onChange={this.onChangeExchangeAktif} checked={this.state.aktif === null ? true : this.state.aktif } />
							<Label for="aktif" check>Aktif</Label>
						</FormGroup>
					</Col>
				</Row>
				<Row>
						<Col md={6}>{ ' ' }</Col>
						<Col md={6}> <Button>Simpan</Button> </Col>
					</Row>
			</Form>
			</>
		)
	}
}

export default ExchangeForm
