import React, { Component } from 'react'
import { Table, Button } from 'reactstrap'
import UsersModal from '../Modals/Users.Modal'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

export default class UsersTable extends Component {
    deleteUser = (id) => {
		let confirmDelete = window.confirm(`Hapus Data User (${id}) ?`)
		if ( confirmDelete ) {
			fetch(API_URL + `users/delete`,
				{
					method: 'DELETE',
					//headers: { 'Content-Type': 'application/json' },
					headers: authHeader(),
					body: JSON.stringify({ username : id })
				}
			)
				.then(response => response.json())
				.then(item => { this.props.deleteUsersFromState(id) })
				.catch(err => console.log(`err: ${err}`))
		}
	}

	render() {

		const users = this.props.users.map(user => {
			return (
                <tr key={user.username}>
                    <th scope="row">{user.username}</th>
                    <td>{user.waktu}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{user.nama_lengkap}</td>
					<td>{user.ulevel}</td>
					<td>{user.aktif}</td>
                    <td>
                        <div style={{ width: "120px" }}>
                            <UsersModal buttonLabel="Edit" user={user} updateUsersState={this.props.updateUsersState} />
                            { ' ' }
                            <Button color="danger" onClick={() => this.deleteUser(user.username)}> Del </Button>
                        </div>
                    </td>
                </tr>
            )
		});

		return (
			<Table responsive hover striped size="sm" dark>
				<thead>
					<tr>
						<th> # </th>
						<th> Waktu </th>
						<th> Username </th>
						<th> E-Mail </th>
						<th> Nama Lengkap </th>
						<th> Level </th>
						<th> Sts </th>
						<th> AKSI </th>
					</tr>
				</thead>
				<tbody>
          {users}
        </tbody>
			</Table>
		)
	}
}