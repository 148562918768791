import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import UsersExchangeTable from './Tables/UsersExchange.Table'
import UsersExchangeModal from './Modals/UsersExchange.Modal.js'

import authHeader from '../services/auth-header';
import Config from '../Config';
const API_URL = Config.API_URL

export default class ProfileUsersExchange extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            users_exchange: []
        }
    }

    componentDidMount() {
        this.getUsersExchange()
    }

    getUsersExchange = () => {
        const user = JSON.parse(localStorage.getItem('user'));
		const username = user.username

		fetch(API_URL + `${username}/users-exchange`, {
			method: 'GET',
			headers: authHeader()
		})
			.then(response => response.json())
			.then(users_exchange => this.setState({users_exchange}))
			.catch(err => console.log(`err: ${err}`))
    }
    
    addUsersExchangeToState = (user_exchange) => {
		this.setState(prevState => ( {
			users_exchange: [...prevState.users_exchange, user_exchange]
		}))
    }
    
    updateUsersExchangeState = (user_exchange) => {
		const UsersExchangeIndex = this.state.users_exchange.findIndex(data => data.users_exchange_id === user_exchange.users_exchange_id)
		const newArray = [
			// desctructure all users-exchange from beginning to the indexed users-exchange
			...this.state.users_exchange.slice(0, UsersExchangeIndex),
			// add the updated users-exchange to the array
			user_exchange,
			// add the rest of the users to the array from the index after the replaced user
			...this.state.users_exchange.slice(UsersExchangeIndex + 1)
		]

		this.setState({users_exchange: newArray})
    }
    
    deleteUsersExchangeFromState = (id) => {
		const updatedUsersExchange = this.state.users_exchange.filter(user_exchange => user_exchange.users_exchange_id !== id)
		this.setState({users_exchange: updatedUsersExchange})
    }
    
    render() {
        return(
            <>
                <Row>
                    <Col>
                        <UsersExchangeTable users_exchange={this.state.users_exchange}
                            updateUsersExchangeState={this.updateUsersExchangeState}
                            deleteUsersExchangeFromState={this.deleteUsersExchangeFromState} />
                    </Col>
                </Row>
                <Row>
					<Col>
						<UsersExchangeModal buttonLabel="Tambah Exchange Config" addUsersExchangeToState={this.addUsersExchangeToState} />
						<br/>
					</Col>
				</Row>
            </>
        )
    }
}