import React, {Component} from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import ExchangeMarketForm from '../Forms/ExchangeMarket.Form'

class ExchangeMarketModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }

  render() {
    const closeBtn = <button className="close" onClick={this.toggle}> &times; </button> 
    const label = this.props.buttonLabel
    let button = ''
    let title = ''

    if (label === "Edit") {
      button = <Button color="warning" onClick={this.toggle} style={{float: "left", marginRight: "10px"}}>{label}</Button>
      title = "Edit Market"
    } else {
      button = <Button color="success" onClick={this.toggle} style={{float: "left", marginRight: "10px"}}>{label}</Button>
      title = "Add Market"
    }

    return(
      <div>
        {button}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle} close={closeBtn}> {title} </ModalHeader>
          <ModalBody>
            <ExchangeMarketForm 
              toggle={this.toggle}
              market={this.props.market}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}> Simpan </Button>
            { ' ' }
            <Button color="secondary" onClick={this.toggle}>Batal</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default ExchangeMarketModal