import React, { Component } from 'react'
import { Table, Button } from 'reactstrap'
import MarketKonversiModal from '../Modals/MarketKonversi.Modal'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class MarketKonversiTable extends Component {
	deleteMarketKonversi = (id) => {
		let confirmDelete = window.confirm(`Hapus Konversi Market Pair (${id}) ?`)
		if ( confirmDelete ) {
			fetch(API_URL + `market-konversi/delete/${id}`,
				{
					method: 'delete',
					headers: authHeader(),
					//credentials: "include",
					//body: JSON.stringify({ 'market_konversi_id': id })
				}
			)
			.then(response => response.json())
			.then(item => { this.props.deleteMarketKonversiFromState(id) })
			.catch(err => console.log(`err: ${err}`))
		}
	}

	render() {
		const markets_konversi = this.props.markets_konversi.map(market_konversi => {
			return (
				<tr key={market_konversi.market_konversi_id}>
					<th scope="row"> {market_konversi.market_konversi_id} </th>
					<td> {market_konversi.exchange_id} </td>
					<td> {market_konversi.convert_to} </td>
					<td> {market_konversi.pair} </td>
					<td> {market_konversi.market_koin} </td>
					<td> {market_konversi.market_pair} </td>
					<td align="right"> {market_konversi.ask} </td>
					<td align="right"> {market_konversi.bid} </td>
					<td> {market_konversi.network} </td>
					<td> {market_konversi.aktif} </td>
					<td>
						<div style={{width: "120px"}}>
							<MarketKonversiModal buttonLabel="Edit" 
								market_konversi={market_konversi} 
								updateMarketKonversiState={this.props.updateMarketKonversiState} 
								getMarketsKonversi={this.props.getMarketsKonversi}
							/>
							{ ' ' }
							<Button color="danger" onClick={() => this.deleteMarketKonversi(market_konversi.market_konversi_id)}> Del </Button>
						</div>
					</td>
				</tr>
			)
		})

		return (
			<Table responsive hover striped size="sm" dark>
				<thead>
					<tr>
						<th> ID </th>
						<th> Exchange </th>
						<th> Konversi </th>
						<th> Pair </th>
						<th> MarkKoin </th>
						<th> MarkPair </th>
						<th> ASK </th>
						<th> BID </th>
						<th> NetworkID </th>
						<th> Aktif </th>
						<th> AKSI </th>
					</tr>
				</thead>
				<tbody>
					{markets_konversi}
				</tbody>
			</Table>
		)
	}
}

export default MarketKonversiTable