import React, { Component } from 'react'
import { Container, Form, Row, Col, Input, Button, FormGroup, Label,
        Nav, NavItem, NavLink, TabContent, TabPane,
        InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import classnames from 'classnames';

import LogAIOTable from './Tables/LogAIOTrade.Table'

import authHeader from '../services/auth-header';
import Config from '../Config';
const API_URL = Config.API_URL

export default class WebLogs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeTab: '1', 
            filename: 'passenger.log',
            logs: '',
            extOrderbooks:'',

            exchanges: [], 
            networks: [],
            converts: [],
            exchange_id: '',
            network: 'ETH',
            convert_to: 'ETH',

            logs_aio: []
        }
    }

    componentDidMount() {
        this.getNetworkConvert()
        this.getLogsAIO()
    }

    setActiveTab = tab => {
		this.setState({activeTab: tab})
	}

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    readWebLogs = async () => {
        this.setState({logs: ''})

        fetch(API_URL + 'logs', {
            method: 'post',
            //headers: {'Content-Type': 'application/json'},
            headers: authHeader(),
            body: JSON.stringify({
                filename: this.state.filename,
            })
        })
        .then(async response => { 
            return await response.text()
        })
        .then(res => {
            this.setState({logs: res.toString()},
                () => {
                    console.log( "logs: " + res )
                })
        })
    }

    writeWebLogs = async () => {
        fetch(API_URL + 'logs/write', {
            method: 'post',
            //headers: {'Content-Type': 'application/json'},
            headers: authHeader(),
            body: JSON.stringify({
                filename: this.state.filename,
                logs: this.state.logs
            })
        })
        .then(async response => { 
            return await response.text()
        })
    }

    getExchanges() {
        fetch(API_URL + `exchange-aktif/${this.state.convert_to}/${this.state.network}`, {
            method: 'GET',
            headers: authHeader()
        })
        .then(response => response.json())
        .then(exchanges => this.setState({ exchanges }))
        .catch(err => console.log(err))        
    }

    getNetworkConvert = () => {
		fetch(API_URL + `profile-system/network-convert`, {
			method: 'GET',
			headers: authHeader()
		})
		.then(async res => {
			const network_convert = await res.json()
			//let uniq = [ ...new Set(names) ]
			let networks = [], converts = []

			Object.entries(network_convert).forEach( ([k1, v1])=> {
				networks.push( {'network':v1['network']} );
				converts.push( {'network':v1['network'], 'convert_to':v1['convert_to'] } )
			})

			const uniqueNetworks = [...new Map(networks.map(item => [item.network, item])).values()]
			this.setState({'networks': uniqueNetworks })
			this.setState({'converts': converts})
		})
	}

    setConvertTo = (convert_to) => {
		this.setState({convert_to})
	}

	getConvertTo = () => {
		console.log(`getConvertTo ${this.state.convert_to}`)
		return this.state.convert_to
	}

    convertToChange = (e) => {
		this.setState({ [e.target.name]: e.target.value }, () => {
            console.log(`getConvertTo ${this.state.convert_to}`)
            this.getExchanges()
        });
		this.setConvertTo( e.target.value )
        
        
	}

    networkChange = (e) => {
		this.setState({ [e.target.name]: e.target.value }, ()=> {
            console.log(`getNetwork ${this.state.network}`)
            this.getExchanges()
        });
		this.setNetwork( e.target.value )
        
	}

	setNetwork = (network) => {
		this.setState({network})
	}

	getNetwork = () => {
		console.log(`getNetwork ${this.state.network}`)
		return this.state.network
	}

    readExtOrderbooks = async () => {
        this.setState({logs: ''})

        fetch(API_URL + 'logs/external-orderbooks', {
            method: 'post',
            //headers: {'Content-Type': 'application/json'},
            headers: authHeader(),
            body: JSON.stringify({
                "exchange_id": this.state.exchange_id,
                "network": this.state.network,
                "convert_to": this.state.convert_to
            })
        })
        .then(async response => { 
            return await response.text()
        })
        .then(res => {
            this.setState({"extOrderbooks": res.toString()},
                () => {
                    //console.log( "logs: " + res )
                })
        })
    }

    getLogsAIO() {
        const username = this.getCurrentUser()

		fetch(API_URL + `${username}/log-aio`, {
			method: 'GET',
			headers: authHeader()
		})
        .then(response => response.json())
        .then(logs_aio => this.setState({ logs_aio }))
        .catch(err => console.log(err))
	}

    deleteLogAIOFromState = (id) => {
		const updatedLogs = this.state.logs_aio.filter(log => log.autoid !== id)
		this.setState({logs_aio: updatedLogs})
	}

    getCurrentUser = () => {
		const user = JSON.parse(localStorage.getItem('user'))
        return user.username
	}

    render() {
        const toggleTab = tab => {
			if (this.state.activeTab !== tab) this.setActiveTab(tab);
		}

        return (
            <Container className="App">
                <Nav tabs>
					<NavItem>
						<NavLink style={{ cursor: "pointer", fontSize: 12, backgroundColor: this.state.activeTab === '1' ? 'Orange' : 'DarkGrey' }}
							className={classnames({ active: this.state.activeTab === '1' })}
							onClick={() => { toggleTab('1'); }}>
							Node-Log
            			</NavLink>
					</NavItem>

                    <NavItem>
						<NavLink style={{ cursor: "pointer", fontSize: 12, backgroundColor: this.state.activeTab === '2' ? 'Orange' : 'DarkGrey' }}
							className={classnames({ active: this.state.activeTab === '2' })}
							onClick={() => { toggleTab('2'); }}>
							Arbit-log
            			</NavLink>
					</NavItem>

					<NavItem>
						<NavLink style={{ cursor: "pointer", fontSize: 12, backgroundColor: this.state.activeTab === '3' ? 'Orange' : 'DarkGrey' }}
							className={classnames({ active: this.state.activeTab === '3' })}
							onClick={() => { toggleTab('3'); }}>
							Ext-Orderbooks
            			</NavLink>
					</NavItem>

				</Nav>

                <TabContent activeTab={this.state.activeTab}>
					<TabPane tabId="1" style={{ backgroundColor: 'Orange' }}>
                        <Row>
                            <Col sm={12}>
                            <InputGroup size="sm">
                                <InputGroupAddon addonType="prepend"> Nama </InputGroupAddon>
                                <InputGroupAddon addonType="append">
                                    <Input type="text" id="filename" name="filename" onChange={() => this.handleChange} 
                                        value={this.state.filename === null ? '' : this.state.filename} />
                                </InputGroupAddon>
                                <InputGroupAddon addonType="append">
                                    <Button color="primary" onClick={this.readWebLogs}>Load</Button> {' '}
                                    <Button color="danger" onClick={() => this.setState({ logs: '' })}>Clear All</Button> {' '}
                                    <Button color="success" onClick={this.writeWebLogs}>Save</Button>
                                </InputGroupAddon>
                            </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Input type="textarea" name="logs" onChange={this.handleChange} value={this.state.logs === null ? '' : this.state.logs} style={{ height: 400 }} disabled={true} />
                            </Col>
                        </Row>
					</TabPane>

                    <TabPane tabId="2" style={{ backgroundColor: 'Orange' }}>
                        <Row>
                            <Col md={12}>
                            <LogAIOTable logs_aio={this.state.logs_aio} deleteLogAIOFromState={this.deleteLogAIOFromState} /> 
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="3" style={{ backgroundColor: 'Orange' }}>
                        <Row>
                            <Col md={12}>
                                <InputGroup size="sm">
                                    <InputGroupAddon addonType="prepend"> NET </InputGroupAddon>
                                    <InputGroupAddon addonType="append">
                                        <Input type="select" name="network" bsSize="sm" onChange={this.networkChange}>
                                            <option value="=">===</option>
                                            {this.state.networks.map((option) => (
                                                <option value={option.network} selected={option.network === "ETH" ? true : false}>{option.network}</option>
                                            ))}
                                        </Input>
                                    </InputGroupAddon>
                                    <InputGroupAddon addonType="append"><InputGroupText>CONVERT-TO</InputGroupText></InputGroupAddon>
                                    <InputGroupAddon addonType="append">
                                        <Input type="select" name="convert_to" bsSize="sm" onChange={this.convertToChange}>
                                            <option value="=">===</option>
                                            {this.state.converts.filter(item => item.network === this.state.network).map(convert => (
                                                <option value={convert.convert_to}>{convert.convert_to}</option>
                                            ))}

                                        </Input>
                                    </InputGroupAddon>
                                    <InputGroupAddon addonType="append"><InputGroupText>EXCH</InputGroupText></InputGroupAddon>
                                    <InputGroupAddon addonType="append">
                                        <Input type="select" name="exchange_id" bsSize="sm" onChange={this.handleChange}>
                                            <option value="=">===</option>
                                            {this.state.exchanges.map((option) => (
                                                <option value={option.exchange_id}>{option.nama_exchange}</option>
                                            ))}
                                        </Input>
                                    </InputGroupAddon>
                                    <InputGroupAddon addonType="append">
                                        <Button color="info" onClick={()=> this.readExtOrderbooks() }>Load</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                        <Col md={12}>
                                <Input type="textarea" name="extOrderbooks" onChange={this.handleChange} value={this.state.extOrderbooks === null ? '' : this.state.extOrderbooks} style={{ height: 400 }} disabled={true} />
                            </Col>
                        </Row>
                    </TabPane>

				</TabContent>

            </Container>
        )
    }
}