import React, { Component } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import MarketKonversiModal from './Modals/MarketKonversi.Modal.js'
import MarketKonversiTable from './Tables/MarketKonversi.Table.js'

import authHeader from '../services/auth-header';
import Config from '../Config';
const API_URL = Config.API_URL

export default class MarketKonversi extends Component {
	constructor(props) {
		super(props)
	}

	state = {	markets_konversi: [] }

	componentDidMount() {
		this.getMarketsKonversi()
	}

	getMarketsKonversi() {
		fetch(API_URL + 'market-konversi', {
			method: 'GET',
			headers: authHeader()
		})
			.then(response => response.json())
			.then(markets_konversi => this.setState({markets_konversi}))
			.catch(err => console.log(`err: ${err}`))
	}

	addMarketKonversiToState = (market_konversi) => {
		this.setState(prevState => ({
			markets_konversi: [...prevState.markets_konversi, market_konversi]
		}))
		//console.table(this.state.markets_konversi)
	}

	updateMarketKonversiState = (market_konversi) => {
		const marketKonversiIndex = this.state.markets_konversi.findIndex(data => data.market_konversi_id === market_konversi.market_konversi_id)
		const newArray = [
			// desctructure all markets-konversi from beginning to the indexed market-konversi
			...this.state.markets_konversi.slice(0, marketKonversiIndex),
			// add the updated market-konversi to the array
			market_konversi,
			// add the rest of the markets-konversi to the array from the index after the replaced market-konversi
			...this.state.markets_konversi.slice(marketKonversiIndex + 1)
		]

		this.setState({markets_konversi: newArray})
	}

	deleteMarketKonversiFromState = (id) => {
		const updatedMarketsKonversi = this.state.markets_konversi.filter(market_konversi => market_konversi.market_konversi_id !== id)
		this.setState({markets_konversi: updatedMarketsKonversi})
	}

	render() {
		return(
			<Container className="App">
				<Row>
					<Col> <h3 style={{margin: "20px 0"}}> Konversi Market Pair Data</h3> </Col>
				</Row>
				<Row>
					<Col>
						<MarketKonversiTable markets_konversi={this.state.markets_konversi} 
							updateMarketKonversiState={this.updateMarketKonversiState}
							deleteMarketKonversiFromState={this.deleteMarketKonversiFromState}
							getMarketsKonversi={this.getMarketsKonversi} />
					</Col>
				</Row>
				<Row>
					<Col>
						<MarketKonversiModal buttonLabel="Tambah Market Konversi" addMarketKonversiToState={this.addMarketKonversiToState} />
						{ ' ' }
						<Button color="primary">Export JSON</Button>
						<br/><br/>
					</Col>
				</Row>
			</Container>
		)
	}
}