import React from 'react';
import { Collapse, Row, Col, Button } from 'reactstrap'
import RptArbit from './Reports/Arbit.Report'

export default class ReportArbit extends React.Component {
    constructor(props) {
		super(props)
    }

    state = { isOpen: false, refreshSettings: false, refreshArbits: false }

    setIsOpen = () => this.setState(prevState => ({isOpen: !prevState.isOpen}))
    toggleCollapse = () => this.setIsOpen();
    setRefreshSettings = () => this.setState(prevState => ({refreshSettings: !prevState.refreshSettings}) )
    setRefreshArbits = () => this.setState(prevState => ({refreshArbits: !prevState.refreshArbits}))

    render() {
        return(
            <>
{/*                 <Row className="fixed-bottom">
                    <Col md={1} className="text-left text-md-left">
                        <Button color="warning" onClick={this.toggleCollapse} style={{ marginBottom: '1rem' }} size="sm">Widget</Button>
                    </Col>
                    <Col md={11} className="text-center text-md-left">
                        <Collapse isOpen={this.state.isOpen}>
                            <MarketsScrap setRefreshSettings={this.setRefreshSettings} setRefreshArbits={this.setRefreshArbits} />
                        </Collapse>
                    </Col>
                </Row> */}
    
                <Row>
                    <Col md={12}>
                        <RptArbit setRefreshSettings={this.setRefreshSettings} setRefreshArbits={this.setRefreshArbits}
                            refreshSettings={this.state.refreshSettings} refreshArbits={this.state.refreshArbits} />
                    </Col>
                </Row>
            </>
        );
    }
}