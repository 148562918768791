import React, { Component } from 'react'
import { Table, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import DexToolCountUp from '../Forms/DexToolCountUp.Form'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class DexTool extends Component {

	constructor(props) {
		super(props)
		this.minutesInput = React.createRef();
		this.secondsInput = React.createRef();
	}

	state = {
		minutes: 0,
		seconds: 5,
		scrap_finish_time: ''
	}

	componentDidMount() {
		this.minutesInput.current.value = 0;
		this.secondsInput.current.value = 4;
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	startTimer = () => {
		this.setState({scrap_finish_time: 'Proses...'})
		this.timer = setInterval(this.countDown, 1000);
	};

	resetTimer = () => {
		this.setState({
			minutes: this.minutesInput.current.value, 
			seconds: this.secondsInput.current.value
		})
	};

	stopTimer = () => {
		clearInterval(this.timer);
		this.resetTimer()
	};

	countDown = () => {
		const { minutes, seconds } = this.state;
		let c_seconds = this.convertToSeconds(minutes, seconds);

		if (c_seconds) {
			// seconds change
			seconds	? this.setState({ seconds: seconds - 1 }) : this.setState({ seconds: 59 });

			// minutes change
			if (c_seconds % 60 === 0 && minutes) {
				this.setState({ minutes: minutes - 1 })
			}

		} else { // counter == 0
			this.props.dexs.map(dex => {
				console.log(`counter is over, trigger scraping for dex: ${dex.exchange_id}`)
				this.props.setDexScraping(dex.exchange_id, 1)
			});
			this.stopTimer()
		}
	};

	convertToSeconds = (minutes, seconds) => {
		return seconds + minutes * 60;
	};

	calcArbitExt = () => {
		const profit = this.props.getProfit()
		const volume = this.props.getVolume()

		fetch(API_URL + `report/calc-arbit/${profit}/${volume}`, {
			method: 'GET',
			headers: authHeader()
		})
		.then(() => {
			console.log(`Hitung Selisih Orderbook Berhasil.`)
			//this.props.setRefreshArbits()
			const timestamp = Date.now(); // This would be the timestamp you want to format
			//console.log(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp));
			//const finish_time = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp)
			const options = {
				year: 'numeric', month: 'numeric', day: 'numeric',
				hour: 'numeric', minute: 'numeric', second: 'numeric',
				hour12: false,
				timeZone: 'Asia/Jakarta'
			};
			
			const finish_time = new Intl.DateTimeFormat('id-ID', options).format(timestamp)
			this.setState({scrap_finish_time: 'End ' + finish_time}) 
		})
		.catch(err => console.log("calcArbitExt Error: " + err))
		 
	}

	render() {

		const dexs = this.props.dexs.map(dex => {
			return(
				<tr key={dex.autoid}>
					<td scope="row" style={{ fontSize: 13, fontWeight: 'bold' }}>{dex.exchange_id}</td>
					<td>
						<DexToolCountUp dex={dex} setDexScraping={this.props.setDexScraping} />
					</td>
				</tr>
			)
		})

		return(
			<Table responsive hover striped size="sm" bordered>
				<thead>
					<tr style={{ fontSize: 13, fontWeight: 'bold'}}>
						<td colSpan="2">
						<InputGroup size="sm">
							<InputGroupAddon addonType="prepend">
								<Button color="success" onClick={this.startTimer} size="sm">start</Button>
							</InputGroupAddon>
							<InputGroupAddon addonType="append">
								<Button color="danger" onClick={this.stopTimer} size="sm">stop</Button>
							</InputGroupAddon>
							{/* <InputGroupAddon addonType="append">
								<Button color="primary" onClick={this.resetTimer} size="sm">reset</Button>
							</InputGroupAddon> */}
							<InputGroupAddon addonType="append">
								<InputGroupText>Min</InputGroupText>
							</InputGroupAddon>
							<Input type="number" innerRef={this.minutesInput} onChange={this.handleChange} bsSize="sm" name="minutes" />
							<InputGroupAddon addonType="append">
								<InputGroupText>Sec</InputGroupText>
							</InputGroupAddon>
							<Input type="number" innerRef={this.secondsInput} onChange={this.handleChange} bsSize="sm" name="seconds" />
							<InputGroupAddon addonType="append">
								<InputGroupText> <b>{this.state.minutes} : {this.state.seconds}</b> </InputGroupText>
							</InputGroupAddon>
						</InputGroup>
						</td>
					</tr>
					<tr>
						<td colSpan="2">
							<b>{ this.state.scrap_finish_time }</b>
						</td>
					</tr>
				</thead>
				<tbody>
					{dexs}
				</tbody>
			</Table>
		)
	}
}

export default DexTool