import React, { Component } from 'react'
import { Container, Row, Col, Input } from 'reactstrap'
import ScrapProfilModal from './Modals/ScrapProfil.Modal'
import ScrapProfilTable from './Tables/ScrapProfil.Table'

import authHeader from '../services/auth-header';
import Config from '../Config';
const API_URL = Config.API_URL

export default class ScrapProfil extends Component {
    state = {
		scrap_profiles: [],
		filter: ''
	}

	componentDidMount() {
		this.getScrapProfil()
	}

	getScrapProfil() {
		fetch(API_URL + 'scrap-profil', {
			method: 'GET',
			headers: authHeader()
		})
			.then(response => response.json())
			.then(scrap_profiles => this.setState({scrap_profiles}))
			.catch(err => console.log(err))
	}

	addScrapProfilToState = (scrap_profil) => {
		this.setState(prevState => ( {
			scrap_profiles: [...prevState.scrap_profiles, scrap_profil]
		}))
	}

	updateScrapProfilState = (scrap_profil) => {
		const profilIndex = this.state.scrap_profiles.findIndex(data => data.autoid === scrap_profil.autoid)
		const newArray = [
			// desctructure all exchanges from beginning to the indexed exchange
			...this.state.scrap_profiles.slice(0, profilIndex),
			// add the updated exchange to the array
			scrap_profil,
			// add the rest of the exchanges to the array from the index after the replaced exchange
			...this.state.scrap_profiles.slice(profilIndex + 1)
		]
		this.setState({scrap_profiles: newArray})
	}

	deleteScrapProfilFromState = (id) => {
		const updatedProfil = this.state.scrap_profiles.filter(scrap_profil => scrap_profil.autoid !== id)
		this.setState({scrap_profiles: updatedProfil})
	}

	handleFilter = event => {
		this.setState({ filter: event.target.value })
	  }

	render() {
		const { scrap_profiles, filter } = this.state
		const lowercasedFilter = filter.toLowerCase()
		const filterProfiles = scrap_profiles.filter(item => {
			return item.koin.toLowerCase().indexOf(lowercasedFilter) !== -1
		});

		return (
			<Container className="App">
				<Row>
					<Col md={2}> <h5 style={{ margin: "5px 0" }}>Scrap Profil</h5> </Col>
					<Col md={10}>
						<Input type="text" onChange={this.handleFilter} placeholder="Filter Koin.." />
					</Col>
				</Row>

				<Row>
					<Col> 
						<ScrapProfilTable scrap_profiles={filterProfiles || []} updateScrapProfilState={this.updateScrapProfilState} 
						deleteScrapProfilFromState={this.deleteScrapProfilFromState} /> 
					</Col>
				</Row>

				<Row>
					<Col>
						<ScrapProfilModal buttonLabel="Tambah Koin/Token" addScrapProfilToState={this.addScrapProfilToState} />
					</Col>
				</Row>
			</Container>
		)
	}
}

