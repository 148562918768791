import React from 'react'
import { Badge, Table, Row, Col, Button, InputGroup, InputGroupAddon, 
        InputGroupText, Input, FormText, UncontrolledPopover, PopoverHeader, PopoverBody
    } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL
let ws = null

class SimulasiArbitBeliForm extends React.Component {
	constructor(props) {
        super(props)
        this.hargaInput = React.createRef();
        this.jumlahInput = React.createRef();
        this.totalConvertToInput = React.createRef();
        this.totalInput = React.createRef();
    }
    
    state = {
        arbit: [],
        orderbook: [],
        simulasi_modal: 0,
        convert_to: '',
        network: '',
        saldo_asset: [],
        modal: 0,
        busy_info: 'OB:Orderbook|OPOR:Open Order|PREV:Preview',
        bgcolor_row: 0,
        koin_presisi: 0,
        msg_simulasi_beli: '',
        wd_sts: true,
        wd_fee: 0,
        wd_to:'',
        dataFromServer: null
    }

    componentDidMount() {
        this.getSettings()
    }

    componentWillUnmount() {
        this.closeWS()
    }

    getOrderbook = () => {
        const exchange_id = this.props.arbit.exchange_id_a
        // CEX using websocket
        if ( exchange_id=="BINA" || exchange_id=="BINA_BSC") {
            this.closeWS()
            this.openWS()
        }
        
        const market = this.props.arbit.koin + '_' + this.props.arbit.pair_a
        let simulasi_modal = this.state.simulasi_modal,
            convert_to = this.state.convert_to, network = this.state.network
        const side =
            (exchange_id === "1INC" || exchange_id === "1INC_BSC" || exchange_id === "OPOC" ||
                exchange_id === "OPOC_BSC" || exchange_id === "PARA" || exchange_id === "PARA_BSC") ? 'bid' : 'ask'
        const user = JSON.parse(localStorage.getItem('user'));
        const username = user.username

        this.hargaInput.current.value = 'Proses'
        //ext/:username/modal/:modal/mkt/:market/:side/:convert_to/:network/:exchange_id
        fetch(API_URL + `ext/${username}/modal/${simulasi_modal}/mkt/${market}/${side}/${convert_to}/${network}/${exchange_id}`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(async orderbook => {
                this.setState({ orderbook })

                const objs = Object.entries(await orderbook)
                objs.map(([k1, v1]) => {

                    if (k1 === "asks") {
                        const sum_row1 = this.decimal4(parseFloat(v1[0][2]))
                        const sum_row2 = this.decimal4(parseFloat(v1[0][2]) + parseFloat(v1[1][2]))
                        const sum_row3 = this.decimal4(parseFloat(v1[0][2]) + parseFloat(v1[1][2]) + parseFloat(v1[2][2]))

                        if (sum_row1 >= parseFloat(this.totalInput.current.value)) {
                            this.setState({ 'bgcolor_row': 1 })
                            this.hargaInput.current.value = v1[0][0]
                        } else if (sum_row2 >= parseFloat(this.totalInput.current.value)) {
                            this.setState({ 'bgcolor_row': 2 })
                            this.hargaInput.current.value = v1[1][0]
                        } else if (sum_row3 >= parseFloat(this.totalInput.current.value)) {
                            this.setState({ 'bgcolor_row': 3 })
                            this.hargaInput.current.value = v1[2][0]
                        } else {
                            this.setState({ 'bgcolor_row': 0 })
                            this.hargaInput.current.value = 0
                        }

                    } else if (exchange_id === "BINA" || exchange_id === "BINA_BSC") {
                        if (k1 === "koin_presisi_desimal") this.setState({ 'koin_presisi': v1 })
                        else if (k1 === "wd_sts") {
                            if (v1 === 1) this.setState({ 'wd_sts': true })
                            else this.setState({ 'wd_sts': false })

                            console.log("wd_sts ", v1)
                        }
                        else if (k1 === "wd_fee") {
                            //this.setState({'wd_fee': v1})
                            const network = this.state.network
                            const wd_fee = v1
                            const fees = wd_fee.split(';') // split berdasar network
                            for (let i = 0; i < fees.length; i++) {
                                if (fees[i].indexOf(network) >= 0) {
                                    const fees2 = fees[i].split(':') // split untuk mendapatkan biaya wd
                                    console.log("network: ", fees2[0], " fee: ", fees2[1])
                                    this.setState({ 'wd_fee': parseFloat(fees2[1]) })
                                }
                            }
                        }
                    }
                })

            })
            .then(() => {
                this.handleTotalConvertToChange(this.props.arbit.exchange_id_a, this.state.convert_to, this.props.arbit.pair_a)
            })
            .catch(err => console.log(err))
        
    }

    decimal9 = (x) => parseFloat(x).toFixed(10)
    decimal4 = (x) => Number.parseFloat(x).toFixed(4);
    setJumlahKoinPresisi = (x) => Number.parseFloat(x).toFixed( this.state.koin_presisi )

    getSettings = () => {
        this.setState({'simulasi_modal': this.props.settings_main.modal})
        //this.setState({'convert_to': this.props.settings_main.convert_to}, () => {
        this.setState({'convert_to': this.props.arbit.convert_to}, () => {
            this.totalConvertToInput.current.value = this.props.settings_main.modal
            this.handleTotalConvertToChange(this.props.arbit.exchange_id_a, this.state.convert_to, this.props.arbit.pair_a) 
        })
        //this.setState({'network': this.props.settings_main.network})
        this.setState({'network': this.props.arbit.network})
    }

    openWS = () => {
        const exchange_id = this.props.arbit.exchange_id_a

        if ( exchange_id=="BINA" || exchange_id=="BINA_BSC" ) {
            const market = this.props.arbit.koin + this.props.arbit.pair_a

            if ( !ws || ws.readyState !== ws.OPEN  )
                //ws = new WebSocket('wss://stream.binance.com:9443/ws');
                ws = new WebSocket('wss://stream.binance.com:9443/ws/' + market.toLowerCase() + '@depth5@1000ms');

            const msg = {
                method: 'SUBSCRIBE',
                //params: ['btcusdt@depth'],
                params: [market.toLowerCase() +'@depth@1000ms'],
                id: 1,
            };

            ws.onopen = () => {
                console.log("connected");
                //ws.send(JSON.stringify(msg));
            }
    
            ws.onmessage = evt => {
                // listen to data sent from the websocket server
                //const message = JSON.parse(evt.data);
                const message = JSON.parse( evt.data );
                this.setState({ dataFromServer: message });
                //console.log('onmessage', message);
            }

            ws.onclose = () => {
                console.log('disconnected')
                // automatically try to reconnect on connection loss
            }
        }
        
    }

    closeWS = () => {
        if ( !!ws && ws.readyState === ws.OPEN  ) ws.close()
    }

    setHrgBeli = (values) => {
        //this.hargaInput.current.value = this.state.orderbook.asks[values][0];
        this.hargaInput.current.value = values;
        this.handleHargaChange()
    }

    handleHargaChange = () => {
        try {
            let jumlahInput=0

            if ( this.props.arbit.exchange_id_a==="BINA" || this.props.arbit.exchange_id_a==="BINA_BSC" ) 
                jumlahInput = this.setJumlahKoinPresisi( this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value) ) - this.state.wd_fee
            else
                jumlahInput = this.decimal4( this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value) )
            
            this.jumlahInput.current.value = jumlahInput
            this.handleJumlahChange(this.props.arbit.exchange_id_a, this.state.convert_to, this.props.arbit.pair_a)
        } catch (e) { 
            console.log(`err handleHargaChange: ${e}`) 
        }
    }

    handleJumlahChange = (exchange_id, market_koin, market_pair) => {
        try {
            const market_konversi = Object.entries( this.props.market_konversi.filter(item => item.exchange_id==exchange_id && item.convert_to==this.props.arbit.convert_to) )
            const convert_to = this.state.convert_to
            const network = this.state.network

            this.props.setHargaBeli( this.hargaInput.current.value )
            this.props.setJumlahBeli( this.jumlahInput.current.value )
            this.props.setTotalBeli( this.totalConvertToInput.current.value )
            market_konversi.map( ([k1, v1]) => {
                if ( v1.exchange_id===exchange_id && v1.market_koin===market_koin && v1.market_pair===market_pair && v1.network.search(network)>=0 ) {
                    try {
                        this.totalConvertToInput.current.value = this.decimal4( this.decimal4(this.totalInput.current.value) / this.decimal9(v1.bid) )
                        this.totalInput.current.value = this.decimal4(this.hargaInput.current.value * this.jumlahInput.current.value )
                        this.setState({'simulasi_modal': this.totalInput.current.value})
                    } catch (e) { console.log(`err change Total ${this.state.convert_to}: ${e}`)  }
                } else if ( v1.exchange_id===exchange_id && v1.market_pair===market_koin && v1.market_koin===market_pair && v1.network.search(network)>=0 ) {
                    try {
                        this.totalConvertToInput.current.value = this.decimal4( this.decimal4(this.totalInput.current.value) * this.decimal9(v1.bid) )
                        this.totalInput.current.value = this.decimal4(this.hargaInput.current.value * this.jumlahInput.current.value )
                        this.setState({'simulasi_modal': this.totalInput.current.value})
                    } catch (e) { console.log(`err change Total ${this.state.convert_to}: ${e}`)  }
                }
                
            })
        } catch (e) { console.log(`err handleJumlahChange ${e}`) }
    }

    handleTotalConvertToChange = (exchange_id, market_koin, market_pair) => {
        market_koin = market_koin.toUpperCase()
        market_pair = market_pair.toUpperCase()

        const market_konversi = Object.entries( this.props.market_konversi.filter(item => item.exchange_id==exchange_id && item.convert_to==this.props.arbit.convert_to) )
        const network = this.state.network
        //console.log(`network : ${network} market_koin ${market_koin}`)

        market_konversi.map( ([k1, v1]) => {
            let jumlahInput=0

            if ( v1.exchange_id===exchange_id && v1.market_koin===market_koin && v1.market_pair===market_pair && v1.network.search(network)>=0 ) {
                try {
                    if ( exchange_id==="BINA" || exchange_id==="BINA_BSC" ) 
                        jumlahInput = this.setJumlahKoinPresisi( this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value) ) - this.state.wd_fee
                    else
                        jumlahInput = this.decimal4(this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value))
                        // jumlahInput = this.decimal4(this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value))
                    this.totalInput.current.value = this.decimal4(this.decimal4(this.totalConvertToInput.current.value) * this.decimal9(v1.bid))
                    this.jumlahInput.current.value = jumlahInput
    
                    this.props.setHargaBeli(this.hargaInput.current.value)
                    this.props.setJumlahBeli(this.jumlahInput.current.value)
                    this.props.setTotalBeli(this.totalConvertToInput.current.value)
                    this.setState({'simulasi_modal': this.totalInput.current.value})
                } catch (e) { 
                    console.log(`err handleTotalConvertToChange: ${e}`) 
                }
            } else if ( v1.exchange_id===exchange_id && v1.market_pair===market_koin && v1.market_koin===market_pair && v1.network.search(network)>=0 ) {
                try {
                    if ( exchange_id==="BINA" || exchange_id==="BINA_BSC" ) 
                        jumlahInput = this.setJumlahKoinPresisi( this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value) ) - this.state.wd_fee
                    else
                        jumlahInput = this.decimal4(this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value))
                    // jumlahInput = this.decimal4(this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value))
                    this.totalInput.current.value = this.decimal4(this.decimal4(this.totalConvertToInput.current.value) / this.decimal9(v1.bid))
                    this.jumlahInput.current.value = jumlahInput
    
                    this.props.setHargaBeli(this.hargaInput.current.value)
                    this.props.setJumlahBeli(this.jumlahInput.current.value)
                    this.props.setTotalBeli(this.totalConvertToInput.current.value)
                    this.setState({'simulasi_modal': this.totalInput.current.value})
                } catch (e) { 
                    //this.totalInput.current.value = 0
                    console.log(`err handleTotalConvertToChange: ${e}`) 
                }
            }
            
        })
    }

    handleTotalChange = (exchange_id, market_koin, market_pair) => {
        market_koin = market_koin.toUpperCase()
        market_pair = market_pair.toUpperCase()
        const market_konversi = Object.entries( this.props.market_konversi.filter(item => item.exchange_id==exchange_id && item.convert_to==this.props.arbit.convert_to) )
        const network = this.state.network

        market_konversi.map( ([k1, v1]) => {
            let jumlahInput=0

            if ( v1.exchange_id===exchange_id && v1.market_koin===market_koin && v1.market_pair===market_pair && v1.network.search(network)>=0 ) {
                try {
                    if ( exchange_id==="BINA" || exchange_id==="BINA_BSC" ) 
                        jumlahInput = this.setJumlahKoinPresisi( this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value) ) - this.state.wd_fee
                    else
                        jumlahInput = this.decimal4( this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value) )
                    // jumlahInput = this.decimal4( this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value) )
                    this.totalConvertToInput.current.value = this.decimal4( this.decimal4(this.totalInput.current.value) / this.decimal9(v1.bid) )
                    this.jumlahInput.current.value = jumlahInput
                    
                    this.props.setHargaBeli(this.hargaInput.current.value)
                    this.props.setJumlahBeli(this.jumlahInput.current.value)
                    this.props.setTotalBeli(this.totalConvertToInput.current.value)
                } catch (e) { 
                    //this.totalConvertToInput.current.value = 0
                    console.log(`err handleTotalChange: ${e}`) 
                }
            } else if ( v1.exchange_id===exchange_id && v1.market_pair===market_koin && v1.market_koin===market_pair && v1.network.search(network)>=0 ) {
                try {
                    if (exchange_id === "BINA" || exchange_id==="BINA_BSC")
                        jumlahInput = this.setJumlahKoinPresisi(this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value)) - this.state.wd_fee
                    else
                        jumlahInput = this.decimal4( this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value) )
                    // jumlahInput = this.decimal4( this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value) )
                    this.totalConvertToInput.current.value = this.decimal4( this.decimal4(this.totalInput.current.value) * this.decimal9(v1.bid) )
                    this.jumlahInput.current.value = jumlahInput
                    
                    this.props.setHargaBeli(this.hargaInput.current.value)
                    this.props.setJumlahBeli(this.jumlahInput.current.value)
                    this.props.setTotalBeli(this.totalConvertToInput.current.value)
                } catch (e) { 
                    //this.totalConvertToInput.current.value = 0
                    console.log(`err handleTotalChange: ${e}`) 
                }
            }
        })
        this.setState({'simulasi_modal': this.totalInput.current.value})
    }

    getSaldoAssets = async () => {
        this.setState({'busy_info': 'PROSES...'})
        const username = this.getCurrentUser()
        const exchange_id = this.props.arbit.exchange_id_a

        await fetch(API_URL + `report/saldo-assets`, {
            method: 'POST',
            //headers: {'Content-Type': 'application/json'},
            headers: authHeader(),
            body: JSON.stringify({
                'username': username,
				'exchange_id': exchange_id
			})
        })
        .then(async res => {
            const saldo_asset = await res.json()
            const token = this.props.arbit.pair_a

            let modal = saldo_asset.filter(function(item) {
                return item.token === token
             }).map(function({saldo}) {                
                return saldo;
             });

             this.setState({ 'modal': this.decimal4( modal ) })
             this.setState({'busy_info': 'OBs:Orderbook|OPOR:Open Order|PREV:Preview'})
        })
    }

    getCurrentUser = () => {
		const user = JSON.parse(localStorage.getItem('user'))
        return user.username
	}

    execArbitrage = async ( side, mode ) => {
        const exchange_id_a = this.props.arbit.exchange_id_a
        const exchange_id_b = this.props.arbit.exchange_id_b
        const koin = this.props.arbit.koin
        const pair_a = this.props.arbit.pair_a
        const pair_b = this.props.arbit.pair_b
        let sell_price = 0

        if ( side==="buy" ) {
            if ( this.props.arbit.bid_row===1 ) sell_price = this.props.arbit.bid1
            else sell_price = this.props.arbit.bid2
        } else if ( side==="sell") {

        }
        
        const profit = this.props.arbit.profit
        const buy_ag = this.props.arbit.ask_ag
        const sell_ag = this.props.arbit.bid_ag
        // mode = as param
        const modal = this.totalInput.current.value
        const username = this.getCurrentUser()

        await fetch(API_URL + `report/exec-arbitrage`, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                'exchange_id_a': exchange_id_a,
                'exchange_id_b': exchange_id_b,
                'koin': koin,
                'pair_a': pair_a,
                'pair_b': pair_b,
                'sell_price': sell_price,
                'profit': profit,
                'buy_ag': buy_ag,
                'sell_ag': sell_ag,
                'mode' : mode,
                'side' : side,
                'modal': modal,
                'username' : username
            })
        })
        .then(async res => {
            const msg = await res.json()

            let msg_simulasi_beli = 
                `exchange ${msg.exchange} ${msg.side} ${msg.koin}/${msg.pair} \n` +
                `${msg.orderbook_price} X ${msg.orderbook_vol} = ${msg.orderbook_sum}`
            this.setState({ msg_simulasi_beli })
        })
    }

     render() {
        let ask_price=0, ask_vol=0, exchange_id=this.props.arbit.exchange_id_a

        if ( this.props.arbit.ask_row==2 ) {
            ask_price = this.decimal9(this.props.arbit.ask2)
            ask_vol = this.decimal4( this.props.arbit.ask2_vol + this.props.arbit.ask1_vol )
        } else if ( this.props.arbit.ask_row==1 ) {
            ask_price = this.decimal9(this.props.arbit.ask1)
            ask_vol = this.decimal4( this.props.arbit.ask1_vol )
        }

        const objs = Object.entries(this.state.orderbook)
        let orderbook=null

        // CEX using websocket
        if ( exchange_id=="BINA" || exchange_id=="BINA_BSC" ) {
            if ( !!this.state.dataFromServer && this.state.dataFromServer.hasOwnProperty('asks') ) {
                let bid_count = 0, sum = 0, harga_input = 0, changeHarga_input=false;

                orderbook = this.state.dataFromServer.asks.map((item) => {
                    const item_price = parseFloat(item[0]).toFixed(9);
                    const item_amount = parseFloat(item[1]).toFixed(4);
                    const item_total = this.decimal4(parseFloat((item_price * item_amount).toFixed(4)));
                    sum += parseFloat(this.decimal4(item_total))
                    bid_count++;

                    if ( !changeHarga_input && sum >= this.totalInput.current.value ) {
                        harga_input = item_price
                        changeHarga_input = true
                    }

                    if (item_amount > 0 && bid_count <= 3) {

                        return (
                            <tr align="right" key={bid_count} onClick={() => this.setHrgBeli(item_price)}
                                style={(sum >= this.totalInput.current.value) ? { backgroundColor: "Orange" } : {}}>
                                <td scope="row" align="right"> {bid_count} </td>
                                <td align="right"> {item_price} </td>
                                <td align="right"> {item_amount} </td>
                                <td align="right"> {item_total} </td>
                                <td align="right"> {this.decimal4(sum)} </td>
                            </tr>
                        )
                    }
                });

                //if ( harga_input > 0 && this.hargaInput.current.value != harga_input ) this.setHrgBeli( harga_input )
            }
        } else { // using rest-api
            orderbook = objs.map(([k1, v1]) => {

                if (k1 === "asks") {
    
                    return (
                        <>
                            <tr align="right" key={0} onClick={() => this.setHrgBeli(v1[0][0])}
                                style={(this.state.bgcolor_row === 1) ? { backgroundColor: "Orange" } : {}}>
                                <td scope="row"> {1} </td>
                                <td> {v1[0][0]} </td>
                                <td> {v1[0][1]} </td>
                                <td> {v1[0][2]} </td>
                                <td> {this.decimal4(parseFloat(v1[0][2]))} </td>
                            </tr>
                            <tr align="right" key={1} onClick={() => this.setHrgBeli(v1[1][0])}
                                style={(this.state.bgcolor_row === 2) ? { backgroundColor: "Orange" } : {}}>
                                <td scope="row"> {2} </td>
                                <td> {v1[1][0]} </td>
                                <td> {v1[1][1]} </td>
                                <td> {v1[1][2]} </td>
                                <td> {this.decimal4(parseFloat(v1[0][2]) + parseFloat(v1[1][2]))} </td>
                            </tr>
                            <tr align="right" key={2} onClick={() => this.setHrgBeli(v1[2][0])}
                                style={(this.state.bgcolor_row === 3) ? { backgroundColor: "Orange" } : {}}>
                                <td scope="row"> {3} </td>
                                <td> {v1[2][0]} </td>
                                <td> {v1[2][1]} </td>
                                <td> {v1[2][2]} </td>
                                <td> {this.decimal4(parseFloat(v1[0][2]) + parseFloat(v1[1][2]) + parseFloat(v1[2][2]))} </td>
                            </tr>
                        </>
                    )
                }
             })
        } // .using rest-api

        return (
            <>
                <Row>
                    <Col md={12} className="text-center text-md-left">
                    <Badge color="success"> {(this.props.arbit.ask_ag !== 'undefined') ? this.props.arbit.ask_ag : ''} </Badge>
                        <Table responsive hover striped size="sm" dark>
                            <thead>
                                <tr>
                                    <th> # </th>
                                    <th> Price </th>
                                    <th> Amount </th>
                                    <th> Total </th>
                                    <th> SUM(Total) </th>
                                </tr>
                            </thead>
                            <tbody key={10} style={{ cursor: "pointer" }}>
                                { orderbook }
                            </tbody>
                            
                        </Table>
                    </Col>
                </Row>

                <Row form>
                    <Col md={12}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">Hrg</InputGroupAddon>
                        <Input innerRef={this.hargaInput} onChange={this.handleHargaChange} name="harga" />
                        <InputGroupAddon addonType="append">
							<InputGroupText> { ask_price } </InputGroupText>
						</InputGroupAddon>
                    </InputGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col md={12}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">Jml</InputGroupAddon>
                        <Input innerRef={this.jumlahInput} onChange={() => this.handleJumlahChange(this.props.arbit.exchange_id_a, this.state.convert_to, this.props.arbit.pair_a)} name="jumlah" />
                        <InputGroupAddon addonType="append">
							<InputGroupText> { ask_vol } </InputGroupText>
						</InputGroupAddon>
                    </InputGroup>
                    <FormText color="muted"> 
                        <b>WDfee- { this.state.wd_fee + ' ' + this.props.arbit.koin + ' | canWD: ' + this.state.wd_sts}</b> 
                    </FormText>
                    </Col>
                </Row>

                <Row form>
                    <Col md={12}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText> {this.state.convert_to} </InputGroupText>
                        </InputGroupAddon>
                        <Input innerRef={this.totalConvertToInput} onChange={()=>this.handleTotalConvertToChange(this.props.arbit.exchange_id_a, this.state.convert_to, this.props.arbit.pair_a)} name="total_eth" />
                        <InputGroupAddon addonType="append">
							<InputGroupText> { this.props.arbit.pair_a } </InputGroupText>
						</InputGroupAddon>
                        <Input innerRef={this.totalInput} onChange={() => this.handleTotalChange(this.props.arbit.exchange_id_a, this.state.convert_to, this.props.arbit.pair_a)} name="total_beli" />
                        <InputGroupAddon addonType="append">
							<InputGroupText> { this.props.arbit.ask_vol_tot } </InputGroupText>
						</InputGroupAddon>
                    </InputGroup>
                    <FormText color="muted"> 
                        <b>WD-to { ': ' + this.state.wd_fee}</b> 
                    </FormText>
                    </Col>
                </Row>

                <Row form>
                    <Col md={9}>
                        <FormText color="danger"> { this.state.busy_info } </FormText>
                    </Col>
                    <Col md={3}>
                        <Badge color="secondary" style={{fontSize: 14}}> 
                            { this.state.modal + ' ' + this.props.arbit.pair_a } 
                        </Badge>
                    </Col>
                </Row>

                <Row form>
                    <Col md={12} className="text-center text-md-right">
                    <Button color="success" onClick={ ()=> this.getOrderbook() }>OBs</Button> { ' ' }
                    <Button color="primary" id="popSimulasiBeli" onClick={ ()=> this.execArbitrage('buy', 'simulasi') }>PREV</Button> { ' ' }
                    <Button color="warning" onClick={()=>this.getSaldoAssets()}>ASET</Button> { ' ' }
                    <Button color="success" onClick={ ()=> this.execArbitrage('buy', 'exec') }><b>BELI</b></Button>
                    </Col>
                </Row>

                <Row form>
                    <Col md={12} className="text-left text-md-left">
                        <Button color="info">OPOR</Button> { ' ' }
                        <a href={this.state.orderbook.url_ext ? `${this.state.orderbook.url_ext}` : ``} target={'_blank'}>
                            <Button color="success">R</Button>
                        </a>
                        <a href={this.state.orderbook.url_api ? `${this.state.orderbook.url_api}` : ``} target={'_blank'}>
                            <Button color="success">A</Button>
                        </a>
                        <a href={this.state.orderbook.url_trade ? `${this.state.orderbook.url_trade}` : ``} target={'_blank'}>
                            <Button color="success">M</Button>
                        </a>
                        
                        <Table responsive hover striped size="sm" dark>
                            <thead>
                                <tr>
                                    <th> Aksi </th>
                                    <th> Date </th>
                                    <th> Pair </th>
                                    <th> Tipe </th>
                                    <th> Side </th>
                                    <th> Price </th>
                                    <th> Amount </th>
                                    <th> Filled </th>
                                    <th> Total </th>
                                </tr>
                            </thead>
                            <tbody>
                                
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <UncontrolledPopover trigger="focus" placement="top" target="popSimulasiBeli">
                    <PopoverHeader>Simulasi Beli</PopoverHeader>
                    <PopoverBody>
                        { this.state.msg_simulasi_beli }
                    </PopoverBody>
                </UncontrolledPopover>
            </>
        )
     }
}

export default SimulasiArbitBeliForm