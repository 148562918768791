import React from 'react'
import { Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

export default class UsersForm extends React.Component {
    state = {
        username:null,
        password: null,
        email:null, 
        nama_lengkap:null,
        ulevel:null,
        aktif:null,
        waktu:null
     }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeUserAktif = (event) => {
        this.setState(initialState => {
            return { aktif: !initialState.aktif }
        });
    }

    componentDidMount() {
		// if user exist , populate the state with proper data 
		if (this.props.user) {
			const { username, email, nama_lengkap, ulevel, aktif, waktu } = this.props.user
			this.setState({username, email, nama_lengkap, ulevel, aktif, waktu})
		}
	}

    submitUserFormAdd = (e) => {
        e.preventDefault()
        fetch(API_URL + 'users/insert',
            {
                method: 'post',
                //headers: { 'Content-Type': 'application/json' },
                headers: authHeader(),
                body: JSON.stringify({
                    username: this.state.username,
                    password: this.state.password,
                    email: this.state.email,
                    nama_lengkap: this.state.nama_lengkap,
                    ulevel: this.state.ulevel,
                    aktif: this.state.aktif
                })
            }
        )
            .then(response => response.json())
            .then(user => {
                //if (Array.isArray(user)) {
                this.props.addUserToState(this.state)
                this.props.toggle()
                //} else { console.log(`Failure add user`) }
            })
            .catch(err => console.log(`error add user: ${err}`))
    }

    submitUserFormEdit = e => {
        e.preventDefault()
        fetch(API_URL + `users/update`, {
            method: 'put',
            //headers: {'Content-Type': 'application/json'},
            headers: authHeader(),
            body: JSON.stringify({
                username: this.state.username,
                password: this.state.password,
                email: this.state.email,
                nama_lengkap: this.state.nama_lengkap,
                ulevel: this.state.ulevel,
                aktif: this.state.aktif,
                waktu: this.state.waktu
            })
        })
            .then(response => response.json())
            .then(user => {
                //if (Array.isArray(exchange)) {
                this.props.updateUsersState(user)
                this.props.toggle()
                //} else { console.log("failure submitUserFormEdit") }
            })
            .catch(err => console.log(err))
    }

    render() {            

        return (
            <Col md={12}>
                <Form onSubmit={this.props.user ? this.submitUserFormEdit : this.submitUserFormAdd}>
                    <FormGroup row>
                        <Label for="username" sm={3}> Username </Label>
                        <Col sm={9}>
                            <Input type="text" name="username" onChange={this.handleChange}
                                value={this.state.username === null ? '' : this.state.username}
                                disabled = {(this.props.user) ? "disabled" : ""} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="nama_lengkap" sm={3}>Nama Full</Label>
                        <Col sm={9}>
                            <Input type="text" name="nama_lengkap" onChange={this.handleChange}
                                value={this.state.nama_lengkap === null ? '' : this.state.nama_lengkap} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="email" sm={3}>E - Mail</Label>
                        <Col sm={9}>
                            <Input type="text" name="email" onChange={this.handleChange}
                                value={this.state.email === null ? '' : this.state.email} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="ulevel" sm={3}>Level</Label>
                        <Col sm={9}>
                            <Input type="select" name="ulevel" value={this.state.ulevel || ''} onChange={this.handleChange} bsSize="sm">
                                <option value="superuser">Superuser</option>
                                <option value="user">User</option>
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="password" sm={3}> Password </Label>
                        <Col sm={9}>
                            <Input type="password" name="password" onChange={this.handleChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md={9}>
                            <FormGroup className="float-right" check>
                                <Input type="checkbox" name="aktif" id="aktif"
                                    onChange={this.handleChangeUserAktif} checked={this.state.aktif === null ? true : this.state.aktif} />
                                <Label for="aktif" check>Aktif</Label>
                            </FormGroup>
                        </Col>
                        <Col md={3}> <Button color="success">Simpan</Button> </Col>
                    </FormGroup>
                </Form>
            </Col>
        )
    }

}