import React, { Component } from 'react'
import { Table, CustomInput } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class ExchangeMarketLogs extends Component {
	state = {
		exchange_market_id: ''
	}

	customInputSwitched(inputName, e) {
		let str = `CustomInput value: ${e.target.checked} for ${inputName}...`,
		id = inputName.replace('CustomInput_', '')
		console.log(str + " - id: " + id)
		fetch(API_URL + `exchange-market-logs/${id}`, {
			method: 'post',
			//headers: {'Content-Type': 'application/json'},
			headers: authHeader(),
			body: JSON.stringify({
				aktif: e.target.checked
			})
		})
		.then( res => { this.props.getMarketLogs(); } )
	}

	isIterable (value) {
		return Symbol.iterator in Object(value);
	}

	render() {
		if (!this.isIterable(this.props.marketLogs)) return false;
		const marketLogs = this.props.marketLogs.map(marketLog => {
			return (
				<tr key={marketLog.exchange_market_id}>
					<th scope="row">{marketLog.exchange_market_id}</th>
					<td>{marketLog.exchange_id}</td>
					<td>{marketLog.koin}</td>
					<td>{marketLog.pair}</td>
					<td>{marketLog.aktif}</td>
					<td>
						<div style={{width: "100"}}>
							<CustomInput type="switch" id={`${marketLog.exchange_market_id}`} label="custom switch" 
								onChange={this.customInputSwitched.bind(this, "CustomInput_"+marketLog.exchange_market_id)} checked={marketLog.aktif} />
						</div>
					</td>
				</tr>
			)
		})

		return(
			<Table responsive hover striped size="sm" dark>
				<thead>
					<tr>
						<th>I D</th>
						<th>Exchange</th>
						<th>Koin</th>
						<th>Pair</th>
						<th>Status</th>
						<th>Aksi</th>
					</tr>
				</thead>
				<tbody>
					{marketLogs}
				</tbody>
			</Table>
		)
	}
}

export default ExchangeMarketLogs