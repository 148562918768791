import React, { Component } from 'react'
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import MarketKonversiTable from '../Tables/MarketKonversi.Table'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class MarketKonversiForm extends Component {
	state = {
		market_konversi_id: '',
		convert_to: '',
		exchange_id: '',
		koin: '',
		pair: '',
		market_koin: '',
		market_pair: '',
		network: '',
		aktif: ''
	}

	onChange = (e) => {
		this.setState({[e.target.name]: e.target.value})
	}

	submitMarketKonversiFormAdd = (e) => {
		e.preventDefault()
		//console.log(`submitMarketKonversiFormAdd: ${this.state.convert_to}, ${this.state.exchange_id}, ${this.state.koin}, ${this.state.pair}, ${this.state.aktif}`)
		fetch(API_URL + 'market-konversi/insert',
			{
				method: 'post',
				//headers: { 'Content-Type': 'application/json' },
				headers: authHeader(),
				body: JSON.stringify({
					market_konversi_id: this.state.market_konversi_id,
					convert_to: this.state.convert_to,
					exchange_id: this.state.exchange_id,
					koin: this.state.koin,
					pair: this.state.pair,
					market_koin: this.state.market_koin,
					market_pair: this.state.market_pair,
					network: this.state.network,
					aktif: this.state.aktif
				})
			}
		)
		.then(response => response.json())
		.then(market_konversi => {
			//console.table(market_konversi)
			//console.log(`id: ${market_konversi.id}, exchange_id: ${market_konversi.exchange_id}, convert_to: ${market_konversi.convert_to}, koin: ${market_konversi.koin}`)
			this.setState({market_konversi_id: market_konversi.id})
			//if (Array.isArray(market_konversi)) {
				this.props.addMarketKonversiToState(this.state)
				this.props.toggle()
			//} else { console.log(`Failure add market-konversi`) }
		})
		.catch(err => console.log(`error add market-konversi: ${err}`))
	}

	submitMarketKonversiFormEdit = (e) => {
		e.preventDefault()
		console.log(`market_konversi_id: ${this.state.market_konversi_id}`)
		fetch(API_URL + `market-konversi/update/${this.state.market_konversi_id}`, 
			{
				method: 'put',
				//headers: {'Content-Type': 'application/json'},
				headers: authHeader(),
				body: JSON.stringify({
					market_konversi_id: this.state.market_konversi_id,
					convert_to: this.state.convert_to,
					exchange_id: this.state.exchange_id,
					koin: this.state.koin,
					pair: this.state.pair,
					market_koin: this.state.market_koin,
					market_pair: this.state.market_pair,
					network: this.state.network,
					aktif: this.state.aktif
				})
			}
		)
		.then(response => response.json())
		.then(market_konversi => {
			//if (Array.isArray(market_konversi)) {
				//console.log(`market_konversi updated: `)
				//console.table(market_konversi)
				this.props.updateMarketKonversiState(market_konversi)
				//this.props.getMarketsKonversi()
				this.props.toggle()
			//} else { console.log('Failure Update market-konversi') }
		})
		.catch(err => console.log(`Error Update market-konversi: ${err}`))
	}

	onMarketKonversiAktif = (e) => {
		this.setState(prevState => {
			return { aktif: !prevState.aktif}
		})
	}

	componentDidMount() {
		// if market-konversi exist, populate the state with proper data
		if (this.props.market_konversi) {
			const {market_konversi_id,	convert_to, exchange_id, koin,	pair, aktif} = this.props.market_konversi
			this.setState({market_konversi_id,	convert_to, exchange_id, koin,	pair, aktif})
		}
	}

	render() {
		return(
			<>
				<Form onSubmit={this.props.market_konversi ? this.submitMarketKonversiFormEdit : this.submitMarketKonversiFormAdd} >
					<Row form>
						<Col md={6}>
							<FormGroup>
								<Label for="market_konversi_id"> I D </Label>
								<Input type="text" name="market_konversi_id" onChange={this.onChange} 
									value={this.state.market_konversi_id === null ? '' : this.state.market_konversi_id}
									disabled = {"disabled"} />
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label for="exchange_id">Exchange</Label>
								<Input type="text" id="exchange_id" name="exchange_id" onChange={this.onChange}
									value={this.state.exchange_id === null ? '' : this.state.exchange_id} />

							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={6}>
							<FormGroup>
								<Label for="convert_to">Konversi</Label>
								<Input type="text" id="convert_to" name="convert_to" onChange={this.onChange}
									value={this.state.convert_to === null ? '' : this.state.convert_to} />
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label for="koin">Koin</Label>
								<Input type="text" id="koin" name="koin" onChange={this.onChange}
									value={this.state.koin === null ? '' : this.state.koin} />
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={6}>
							<FormGroup>
								<Label for="pair">Pair</Label>
								<Input type="text" id="pair" name="pair" onChange={this.onChange}
									value={this.state.pair === null ? '' : this.state.pair} />
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label for="market_koin">Market Koin</Label>
								<Input type="text" id="market_koin" name="market_koin" onChange={this.onChange}
									value={this.state.market_koin === null ? '' : this.state.market_koin} />
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={6}>
							<FormGroup>
								<Label for="market_pair">Market Pair</Label>
								<Input type="text" id="market_pair" name="market_pair" onChange={this.onChange}
									value={this.state.market_pair === null ? '' : this.state.market_pair} />
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label for="aktif">Aktif</Label>
								<Input type="text" name="aktif" onChange={this.onChange}
									value={this.state.aktif===null ? '' : this.state.aktif} />
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={6}>{ ' ' }</Col>
						<Col md={6}> <Button>Simpan</Button> </Col>
					</Row>
				</Form>
			</>
		)
	}
}

export default MarketKonversiForm