import React, { Component } from 'react'
import { Row, Col, Table, Input, Badge, InputGroup, InputGroupAddon } from 'reactstrap';
import ModalSimulasiArbit from '../Modals/SimulasiArbit.Modal'
import '../Css/custom-hr.css';
import '../Css/custom-modal.css';
import Button from 'reactstrap/lib/Button';

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class ReportArbit extends Component {
  constructor(props) {
    super(props)
  }
    
  state = {
    arbits: [],
    profit: 0,
    volume: 0,
    settings: [],
    market_konversi: [],
    convert_to: '',
    network: '',
    settings_main:
    {
        modal: 0,
        convert_to: '',
        network: ''
    },

    showPriceReal: true,
    showPriceConvert: false,

    showUserBoard: false,
    showAdminBoard: false,
    showOtherBoard: false,
    currentUser: undefined
  }
    
  componentDidMount() {
    let user = this.getCurrentUser()

    this.getSettings()
    
    if (user) {
		  this.setState({
			currentUser: user,
			showUserBoard: (user.roles !== null) ? user.roles.includes("ROLE_USER") : null,
			showAdminBoard: (user.roles !== null) ? user.roles.includes("ROLE_ADMIN") : null,
			showOtherBoard: (user.roles !== null) ? user.roles.includes("ROLE_OTHER") : null,
		  });
		} else {}
  }

  componentDidUpdate(prevProps) {
    if (this.props.refreshSettings !== prevProps.refreshSettings) {
      if (this.props.refreshSettings === true) {
        this.props.setRefreshSettings()
        this.getSettings()
      }
    } else if (this.props.refreshArbits !== prevProps.refreshArbits) {
      if (this.props.refreshArbits === true) { 
        this.props.setRefreshArbits()
        this.getReportArbit()
      }
    }
  }

  getCurrentUser = () => {
		return JSON.parse(localStorage.getItem('user'))
	}

  getSettings = () => {
    const user = JSON.parse(localStorage.getItem('user'));
		const username = user.username

		fetch(API_URL + `profile-system/settings/${username}`, {
			method: 'GET',
			headers: authHeader()
		})
		.then( res => res.json() )
		.then( settings => {
      this.setState({settings})
			settings.map(item => {
        if ( item.id === "profit" ) {
					this.state.profit = item.val
				} else if ( item.id === "volume" ) {
					this.state.volume = item.val
				} else if ( item.id === "convert_to" ) {
					this.state.convert_to = item.val
          this.setState({ settings_main: { ...this.state.settings_main, convert_to: item.val } })
				} else if ( item.id === "network" ) {
					this.state.network = item.val
          this.setState({ settings_main: { ...this.state.settings_main, network: item.val } })
				} else if ( item.id === "modal" ) {
          this.setState({ settings_main: { ...this.state.settings_main, modal: item.val } })
        }
			})
    })
    .then( () => this.getMarketKonversi() )
    .then( () => this.getReportArbit() )
		.catch(err => console.log(err))
  }
  
  getMarketKonversi = () => {
    fetch(API_URL + `market-konversi/${this.state.convert_to}/${this.state.network}`, {
			method: 'GET',
			headers: authHeader()
		})
		.then( res => res.json() )
		.then( market_konversi => {
      this.setState({market_konversi})
    })
		.catch(err => console.log(err))
  }
    
  getReportArbit() {
    fetch(API_URL + `report/arbit/${this.state.volume}`, {
			method: 'GET',
			headers: authHeader()
		})
      .then(response => response.json())
      .then(arbits => this.setState({ arbits }))
      .catch(err => console.log(err))
  }

  decimal9 = (x) => parseFloat(x).toFixed(10)
  decimal4 = (x) => Number.parseFloat(x).toFixed(4);
    
  render() {
    const { currentUser, showUserBoard, showAdminBoard, showOtherBoard } = this.state;
    let displayPriceReal = this.state.showPriceReal ? 'block' : 'none'
    let displayPriceConvert = this.state.showPriceConvert ? 'block' : 'none'
    let labelConvert = this.state.showPriceReal ? 'show converted price' : 'show real price'
    let count = 0;

    const rpt_arbit = this.state.arbits.map(arbit => {
      count++;

      return (
        <tr key={arbit.rpt_arbit_id}>
          <td scope="row">{count}</td>
          <td align="center">
            <b>{arbit.koin}</b>
            <div>{arbit.pair_a + '=' + arbit.pair_b}</div>
            <div>{arbit.exchange_id_a + '=' + arbit.exchange_id_b}</div>
            <div className="text-success" style={{ fontSize: 12 }}><b>{(arbit.ask_ag !== 'undefined') ? arbit.ask_ag : ''}</b></div>
            <div className="text-warning" style={{ fontSize: 12 }}><b>{(arbit.bid_ag !== 'undefined') ? arbit.bid_ag : ''}</b></div>
            <div style={{ fontSize: 11 }}>{arbit.waktu_a + '=' + arbit.waktu_b}</div>
          </td>

          <td>
            {'Profit: '} <b>{arbit.profit}</b> {'%'}
            <div> {'Modal B/J: '} <b> {arbit.ask_vol_tot} </b> {'/'} <b> {arbit.bid_vol_tot} </b> </div>
            <div> {'Jual baris-'}<b>{arbit.ask_row}</b> {'/Beli baris-'}<b>{arbit.bid_row}</b> </div>

            {showAdminBoard && (
            <div>
              <ModalSimulasiArbit arbit={arbit} settings={this.state.settings} 
                market_konversi={this.state.market_konversi} settings_main={this.state.settings_main} />
            </div>
            )}
          </td>

          <td>
            <a href={`${arbit.url_koin_cmc}`} target={'_blank'}>
              <Badge color="primary"> CMC </Badge>
            </a>
            {' '}
            <a href={`${arbit.url_explorer}`} target={'_blank'}>
              <Badge color="primary"> Scan </Badge>
            </a>

            <div>
              <a href={`${arbit.url_trade_ask}`} target={'_blank'}>
                <Badge color="danger"> trx-ASK </Badge>
              </a>
              {' '}
              <a href={`${arbit.url_trade_bid}`} target={'_blank'}>
                <Badge color="success"> trx-BID </Badge>
              </a>
            </div>

            <div>
              <a href={`${arbit.url_wallet_ask}`} target={'_blank'}>
                <Badge color="danger">
                  WD-ask
              </Badge>
              </a>
              {' '}
              <a href={`${arbit.url_wallet_bid}`} target={'_blank'}>
                <Badge color="success"> DP-bid </Badge>
              </a>
            </div>

          </td>

        </tr>
      )
    })

    return (
      <>
        <Row form>
          <p>{' \n '}</p>
          <Col md={12} className="text-center text-md-left">
            <InputGroup size="sm">
              <InputGroupAddon addonType="prepend"> Filter </InputGroupAddon>
              <Input type="text" name="filter" id="filter" placeholder="Exchange/Token ..." />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center text-md-left">
            <Table responsive hover striped size="sm" dark>
              <thead>
                <tr>
                  <th> # </th>
                  <th> MARKET EXCHANGES </th>
                  <th> INFORMASI </th>
                  <th> AKSI </th>
                </tr>
              </thead>
              <tbody>
                {rpt_arbit}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    )
  }
}

export default ReportArbit