import React from 'react'
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

export default class ScrapProfilForm extends React.Component {
    state = {
		autoid: 0,
		nama_profil: '',
		koin: '',
		convert_to: '',
		network: '',
		aktif: 0,
		waktu: ''
	}

    onChange = e => {
		this.setState({[e.target.name]: e.target.value})
	}

    submitScrapProfilFormAdd = (e) => {
		e.preventDefault()
		fetch(API_URL + 'scrap-profil/insert',
			{
				method: 'post',
				//headers: { 'Content-Type': 'application/json' },
				headers: authHeader(),
				body: JSON.stringify({
					nama_profil: this.state.nama_profil,
					koin: this.state.koin,
					convert_to: this.state.convert_to,
					network: this.state.network,
					aktif: this.state.aktif,
				})
			}
		)
		.then(response => response.json())
		.then(scrap_profil => {
			this.setState({autoid: scrap_profil.id})
			//if (Array.isArray(market_konversi)) {
				this.props.addScrapProfilToState(this.state)
				this.props.toggle()
			//} else { console.log(`Failure add market-konversi`) }
		})
		.catch(err => console.log(`error add scrap-profil: ${err}`))
	}

    submitScrapProfilFormEdit = (e) => {
		e.preventDefault()
		//console.log(`autoid: ${this.state.autoid}`)
		fetch(API_URL + `scrap-profil/update`, 
			{
				method: 'put',
				//headers: {'Content-Type': 'application/json'},
				headers: authHeader(),
				body: JSON.stringify({
					autoid: this.state.autoid,
					nama_profil: this.state.nama_profil,
					koin: this.state.koin,
					convert_to: this.state.convert_to,
					network: this.state.network,
					aktif: this.state.aktif,
				})
			}
		)
		.then(response => response.json())
		.then(scrap_profil => {
			//if (Array.isArray(market_konversi)) {
				//console.log(`market_konversi updated: `)
				//console.table(market_konversi)
				this.props.updateScrapProfilState(scrap_profil)
				//this.props.getMarketsKonversi()
				this.props.toggle()
			//} else { console.log('Failure Update market-konversi') }
		})
		.catch(err => console.log(`Error Update scrap-profil: ${err}`))
	}

    onScrapProfilAktif = (e) => {
		this.setState(prevState => {
			return { aktif: !prevState.aktif}
		})
	}

    componentDidMount() {
		// if market-konversi exist, populate the state with proper data
		if (this.props.scrap_profil) {
			const {autoid, nama_profil, koin, convert_to, network, aktif} = this.props.scrap_profil
			this.setState({autoid, nama_profil, koin, convert_to, network, aktif})
		}
	}

    render() {
        return (
            <>
            <Form onSubmit={this.props.scrap_profil ? this.submitScrapProfilFormEdit : this.submitScrapProfilFormAdd} >
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="market_konversi_id"> I D </Label>
                        <Input type="text" name="autoid" onChange={this.onChange}
                            value={this.state.autoid === null ? '' : this.state.autoid}
                            disabled={"disabled"} />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="nama_profil">Nama Profil</Label>
                        <Input type="text" id="nama_profil" name="nama_profil" onChange={this.onChange}
                            value={this.state.nama_profil === null ? '' : this.state.nama_profil} />
                    </FormGroup>
                </Col>
            </Row>
			<Row form>
				<Col md={6}>
					<FormGroup>
						<Label for="koin">Koin</Label>
						<Input type="text" id="koin" name="koin" onChange={this.onChange}
							value={this.state.koin === null ? '' : this.state.koin} />
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label for="convert_to">convert_to</Label>
						<Input type="text" id="convert_to" name="convert_to" onChange={this.onChange}
							value={this.state.convert_to === null ? '' : this.state.convert_to} />
					</FormGroup>
				</Col>
			</Row>

			<Row form>
				<Col md={6}>
					<FormGroup>
						<Label for="network">Network</Label>
						<Input type="text" id="network" name="network" onChange={this.onChange}
							value={this.state.network === null ? '' : this.state.network} />
					</FormGroup>
				</Col>
				<Col md={6}>
					<FormGroup>
						<Label for="aktif">Aktif</Label>
						<Input type="text" name="aktif" onChange={this.onChange}
							value={this.state.aktif === null ? '' : this.state.aktif} />
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col md={6}>{' '}</Col>
				<Col md={6}> <Button>Simpan</Button> </Col>
			</Row>
            </Form>
            </>
        )
    }

}