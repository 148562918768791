import React, { Component } from 'react'
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames';
import MarketsScrapTable from './Tables/MarketsScrap.Table'
import DexToolTable from './Tables/DexTool.Table'
import MarketsScrapCountSet from './Forms/MarketsScrapCountSet.Form'
import KalkulatorKonversi from './Forms/KalkulatorKonversi.Form'

import authHeader from '../services/auth-header';
import Config from '../Config';
const API_URL = Config.API_URL

export default class MarketsScrap extends Component {
	constructor(props) {
		super(props)
		this.kalkulator = React.createRef();
		this.scrapCountSet = React.createRef();
		this.dexScrapCountSet = React.createRef();
	}

	state = { 
		activeTab: '2', 
		exchanges: [], 
		profit: 0, 
		volume: 0, 
		parentModal: 0, 
		scrap_finish_time: '', 
		convert_to:'ETH',
		network: 'ETH',
		scrap_profil: 'all',
		dexs: [],
		server_ext_url:'',

		exchanges_dex: [],
		dexag_eth_ask_exchange: '',
		dexag_bsc_ask_exchange: '',
		is_scraping_dexag_ask: 0
	}

	componentDidMount() {
		this.getExchanges()
		//this.getDexTool()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.network !== this.state.network || prevState.convert_to !== this.state.convert_to) {
			fetch(API_URL + `exchange-aktif/${this.state.convert_to}/${this.state.network}`, {
				method: 'GET',
				headers: authHeader()
			})
				.then(response => response.json())
				.then(exchanges => this.setState({ exchanges }))
				.catch(err => console.log(err))
		}
	}

	setActiveTab = tab => {
		this.setState({activeTab: tab})
	}

	setScraping = (id, val, scrapFor) => {
		//console.log(`setScrapStatus id: ${id} val: ${val} | state with id: ${this.state.exchanges[id].nama_exchange}`)
		const exchangeIndex = this.state.exchanges.findIndex(data => data.exchange_id === id)
		//let newArray = [...this.state.exchanges]
		let newArray = this.state.exchanges
		if ( scrapFor==="ALL" )
			newArray[exchangeIndex] = {...newArray[exchangeIndex], is_scraping: val}
		else
			newArray[exchangeIndex] = {...newArray[exchangeIndex], is_scraping_dexag_ask: val}
		
		this.setState({exchanges: newArray})

		const exchange_all_finish_scrap = this.state.exchanges.filter( (exchange) => { 
			return exchange.is_scraping === 0;
		});

		const exchange_dexag_finish_scrap = this.state.exchanges.filter( (exchange) => { 
			return (exchange.is_scraping_dexag_ask === 0 && exchange.jenis === 'DEX-AG');
		});

		const exchanges_dexag = this.state.exchanges.filter( (exchange) => { 
			return exchange.jenis === 'DEX-AG';
		});

		if ( (Object.keys(exchange_all_finish_scrap).length === Object.keys(this.state.exchanges).length) && 
			 (Object.keys(exchange_dexag_finish_scrap).length) === Object.keys(exchanges_dexag).length ) {
			console.log(`MarketScrap.js setScraping ${id} = ${val} FINISH SCRAP`)
			this.scrapCountSet.current.calcArbitExt( 1 )
			setTimeout(() => { this.kalkulator.current.getKalkulatorKonversi() }, 3000)
		} else {
			console.log(`MarketScrap.js setScraping ${id} = ${val} / ${scrapFor} exchangeIndex ${exchangeIndex} ` +
				`NOT FINISH SCRAP: ${ Object.keys(exchange_all_finish_scrap).length } | ${ Object.keys(exchange_dexag_finish_scrap).length } | ${ Object.keys(exchanges_dexag).length }`)
			//console.table( newArray )
			//console.table( this.state.exchanges )
		}
		
	}

	setDexScraping = (id, val) => {
		const dexIndex = this.state.dexs.findIndex(data => data.exchange_id === id)
		let newArray = [...this.state.dexs]
		newArray[dexIndex] = {...newArray[dexIndex], is_scraping: val}
		//console.table(newArray)
		this.setState({dexs: newArray})

		const dex_finish_scrap = this.state.dexs.filter( (dex) => { 
			return dex.is_scraping === 0;
		});

		if ( Object.keys(dex_finish_scrap).length === Object.keys(this.state.dexs).length ) {
			console.log(`MarketScrap.js setScraping ${id} = ${val} FINISH SCRAP`)
			this.dexScrapCountSet.current.calcArbitExt()
		} else {
			console.log(`MarketScrap.js setScraping ${id} = ${val} NOT FINISH SCRAP: ${ Object.keys(dex_finish_scrap).length }`)
		}
		
	}

	setParentModal = (modal) => this.setState({parentModal: modal})

	getExchanges() {
		fetch(API_URL + 'exchange/exchange-set-unscraping', {
			method: 'GET',
			headers: authHeader()
		})
			.then(res => {
				fetch(API_URL + `exchange-aktif/${this.state.convert_to}/${this.state.network}`, {
					method: 'GET',
					headers: authHeader()
				})
					.then(response => response.json())
					.then(exchanges => this.setState({ exchanges}, () => { this.getExchanges_dex() } ))
					.catch(err => console.log(err))
			})
			.then(response => console.log('exchange-set-unscraping sukses'))
			.catch(err => console.log(err))
	}

	getExchanges_dex() {
		this.setState({'exchanges_dex': this.state.exchanges.filter(item => item.jenis === 'DEX_AG' )
		});
	}

	getDexTool() {
		fetch(API_URL + 'dex-tools/aktif', {
			method: 'GET',
			headers: authHeader()
		})
			.then(async res => {
				const dexs = await res.json()
				this.setState({ dexs })
			})
			.catch(err => console.log(err))
	}

	setServerExtUrl = (server_ext_url) => {
		this.setState({server_ext_url})
	}

	getServerExtUrl = () => {
		//console.log(`marketscarp.js getServerExtUrl `, this.state.server_ext_url)
		return this.state.server_ext_url
	}

	setProfit = (profit) => {
		this.setState({profit})
	}

	getProfit = () => {
		return this.state.profit
	}

	setVolume = (volume) => {
		this.setState({volume})
	}

	getVolume = () => {
		return this.state.volume
	}

	setConvertTo = (convert_to) => {
		this.setState({convert_to})
	}

	getConvertTo = () => {
		//console.log(`getConvertTo ${this.state.convert_to}`)
		return this.state.convert_to
	}

	setNetwork = (network) => {
		this.setState({network})
	}

	getNetwork = () => {
		//console.log(`getNetwork ${this.state.network}`)
		return this.state.network
	}

	setScrapProfil = (scrap_profil) => {
		this.setState({scrap_profil})
	}

	getScrapProfil = () => {
		//console.log(`getScrapProfil ${this.state.scrap_profil}`)
		return this.state.scrap_profil
	}

	setDexag_eth_ask_exchange = (dexag_eth_ask_exchange) => {
		this.setState({dexag_eth_ask_exchange})
	}

	getDexag_eth_ask_exchange = () => {
		return this.state.dexag_eth_ask_exchange
	}

	setDexag_bsc_ask_exchange = (dexag_bsc_ask_exchange) => {
		this.setState({dexag_bsc_ask_exchange})
	}

	getDexag_bsc_ask_exchange = () => {
		return this.state.dexag_bsc_ask_exchange
	}

	setScraping_dexag_ask = (id, val) => {
		//console.log(`setScrapStatus id: ${id} val: ${val} | state with id: ${this.state.exchanges[id].nama_exchange}`)
		const exchangeIndex = this.state.exchanges.findIndex(data => data.exchange_id === id)
		let newArray = [...this.state.exchanges]
		newArray[exchangeIndex] = {...newArray[exchangeIndex], is_scraping_dexag_ask: val}
		//console.table(newArray)
		this.setState({exchanges: newArray})

		const exchange_finish_scrap = this.state.exchanges.filter( (exchange) => { 
			return exchange.is_scraping_dexag_ask === 0;
		});

		if ( Object.keys(exchange_finish_scrap).length === Object.keys(this.state.exchanges).length ) {
			console.log(`MarketScrap.js setScraping_dexag_ask ${id} = ${val} FINISH Scraping_dexag_ask`)
			//this.scrapCountSet.current.calcArbitExt( 1 )
			//setTimeout(() => { this.kalkulator.current.getKalkulatorKonversi() }, 3000)
		} else {
			console.log(`MarketScrap.js setScraping_dexag_ask ${id} = ${val} NOT FINISH Scraping_dexag_ask: ${ Object.keys(exchange_finish_scrap).length }`)
		}
	}

	render() {

		const toggleTab = tab => {
			if (this.state.activeTab !== tab) this.setActiveTab(tab);
		}

		return (
			<Container className="App">
				<Nav tabs>
					<NavItem>
						<NavLink style={{ cursor: "pointer", fontSize: 12, backgroundColor: this.state.activeTab === '1' ? 'Orange' : 'DarkGrey' }}
							className={classnames({ active: this.state.activeTab === '1' })}
							onClick={() => { toggleTab('1'); }}>
							Kalkulator
            			</NavLink>
					</NavItem>

					<NavItem>
						<NavLink style={{ cursor: "pointer", fontSize: 12, backgroundColor: this.state.activeTab === '2' ? 'Orange' : 'DarkGrey' }}
							className={classnames({ active: this.state.activeTab === '2' })}
							onClick={() => { toggleTab('2'); }}>
							Report Parameter
            			</NavLink>
					</NavItem>

					<NavItem>
						<NavLink style={{ cursor: "pointer", fontSize: 12, backgroundColor: this.state.activeTab === '3' ? 'Orange' : 'DarkGrey' }}
							className={classnames({ active: this.state.activeTab === '3' })}
							onClick={() => { toggleTab('3'); }}>
							DEX Tools
            			</NavLink>
					</NavItem>
				</Nav>

				<TabContent activeTab={this.state.activeTab}>
					<TabPane tabId="1" style={{ backgroundColor: 'Orange' }}>
						<KalkulatorKonversi ref={this.kalkulator} />
					</TabPane>

					<TabPane tabId="2" style={{ backgroundColor: 'Orange' }}>
						<Row>
							<Col sm="12">
								<MarketsScrapCountSet ref={this.scrapCountSet} exchanges={this.state.exchanges} 
									setScraping={this.setScraping} setParentModal={this.setParentModal} 
									setProfit={this.setProfit} setVolume={this.setVolume} 
									setConvertTo={this.setConvertTo} setNetwork={this.setNetwork}
									setScrapProfil={this.setScrapProfil} setServerExtUrl={this.setServerExtUrl}
									setDexag_eth_ask_exchange={this.setDexag_eth_ask_exchange} 
									setDexag_bsc_ask_exchange={this.setDexag_bsc_ask_exchange}
									getConvertTo={this.getConvertTo} getNetwork={this.getNetwork}
									/>
							</Col>
						</Row>
						<Row>
							<Col sm="12">
								<MarketsScrapTable exchanges={this.state.exchanges} setScraping={this.setScraping}
									parentModal={this.state.parentModal} getConvertTo={this.getConvertTo} 
									getNetwork={this.getNetwork} getScrapProfil={this.getScrapProfil} 
									getDexag_eth_ask_exchange={this.getDexag_eth_ask_exchange}
									getDexag_bsc_ask_exchange={this.getDexag_bsc_ask_exchange}
									setScraping_dexag_ask={this.setScraping_dexag_ask}
									getServerExtUrl={this.getServerExtUrl}
								/>
							</Col>
						</Row>
					</TabPane>

					<TabPane tabId="3" style={{ backgroundColor: 'Orange' }}>
						<DexToolTable ref={this.dexScrapCountSet} dexs={this.state.dexs} 
							setDexScraping={this.setDexScraping} getProfit={this.getProfit} getVolume={this.getVolume}
						/>
					</TabPane>

				</TabContent>
			</Container>
		)
	}
}