import React from 'react'
import { Table, Row, Col, Badge, Button, InputGroup, InputGroupAddon, 
        InputGroupText, Input, UncontrolledCollapse
        } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class SimulasiArbitAssets extends React.Component {
    constructor(props) {
        super(props)
        this.hargaBeliInput = React.createRef();
        this.jumlahBeliInput = React.createRef();
        this.totalBeliInput = React.createRef();
        this.konversiAssetAsalInput = React.createRef();
        this.konversiAssetTujuanInput = React.createRef();

        this.state = {
            saldo_asset: [],
            konversi_asset: [],
            market_konversi_byExchange: [],
            konversi_asal_asset: '',
            konversi_asal_token: '',
            konversi_asal_saldo: 0,
            konversi_tujuan_token: '',
            konversi_tujuan_saldo: 0,
            konversi_open_orders: [],
            exchange_id_a: '',
            exchange_id_b: '',
            koin:'',
            pair_a:'', 
            pair_b:'', 
            total_beli:0,
            orderbook_beli: [],
            jumlah_withdraw: 0,
            visibilityOrderKonversi: 'hidden',
            visibilityOrderBeli: 'hidden',
            visibilityWalletTujuan: 'hidden',
            wallet_options: [],
            wallet_tujuan: '',
            busy_konversi: '',
            convert_to: '',
            network: ''
         }
    }

    componentDidMount() {
        this.getSettings()
    }

    getSettings = () => {
/*         this.props.settings.map(item => {
            if ( item.id === "convert_to" ) {
                this.setState({'convert_to': item.val})
            } else if ( item.id === "network") {
                this.setState({'network': item.val})
            }
        }) */

        this.setState( {'convert_to': this.props.settings_main.convert_to} )
        this.setState( {'network': this.props.settings_main.network} )
    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
     }

     getExchangeMarketKonversi = async () => {
        const convert_to = this.state.convert_to
        const network = this.state.network
        const exchange_id = this.props.arbit.exchange_id_a
        this.setState({'busy_konversi': 'PROSES...'})

        await fetch(API_URL + `market-konversi/${convert_to}/${network}/${exchange_id}`, {
            method: 'GET',
            //headers: {'Content-Type': 'application/json'},
            headers: authHeader(),
        })
        .then(async res => {
            const market_konversi_byExchange = await res.json()
            this.setState({market_konversi_byExchange})
            this.setState({'busy_konversi': ''})
        })
     }

    getSaldoAssets = async () => {
        this.setState({'busy_konversi': 'PROSES...'})
        const username = this.getCurrentUser()
        const exchange_id = this.props.arbit.exchange_id_a

        await fetch(API_URL + `report/saldo-assets`, {
            method: 'POST',
            //headers: {'Content-Type': 'application/json'},
            headers: authHeader(),
            body: JSON.stringify({
                'username': username,
				'exchange_id': exchange_id
			})
        })
        .then(async res => {
            const saldo_asset = await res.json()
            this.setState({saldo_asset})
            this.getExchangeMarketKonversi()
            this.setState({'busy_konversi': ''})
            //console.log(`saldo asset ${exchange_id} ${saldo_asset}`)
            //console.table( saldo_asset )
            // PR harus segera diseleseikan
            this.getWalletOptions()
            //console.table( this.state.saldo_asset )
        })
    }

    decimal9 = (x) => Number.parseFloat(x).toFixed(10)
    decimal4 = (x) => Number.parseFloat(x).toFixed(4)
    decimal6 = (x) => Number.parseFloat(x).toFixed(6)

    handleHargaBeliChange = () => {
        try {
            this.jumlahBeliInput.current.value = 
                this.decimal4(this.decimal4(this.totalBeliInput.current.value) / this.decimal9(this.hargaBeliInput.current.value))
        } catch (e) {
            //this.jumlahInput.current.value = 0
            console.log(`err handleHargaChange: ${e}`)
        }
    }

    handleJumlahBeliChange = () => {
        try {
            this.totalBeliInput.current.value = 
                this.decimal4(this.decimal9(this.hargaBeliInput.current.value) * this.decimal4(this.jumlahBeliInput.current.value))
        } catch (e) { console.log(`err setJumlahHargaBeli ${e}`) }
    }

    handleTotalBeliChange = (e) => {
        try {
            this.setState({[e.target.name]: e.target.value})
            this.jumlahBeliInput.current.value = 
                this.decimal4( this.decimal4(this.totalBeliInput.current.value) / this.decimal9(this.hargaBeliInput.current.value) )
        } catch (e) { 
            //this.totalEthInput.current.value = 0
            console.log(`err handleTotalBeliChange: ${e}`)
        }
    }

    getCurrentUser = () => {
		const user = JSON.parse(localStorage.getItem('user'))
        return user.username
	}

    handleKonversiAsalChange = (e) =>{
        this.setState({konversi_asal_asset: e.target.value})
        const values = (e.target.value).split(';');
        const asal_token = values[0]
        const asal_saldo = values[1]
        //console.log(`select token : ${asal_token}, saldo : ${asal_saldo}`)
        this.setState({konversi_asal_saldo: asal_saldo})
        this.konversiAssetAsalInput.current.value = asal_saldo
        if ( this.props.arbit.exchange_id_a==="BINA" || this.props.arbit.exchange_id_a==="BINA_BSC" ) {
            this.setState({konversi_asal_token: asal_token})
            this.setState({konversi_asal_saldo: asal_saldo})
        } else if ( this.props.arbit.exchange_id_a==="INDO" )
            this.setState({konversi_asal_token: asal_token.toLowerCase()})
    }

    handleKonversiTujuanChange = (e) =>{
        if ( this.props.arbit.exchange_id_a==="BINA" || this.props.arbit.exchange_id_a==="BINA_BSC" ) {
            this.setState({konversi_tujuan_token: e.target.value})
        } else if ( this.props.arbit.exchange_id_a==="INDO" )
            this.setState({konversi_tujuan_token: e.target.value.toLowerCase()})
    }
    // params : exchange_id, convert_to, network, dari_aset, ke_aset, jml_aset, 
    konversiAsset = async (mode) => {
        const exchange_id = this.props.arbit.exchange_id_a
        const convert_to = this.state.convert_to
        const network = this.state.network
        const dari_aset = this.state.konversi_asal_token
        const ke_aset = this.state.konversi_tujuan_token
        const jml_aset = this.state.konversi_asal_saldo
        const username = this.getCurrentUser()

        await fetch(API_URL + `report/konversi-asset`, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                'exchange_id': exchange_id,
                'convert_to': convert_to,
                'network': network,
                'dari_aset': dari_aset,
                'ke_aset': ke_aset,
                'jml_aset': jml_aset,
                'mode' : mode,
                'username' : username
            })
        })
        .then(async res => {
            const konversi_asset = await res.json()
            this.setState({ 
                konversi_asset 
            }, async() => {
                this.setState({konversi_tujuan_saldo: await this.decimal4( konversi_asset['konversi_tujuan_saldo'] )})
            })
        })
    }

    handleProsenKonversiChange = (prosen) => {
        const values = (this.state.konversi_asal_asset).split(';')
        const asal_saldo = (prosen / 100) * values[1]
        this.setState({konversi_asal_saldo: asal_saldo})
    }

    getOpenOrdersKonversi = async () => {
        const username = this.getCurrentUser()
        const exchange_id = this.props.arbit.exchange_id_a
        const market = (this.state.konversi_asal_token + "_" + this.state.konversi_tujuan_token)

        this.setState({'visibilityOrderKonversi': 'visible'})

        await fetch(API_URL + `report/open-orders`, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                'username': username,
                'exchange_id': exchange_id,
                'symbol': market
            })
        })
        .then(async res => {
            const konversi_open_orders = await res.json()
            this.setState({ 
                konversi_open_orders
            }, () => {
                this.setState({'visibilityOrderKonversi': 'hidden'})
            })
        })
    }

    getItemOpenOrderKonversi = (id) => {
        const exchange_id = this.state.exchange_id_a
        const username = this.getCurrentUser()

        this.state.konversi_open_orders.filter(function (item) {
            return item.orderId === id;
        }).map(function ({ datetime, symbol, type, side, price, amount, filled }) {

            ; (async () => {
                await fetch(API_URL + `report/open-orders/cancel`, {
                    method: 'POST',
                    headers: authHeader(),
                    body: JSON.stringify({
                        'exchange_id': exchange_id,
                        'username': username,
                        'datetime': datetime,
                        'symbol': symbol,
                        'type': type,
                        'side': side,
                        'price': price,
                        'amount': amount,
                        'filled': filled,
                    })
                })
            })();

            //return { datetime, symbol, type, side, price, amount, filled };
        });
    }

/*     getPreviewExecBuy = async () => {
        this.setState({visibilityOrderBeli: 'visible'})
        const exchange_id = this.props.arbit.exchange_id_a
        const market = (this.props.arbit.koin + "_" + this.props.arbit.pair_a)
        const bid_info = this.props.hargaBeli

        await fetch(API_URL + `report/preview-exec-buy`, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                'exchange_id': exchange_id,
                'market': market,
                'modal': this.state.total_beli,
                'bid_info': bid_info,
            })
        })
        .then(async res => {
            const prev_exec_buy = await res.json()
            this.setState({'visibilityOrderKonversi': 'hidden'})
            this.setState({ orderbook_beli: prev_exec_buy['markets'][market] })
            console.log("getPreviewExecBuy ", prev_exec_buy['markets'][market])
        })
    } */

    getWalletOptions = async() => {
        const exchange_id = this.props.arbit.exchange_id_b
        const network = this.state.network
        const symbol = this.props.arbit.koin
        const username = this.getCurrentUser()

        this.setState({'visibilityWalletTujuan': 'visible'})

        await fetch(API_URL + `wallets/address`, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                'exchange_id': exchange_id,
                'network': network,
                'symbol': symbol,
                'username': username
            })
        })
        .then(async res => {
            const wallet_options = await res.json()
            this.setState({ 
                wallet_options
            }, () => {
                this.setState({'visibilityWalletTujuan': 'hidden'})
            })
        })
    }

    getWithdrawAsset = async () => {
        this.setState({'visibilityWalletTujuan': 'visible'})
        const username = this.getCurrentUser()
        const exchange_id = this.props.arbit.exchange_id_a

        await fetch(API_URL + `report/saldo-assets`, {
            method: 'POST',
            //headers: {'Content-Type': 'application/json'},
            headers: authHeader(),
            body: JSON.stringify({
                'username': username,
				'exchange_id': exchange_id
			})
        })
        .then(async res => {
            const saldo_asset = await res.json()
            const koin = this.props.arbit.koin

            let jumlah_withdraw = saldo_asset.filter(function(item) {
                return item.token === koin
             }).map(function({saldo}) {                
                return saldo;
             });

             this.setState({ 'jumlah_withdraw': this.decimal4( jumlah_withdraw ) })
             this.setState({'visibilityWalletTujuan': 'hidden'})
        })
    }

     render() {
         
         let konversi_open_orders = this.state.konversi_open_orders.map(v1 => {

             return (
                 <tr key={v1.orderId}>
                     <td scope="row">
                         <Button color="danger" size="sm" onClick={()=>this.getItemOpenOrderKonversi( v1.orderId )}> Cancel </Button>
                     </td>
                     <td style={{ fontSize: 12, fontWeight: 'normal'}}>{v1.datetime}</td>
                     <td>{v1.symbol}</td>
                     <td style={{ fontSize: 12, fontWeight: 'normal'}}>{v1.type}</td>
                     <td style={{ fontSize: 12, fontWeight: 'normal'}}>{v1.side}</td>
                     <td>{v1.price}</td>
                     <td>{v1.amount}</td>
                     <td>{v1.filled}</td>
                     <td>{this.decimal6(v1.price * v1.amount)}</td>
                 </tr>
             )
         })

         const objs = Object.entries(this.state.orderbook_beli)
         const orderbook_beli = objs.map(([k1, v1]) => {
            return (
                <tbody key={10} style={{cursor: "pointer"}}>
                    <tr align="right" key={2} onClick={()=>this.setHrgBeli(2)}>
                        <td scope="row"> {3} </td>
                        <td> {v1[2][0]} </td>
                        <td> {v1[2][1]} </td>
                        <td> {v1[2][2]} </td>
                        <td> {this.decimal4(parseFloat(v1[0][2]) + parseFloat(v1[1][2]) + parseFloat(v1[2][2]))} </td>
                    </tr>
                    <tr align="right" key={1} onClick={()=>this.setHrgBeli(1)}>
                        <td scope="row"> {2} </td>
                        <td> {v1[1][0]} </td>
                        <td> {v1[1][1]} </td>
                        <td> {v1[1][2]} </td>
                        <td> {this.decimal4(parseFloat(v1[0][2]) + parseFloat(v1[1][2]))} </td>
                    </tr>
                    <tr align="right" key={0} onClick={()=>this.setHrgBeli(0)}>
                        <td scope="row"> {1} </td>
                        <td> {v1[0][0]} </td>
                        <td> {v1[0][1]} </td>
                        <td> {v1[0][2]} </td>
                        <td> {v1[0][2]} </td>
                    </tr>
                </tbody>
            )
         }) // const orderbook

        return (
            <>
                <Row form>
                    <Col sm="12">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">{'1. KONVERSI'}</InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <Button color="warning" size="sm" onClick={() => this.getSaldoAssets()}>ASSETS</Button> { ' ' }
                            </InputGroupAddon>
                            
                            <InputGroupAddon addonType="append">
                                <Button color="info" size="sm" id="toggler-konversi">OPEN {this.state.busy_konversi}</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>

                <UncontrolledCollapse toggler="#toggler-konversi">
                <Row form>
                    <Col sm="12">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend"> DR </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <Input type="select" name="konversi_token_asal" onChange={this.handleKonversiAsalChange}>
                                    <option value="-">-DARI-</option>
                                    {this.state.saldo_asset.map((option) => (
                                        <option value={option.token+';'+option.saldo}>
                                            {option.token + ' (' + option.saldo + ')'}
                                        </option>
                                    ))}
                                </Input>
                            </InputGroupAddon>
                            <Input type="number" innerRef={this.konversiAssetAsalInput} onChange={this.handleChange} name="konversi_asal_saldo" value={this.state.konversi_asal_saldo} />
                        </InputGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col sm="12">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend"> KE </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <Input type="select" name="konversi_token_tujuan" onChange={this.handleKonversiTujuanChange}>
                                    <option value="-">-KE-</option>
                                    {this.state.market_konversi_byExchange.map((option) => (
                                        <option value={option.pair}>
                                            { option.pair }
                                        </option>
                                    ))}
                                    {/* {this.state.saldo_asset.map((option) => (
                                        <option value={option.token}>
                                            { option.token }
                                        </option>
                                    ))} */}
                                </Input>
                            </InputGroupAddon>
                            <InputGroupAddon addonType="append"> <Button color="danger" onClick={() => this.handleProsenKonversiChange(100)}>100</Button> </InputGroupAddon>
                            <InputGroupAddon addonType="append"> <Button color="warning" onClick={() => this.handleProsenKonversiChange(75)}>75</Button> </InputGroupAddon>
                            <InputGroupAddon addonType="append"> <Button color="success" onClick={() => this.handleProsenKonversiChange(50)}>50</Button> </InputGroupAddon>
                            <InputGroupAddon addonType="append"> <Button color="primary" onClick={() => this.handleProsenKonversiChange(25)}>25</Button> </InputGroupAddon>

                        </InputGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col sm="12">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend"> = </InputGroupAddon>
                            <Input type="number" innerRef={this.konversiAssetTujuanInput} onChange={this.handleChange} name="konversi_tujuan_saldo" value={this.state.konversi_tujuan_saldo} />
                            <InputGroupAddon addonType="append">
                                <Button color="warning" onClick={()=> this.konversiAsset('preview')}>PREV</Button>
                            </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <Button color="danger" onClick={()=> this.konversiAsset('exec')}>KONVERSI</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col md={12}> { '* OPOR: Open Order | PREV: Preview'} </Col>
                </Row>

                <Row form>
                    <Col md={12} className="text-left text-md-left">
                        
                            <Button color="info" size="sm" onClick={() => this.getOpenOrdersKonversi()}>OPOR Konversi</Button> { ' ' }
                            <span style={{visibility: this.state.visibilityOrderKonversi }}><b>Proses</b></span>
                            {/* <Badge color="warning" style={{ cursor: "pointer" }} onClick={()=> this.getOpenOrdersKonversi()}> Open Order Konversi </Badge> */}
                            <Table responsive hover striped size="sm" dark>
                                <thead>
                                    <tr>
                                        <th> Aksi </th>
                                        <th> Date </th>
                                        <th> Pair </th>
                                        <th> Tipe </th>
                                        <th> Side </th>
                                        <th> Price </th>
                                        <th> Amount </th>
                                        <th> Filled </th>
                                        <th> Total </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {konversi_open_orders}
                                </tbody>
                            </Table>
                        
                    </Col>
                </Row>

                </UncontrolledCollapse>
{/* 
                <Row form>
                    <Col sm="12">
                        <b> { '2. '} </b> 
                            { 'Beli ' } 
                        <b> { this.props.arbit.koin + '/' + this.props.arbit.pair_a } </b>
                        <b> { ' [' + this.props.hargaBeli + ']' } </b>
                        <span style={{visibility: this.state.visibilityOrderBeli }}><b>Proses</b></span>
                    </Col>
                </Row>

                <Row form>
                    <Col sm="12">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button id="toggler-beli" color="info">OPEN</Button>
                            </InputGroupAddon>
                            <Input innerRef={this.hargaBeliInput} onChange={this.handleHargaBeliChange} name="harga_beli" />
                            <InputGroupAddon addonType="append"><InputGroupText> X </InputGroupText></InputGroupAddon>
                            <Input innerRef={this.jumlahBeliInput} onChange={this.handleJumlahBeliChange} name="jumlah_beli" />
                        </InputGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col sm="12">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button color="warning">TOT</Button>
                            </InputGroupAddon>
                            <Input type="number" innerRef={this.totalBeliInput} onChange={this.handleTotalBeliChange} name="total_beli" value={this.state.total_beli} />
                            <InputGroupAddon addonType="append">
                                <Button color="warning" onClick={()=> this.getPreviewExecBuy()}>PREV</Button>
                            </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <Button color="success">BELI</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>

                <UncontrolledCollapse toggler="#toggler-beli">
                <Row form>
                    <Col md={12} className="text-right text-md-right">
                        <Table responsive hover striped size="sm" dark>
                            <thead>
                                <tr>
                                    <th> # </th>
                                    <th> Price </th>
                                    <th> Amount </th>
                                    <th> Total </th>
                                    <th> SUM(Total) </th>
                                </tr>
                            </thead>

                            { orderbook_beli }

                        </Table>
                    </Col>
                </Row>

                <Row form>
                    <Col md={12} className="text-left text-md-left">
                            <Button color="success" size="sm">Open Order Beli</Button> { ' ' }
                            <Table responsive hover striped size="sm" dark>
                                <thead>
                                    <tr>
                                        <th> Aksi </th>
                                        <th> Date </th>
                                        <th> Pair </th>
                                        <th> Tipe </th>
                                        <th> Side </th>
                                        <th> Price </th>
                                        <th> Amount </th>
                                        <th> Filled </th>
                                        <th> Total </th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </Table>
                        
                    </Col>
                </Row>
                </UncontrolledCollapse>
 */}
                <Row form>
                    <Col sm="12">
                        <b> {'2. '} </b>
                        { 'WDRAW' }
                        <b> {`${this.props.arbit.koin} `} </b>
                        <b> { `${this.props.arbit.exchange_id_a} ke ${this.props.arbit.exchange_id_b}`} </b>
                    </Col>
                </Row>

                <Row form>
                    <Col sm="12">
                        <b> {`${this.state.wallet_tujuan}` } </b>
                    </Col>
                </Row>

                <Row form>
                    <Col sm="12">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button color="primary" onClick={()=> this.getWalletOptions()}>
                                    ADDR
                                </Button>
                            </InputGroupAddon>
                            <Input type="select" name="wallet_tujuan" onChange={this.handleChange}>
                                <option value={''}>-TUJUAN-</option>
                                {this.state.wallet_options.map((option) => (
                                    <option value={option.depo_address}  selected={this.props.arbit.koin.toUpperCase() === option.symbol.toUpperCase()}>{option.nama_wallet}</option>
                                ))}
                            </Input>
                        </InputGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col sm="12">
                            { `ASSET ` } 
                            <span style={{visibility: this.state.visibilityWalletTujuan }}>Proses</span>
                    </Col>
                </Row>

                <Row form>
                    <Col sm="12">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button color="primary" onClick={()=> this.getWithdrawAsset()}>JML</Button>
                            </InputGroupAddon>
                            <Input type="number" innerRef={this.jumlahWithdrawInput} onChange={this.handleChange} 
                                name="jumlah_withdraw" value={this.state.jumlah_withdraw} />
                            <InputGroupAddon addonType="append">
                                <Button color="warning">PREV</Button>
                            </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <Button color="primary">WDRAW</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>

            </>
        )
     }
}

export default SimulasiArbitAssets