import React, { Component } from 'react'
import { Row, Col, InputGroup, InputGroupAddon, Input, InputGroupText } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class DexToolCountUp extends Component {
	constructor() {
		super();
		this.state = {
			minutes: 0,
			seconds: 0,
			scrapMinutes: 0,
			scrapSeconds: 0,
			dex: []
		};
		this.urlInput = React.createRef();
	}

	componentDidMount() {
		this.setState(
			{dex: this.props.dex}, 
			() => {
				this.urlInput.current.value = this.state.dex.url;
			})
		
	}

 	componentDidUpdate(prevProps, prevState) {
		if ( this.props.dex !== prevProps.dex ) {
			this.setState({
				dex: this.props.dex}, 
				()=> { 
					console.log(`DexToolCountUp componentDidUpdate exchange: ${this.state.dex.exchange_id} | isScraping now: ${this.state.dex.is_scraping}`)
					console.table( this.state.dex )
					if ( this.state.dex.is_scraping==1 ) {
						this.startScraping()
					}
				})

		}
	}

	inputHandler = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	convertToSeconds = (minutes, seconds) => {
		return seconds + minutes * 60;
	};

	startTimer = () => {
		this.timer = setInterval(this.countUp, 1000);
	};

	startScraping = async () => {
 		const exchange_id = this.state.dex.exchange_id
		this.startTimer()

		await fetch(API_URL + `ext/dex-orderbooks`,
		{
			method: 'post', 
			headers: authHeader(),
			body: JSON.stringify({
				exchange_id: this.state.dex.exchange_id,
				url: this.urlInput.current.value,
				modal: 1
			})
		})
		.then(async response => { 
			await this.props.setDexScraping(this.state.dex.exchange_id, 0)
			const min = this.state.minutes, sec = this.state.seconds
			this.setState({scrapMinutes: min, scrapSeconds: sec})
			this.stopTimer()
			this.resetTimer()

			//console.log(`response server_ext_url ${response[0].server_ext_url}`)
			const res = await response.json()
			//console.log(`res server_ext_url ${res[0].server_ext_url}`)
			this.setState({'server_ext_url': res[0].server_ext_url})
		})
		.catch(err => console.log(`error fetch orderbooks: ${err}`))
		console.log(`beginning orderbooks scrap ${exchange_id}`)
	}

	countUp = () => {
		const { minutes, seconds } = this.state;
		let c_seconds = this.convertToSeconds(minutes, seconds);

		//if (this.state.isScraping) {
			// seconds change
			//seconds ? this.setState({ seconds: seconds + 1 }) : this.setState({ seconds: 59 });
			this.setState({ seconds: seconds + 1 })
			// minutes change
			if (c_seconds % 60 === 0 && minutes) {
				this.setState({ minutes: minutes + 1 });
			}

		//}
	};

	stopTimer = () => {
		clearInterval(this.timer);
	};

	resetTimer = () => {
		this.setState({
			minutes: 0,
			seconds: 0
		});
	};

	render() {
		const { minutes, seconds } = this.state;
	
		return (
			<>
				<Row>
					<Col md={12} style={{ fontSize: 13, fontWeight: 'bold'}}>
						<InputGroup size="sm">
							<InputGroupAddon addonType="prepend">
								<Input type="text" innerRef={this.urlInput} onChange={this.handleChange} bsSize="sm" name="url" />
							</InputGroupAddon>
							<InputGroupAddon addonType="append">
								<InputGroupText>{minutes} : {seconds} {" "}</InputGroupText>
							</InputGroupAddon>
							<InputGroupAddon addonType="append">
								<InputGroupText>Time { this.state.scrapMinutes} :  { this.state.scrapSeconds} { ' ' }</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</Col>
				</Row>
			</>
		);
	  }

}

export default DexToolCountUp