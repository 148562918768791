import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap'

export default class LogAioTrade extends React.Component {
    constructor(props) {
		super(props)
		this.state = { modal: false }
    }

    toggle = () => {
		this.setState(prevState => ({
			modal: !prevState.modal
		}))
    }

    render() {
        const closeBtn = <button className="close" onClick={this.toggle}> &times; </button>
		const label = this.props.buttonLabel
		let button = ''
        let title = ''
        
        if (label === "Edit") {
			button = 
				<Button color="warning"
					onClick={this.toggle}
					style={{ float: "left", marginRight: "10px"}} >
					{label}
				</Button>
				title = "Edit Logs"
		} else {
			button = 
				<Button color="success"
					onClick={this.toggle}
					style={{ float: "left", marginRight: "10px" }}>
						{label}
				</Button>
				title = "Informasi"
        }

        return (
            <>
                {button}
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle} close={closeBtn}> {title}	</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                { 'waktu : ' + this.props.log_aio.waktu + ' - User : ' + this.props.log_aio.username }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                { this.props.log_aio.info }  
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.toggle}>Delete</Button> { ' ' }
                        <Button color="secondary" onClick={this.toggle}>Batal</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}