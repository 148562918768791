import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ScrapProfilForm from '../Forms/ScrapProfil.Form'

export default class ScrapProfilModal extends Component {

    constructor(props) {
		super(props)
		this.state = {
			modal: false
		}
	}

	toggle = () => {
		this.setState(prevState => ({
			modal: !prevState.modal
		}))
	}


    render() {
		const closeBtn = <button className="close" onClick={this.toggle}> &times; </button>
		const label = this.props.buttonLabel
		let button = ''
		let title = ''

		if (label === 'Edit') {
			button =
				<Button color="warning"
					onClick={this.toggle}
					style={{ float: "left", marginRight: "10px" }}>
					{label}
				</Button>
			title = "Edit Scrap Profil"
		} else {
			button =
				<Button color="success"
					onClick={this.toggle}
					style={{ float: "left", marginRight: "10px" }}>
					{label}
				</Button>
			title = "Add Scrap Profil"
		}

		return(
			<div>
				{button}
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
					<ModalHeader toggle={this.toggle} close={closeBtn} > {title} </ModalHeader>
					<ModalBody>
						<ScrapProfilForm
							addScrapProfilToState={this.props.addScrapProfilToState}
							updateScrapProfilState={this.props.updateScrapProfilState}
							toggle={this.toggle}
							scrap_profil={this.props.scrap_profil} />
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.toggle}>Submit</Button>{' '}
						<Button color="secondary" onClick={this.toggle}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

}