import React, { Component } from 'react'
import { Table, Button } from 'reactstrap'
import UsersExchangeModal from '../Modals/UsersExchange.Modal'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

export default class UsersExchange extends React.Component {
    deleteUsersExchange = (id) => {
		let confirmDelete = window.confirm(`Hapus Konfigurasi Exchange (${id}) ?`)
		if ( confirmDelete ) {
			fetch(API_URL + `users-exchange/delete`,
				{
					method: 'delete',
					//headers: { 'Content-Type': 'application/json' },
                    headers: authHeader(),
					body: JSON.stringify({ users_exchange_id : id })
				}
			)
				.then(response => response.json())
				.then(item => { this.props.deleteUsersExchangeFromState(id) })
				.catch(err => console.log(`err: ${err}`))
		}
    }
    
    render() {
        let count = 0
        const users_exchange = this.props.users_exchange.map(user_exchange => {
            count++
            return (
                <tr key={user_exchange.users_exchange_id}>
                    <td>{count}</td>
                    <td>{user_exchange.waktu}</td>
                    <td>{user_exchange.exchange_id}</td>
                    <td>{user_exchange.aktif}</td>
                    <td>
                        <div style={{ width: "120px" }}>
                            <UsersExchangeModal buttonLabel="Edit" user_exchange={user_exchange} 
                                updateUsersExchangeState={this.props.updateUsersExchangeState} />
                            {' '}
                            <Button color="danger" onClick={() => this.deleteUsersExchange(user_exchange.users_exchange_id)}> Del </Button>
                        </div>
                    </td>
                </tr>
            )
        });

        return (
            <Table responsive hover striped size="sm" dark>
                <thead>
                    <tr>
                        <th> # </th>
                        <th> Waktu </th>
                        <th> Exchange </th>
                        <th> Sts </th>
                        <th> AKSI </th>
                    </tr>
                </thead>
                <tbody>
                    {users_exchange}
                </tbody>
            </Table>
        )
    }
}