import React, { Component } from 'react'
import { Row, Col, Input, Button, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class MarketsScrapCountSet extends Component {
	constructor(props) {
		super(props)
		this.minutesInput = React.createRef();
		this.secondsInput = React.createRef();
		this.profitInput = React.createRef();
		this.volumeInput = React.createRef();
		this.modalInput = React.createRef();
	}

	state = {
		minutes: 0,
		seconds: 5,
		profit: 0,
		volume: 0,
		modal: 0,
		settings: [],
		exchanges: [],
		scrap_finish_time: '',
		networks: [],
		converts: [],
		network: 'ETH',
		convert_to: 'ETH',
		scrap_profiles: [],
		server_ext_url: '',
		ext_server_options: []
	}
	
	componentDidMount() {
		this.getSettings()
		this.getNetworkConvert()
		this.getScrapProfil()
	}

	getSettings = () => {
		const user = JSON.parse(localStorage.getItem('user'));
		const username = user.username
		fetch(API_URL + `profile-system/settings/${username}`, {
			method: 'GET',
			headers: authHeader()
		})
		.then( res => res.json() )
		.then( settings => {
			this.setState({settings}) 
			settings.map(item => {
				if ( item.id === "timer" ) {
					const timer = item.val
					var timer_r = timer.split(':');
					this.minutesInput.current.value = timer_r[0];
					this.setState({minutes: timer_r[0]})
					this.secondsInput.current.value = timer_r[1];
					this.setState({seconds: timer_r[1]})
				} else if ( item.id === "profit" ) {
					this.profitInput.current.value = item.val;
					this.setState({profit: item.val})
					this.props.setProfit( item.val )
				} else if ( item.id === "modal" ) {
					this.modalInput.current.value = item.val;
					this.setState({modal: item.val})
					this.props.setParentModal(item.val)
				} else if ( item.id === "volume" ) {
					this.volumeInput.current.value = item.val;
					this.setState({volume: item.val})
					this.props.setVolume( item.val )
				} else if ( item.id === "server_ext1_url" ) {
					this.props.setServerExtUrl( item.val )
					this.state.server_ext_url = item.val
				} else if ( item.id === "dexag_eth_ask_exchange" ) {
					console.log("SETTING dexag_eth_ask_exchange ", item.val)
					this.props.setDexag_eth_ask_exchange( item.val )
				} else if ( item.id === "dexag_bsc_ask_exchange" ) {
					this.props.setDexag_bsc_ask_exchange( item.val )
				}
			})
		})
		.then( res => this.getExtServerOptions() )
		.catch(err => console.log(err))
	}

	getExtServerOptions = () => {
		fetch(API_URL + `profile-system/ext-server`, {
			method: 'GET',
		})
			.then(async res => {
				this.setState({ 'ext_server_options': await res.json() })
			})
			.catch(err => console.log(err))
	}

	setSettings = (id, new_val) => {
		let settings_id = undefined

		this.state.settings.map(item => {
			if (item.id === id) {
				//console.log(`settings_id: ${item.settings_id} id: ${item.id} new_val: ${new_val}`)
				settings_id = item.settings_id
			}
		})
		fetch(API_URL + 'profile-system/update', {
			method: 'post',
			//headers: {'Content-Type': 'application/json'},
			headers: authHeader(),
			body: JSON.stringify({
				settings_id: settings_id,
				val: new_val
			})
		})
		.then(() => {
/* 			fetch(API_URL + 'ob/create', {
				method: 'GET',
				headers: authHeader()
			})
			.then((res) => { 
				console.log(`Recreate Orderbook Sukses`)
			})
			.catch(err => console.log(err)) */
		})
		.catch(err => console.log("setSettings Error: " + err))
	}

	getNetworkConvert = () => {
		fetch(API_URL + `profile-system/network-convert`, {
			method: 'GET',
			headers: authHeader()
		})
		.then(async res => {
			const network_convert = await res.json()
			//let uniq = [ ...new Set(names) ]
			let networks = [], converts = []

			Object.entries(network_convert).forEach( ([k1, v1])=> {
				networks.push( {'network':v1['network']} );
				converts.push( {'network':v1['network'], 'convert_to':v1['convert_to'] } )
			})

			//console.table( converts )
			// *** remove duplicate string objects
			//let s = new Set(networks)
			//let it = s.values()
			//console.log( Array.from(it) )

			const uniqueNetworks = [...new Map(networks.map(item => [item.network, item])).values()]
			this.setState({'networks': uniqueNetworks })
			this.setState({'converts': converts})
		})
	}

	// scrap-profil/nama-profil
	getScrapProfil = () => {
		fetch(API_URL + `scrap-profil/nama-profil`, {
			method: 'GET',
			headers: authHeader()
		})
		.then(async res => {
			const scrap_profiles = await res.json()
			this.setState({scrap_profiles})
		})
	}

	calcArbitExt = ( silent=1 ) => {
		const user = JSON.parse(localStorage.getItem('user'));
		const username = user.username
		fetch(API_URL + `report/${this.state.profit}/${this.state.volume}/${username}/${this.state.convert_to}/${this.state.network}/calc-arbit`, {
			method: 'GET',
			headers: authHeader()
		})
		.then(() => {
			console.log(`Hitung Selisih Orderbook Berhasil.`)
			//this.props.setRefreshArbits()
			const timestamp = Date.now(); // This would be the timestamp you want to format
			//console.log(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp));
			//const finish_time = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp)

			const options = {
				year: 'numeric', month: 'numeric', day: 'numeric',
				hour: 'numeric', minute: 'numeric', second: 'numeric',
				hour12: false,
				timeZone: 'Asia/Jakarta'
			};
			
			const finish_time = new Intl.DateTimeFormat('id-ID', options).format(timestamp)
			this.setState({scrap_finish_time: finish_time}) 

			if ( silent < 1 ) alert("Hitung Selisih Orderbooks Berhasil !")
		})
		.catch(err => console.log("calcArbitExt Error: " + err))
		 
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	convertToChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		this.props.setConvertTo( e.target.value )
	}

	networkChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		this.props.setNetwork( e.target.value )
	}

	scrapProfilChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		this.props.setScrapProfil( e.target.value )
	}

	convertToSeconds = (minutes, seconds) => {
		return seconds + minutes * 60;
	};

	startTimer = () => {
		fetch(API_URL + `ob/create`, {
			method: 'GET',
			headers: authHeader()
		})
		.then(() => {
			this.setState({scrap_finish_time: 'Proses...'})
			this.timer = setInterval(this.countDown, 1000);
		})
		.catch(err => console.log("clearOrderbooks Error: " + err))

	};

	countDown = () => {
		const { minutes, seconds } = this.state;
		let c_seconds = this.convertToSeconds(minutes, seconds);

		if (c_seconds) {
			// seconds change
			seconds	? this.setState({ seconds: seconds - 1 }) : this.setState({ seconds: 59 });

			// minutes change
			if (c_seconds % 60 === 0 && minutes) {
				this.setState({ minutes: minutes - 1 })
			}

		} else { // counter == 0
			this.props.exchanges.map(exchange => {
				console.log(`counter is over, trigger scraping for exchange: ${exchange.exchange_id}`)
				this.props.setScraping(exchange.exchange_id, 1, "ALL")
			});
			this.stopTimer()
		}
	};

	stopTimer = () => {
		clearInterval(this.timer);
		this.resetTimer()
	};

	resetTimer = () => {
		this.setState({
			minutes: this.minutesInput.current.value, 
			seconds: this.secondsInput.current.value
		})
	};

	clearOrderbooks = () => {
		fetch(API_URL + `ob/create`, {
			method: 'GET',
			headers: authHeader()
		})
		.then(() => {
			alert("Berhasil Clear Orderbooks")
		})
		.catch(err => console.log("clearOrderbooks Error: " + err))
	}

	dexagAskScrap = () => {
		this.props.exchanges.map(exchange => {
			this.props.setScraping(exchange.exchange_id, 1, "DEX_AG")
		})
	}

	render() {
		return (
			<>
{/* 				<Row>
					<Col sm="12">
						<InputGroup size="sm">
							<InputGroupAddon addonType="prepend"> Ext-Srv </InputGroupAddon>
							<InputGroupAddon addonType="append">
								<Input type="select" name="server_ext_url" bsSize="sm" onChange={this.handleChange}>
									<option value="=">===</option>
									{this.state.ext_server_options.map((option) => (
                  <option value={option.url} selected={option.url===this.state.server_ext_url ? true : false}>{option.url}</option>
                ))}
								</Input>
							</InputGroupAddon>
						</InputGroup>
					</Col>
				</Row> */}

				<Row>
					<Col sm="12">
						<InputGroup size="sm">
							<InputGroupAddon addonType="prepend"> NET </InputGroupAddon>
							<InputGroupAddon addonType="append">
								<Input type="select" name="network" bsSize="sm" onChange={this.networkChange}>
									<option value="=">===</option>
									{this.state.networks.map((option) => (
										<option value={option.network} selected={option.network === "ETH" ? true : false}>{option.network}</option>
									))}
								</Input>
							</InputGroupAddon>
							<InputGroupAddon addonType="append"><InputGroupText>TO</InputGroupText></InputGroupAddon>
							<InputGroupAddon addonType="append">
								<Input type="select" name="convert_to" bsSize="sm" onChange={this.convertToChange}>
									<option value="=">===</option>
									{this.state.converts.filter(item => item.network === this.state.network).map(convert => (
										<option value={convert.convert_to}>{convert.convert_to}</option>
									))}

								</Input>
							</InputGroupAddon>

							<InputGroupAddon addonType="append"><InputGroupText>R</InputGroupText></InputGroupAddon>
							<InputGroupAddon addonType="append">
								<Input type="select" bsSize="sm" onChange={this.scrapProfilChange}>
									<option value="all">All</option>
									{this.state.scrap_profiles.map((option) => (
										<option value={option.nama_profil}>{option.nama_profil}</option>
									))}
								</Input>
							</InputGroupAddon>
						</InputGroup>
					</Col>
				</Row>
				
				<Row>
					<Col sm="12">
						<InputGroup size="sm">
							<InputGroupAddon addonType="prepend">Timer Min</InputGroupAddon>
							<Input type="number" innerRef={this.minutesInput} onChange={this.handleChange} bsSize="sm" name="minutes" />
							<InputGroupAddon addonType="append">
								<InputGroupText>Sec</InputGroupText>
							</InputGroupAddon>
							<Input type="number" innerRef={this.secondsInput} onChange={this.handleChange} bsSize="sm" name="seconds" />
							<InputGroupAddon addonType="append">
								<InputGroupText> <b>{this.state.minutes} : {this.state.seconds}</b> </InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</Col>
				</Row>

				<Row>
					<Col sm="12">
						<InputGroup size="sm">
							<InputGroupAddon addonType="prepend">Profit Min</InputGroupAddon>
							<Input name="profit" innerRef={this.profitInput} onChange={this.handleChange} bsSize="sm" value={this.state.profit} />
							<InputGroupAddon addonType="append"><InputGroupText>Vol Min</InputGroupText></InputGroupAddon>
							<Input name="volume" innerRef={this.volumeInput} onChange={this.handleChange} bsSize="sm" value={this.state.volume} />
							<InputGroupAddon addonType="append"><InputGroupText>Modal</InputGroupText></InputGroupAddon>
							<Input name="modal" innerRef={this.modalInput} onChange={this.handleChange} bsSize="sm" value={this.state.modal} />
						</InputGroup>
					</Col>
				</Row>

				<Row>
					<Col sm="12">
						<Button color="danger" size="sm"
							onClick={() => {
								this.setSettings('timer', this.minutesInput.current.value + ':' + this.secondsInput.current.value)
								this.setSettings('profit', this.profitInput.current.value)
								this.setSettings('volume', this.volumeInput.current.value)
								this.setSettings('modal', this.modalInput.current.value)
								this.setSettings('convert_to', this.state.convert_to)
								this.setSettings('network', this.state.network)
								alert("Berhasil Mengubah Pengaturan")
							}}>Set</Button>
						<Button color="success" size="sm" onClick={() => this.startTimer()}>Start</Button>
						<Button color="danger" size="sm" onClick={() => this.stopTimer()}>Stop</Button>
						<Button color="primary" size="sm" onClick={() => this.calcArbitExt(0)}>Calc</Button>
						<Button color="danger" size="sm" onClick={() => this.clearOrderbooks()}>Clear OB</Button>
						<Button color="info" size="sm" onClick={() => this.dexagAskScrap()}>DEX-ASK</Button>
					</Col>
				</Row>

				<Row><Col sm="12"><b>{ this.state.scrap_finish_time }</b></Col></Row>
			</>
		)
	}

}

export default MarketsScrapCountSet