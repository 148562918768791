import React, { Component } from 'react'
//import { Redirect } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap'
import ExchangeModal from './Modals/Exchange.Modal'
import ExchangeTable from './Tables/Exchange.Table'

import authHeader from '../services/auth-header';
import Config from '../Config';
const API_URL = Config.API_URL

export default class Exchange extends Component {
	constructor(props) {
		super(props)
	}

	state = {
		exchanges: [],
		//redirect: null,
	}

	componentDidMount() {
		//const currentUser = this.getCurrentUser();

    //if (!currentUser) this.setState({ redirect: "/login" })
		this.getExchanges()
	}

/* 	getCurrentUser = () => {
		return JSON.parse(localStorage.getItem('user'))
	} */

	getExchanges() {
		fetch(API_URL + 'exchange', {
			method: 'GET',
			headers: authHeader()
		})
			.then(response => response.json())
			.then(exchanges => this.setState({exchanges}))
			.catch(err => console.log(err))
	}

	addExchangeToState = (exchange) => {
		this.setState(prevState => ( {
			exchanges: [...prevState.exchanges, exchange]
		}))
	}

	updateExchangeState = (exchange) => {
		const exchangeIndex = this.state.exchanges.findIndex(data => data.exchange_id === exchange.exchange_id)
		const newArray = [
			// desctructure all exchanges from beginning to the indexed exchange
			...this.state.exchanges.slice(0, exchangeIndex),
			// add the updated exchange to the array
			exchange,
			// add the rest of the exchanges to the array from the index after the replaced exchange
			...this.state.exchanges.slice(exchangeIndex + 1)
		]
		this.setState({exchanges: newArray})
	}

	deleteExchangeFromState = (id) => {
		const updatedExchanges = this.state.exchanges.filter(exchange => exchange.exchange_id !== id)
		this.setState({exchanges: updatedExchanges})
	}

	render() {
/* 		if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    } */

		return (
			<Container className="App">
				<Row>
					<Col> <h3 style={{margin: "20px 0"}}>Exchanges Data</h3> </Col>
				</Row>
				<Row>
					<Col> 
						<ExchangeTable exchanges={this.state.exchanges} updateExchangeState={this.updateExchangeState} deleteExchangeFromState={this.deleteExchangeFromState} /> 
					</Col>
				</Row>
				<Row>
					<Col>
						<ExchangeModal buttonLabel="Tambah Exchange" addExchangeToState={this.addExchangeToState} />
					</Col>
				</Row>
			</Container>
		)
	}
}