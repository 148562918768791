import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
	useHistory 
  } from "react-router-dom";

import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

import Home from './Home';
import Login from './Login';
import Logout from './Logout';
import ReportArbit from './ReportArbit'
import Exchange from './Exchange';
import ExchangeMarket from './ExchangeMarket'
import ExchangeMarketLogs from './ExchangeMarketLogs'
import MarketKonversi from './MarketKonversi'
import Wallets from './Wallets'
import ScrapProfil from './ScrapProfil'
import ProfileSystem from './ProfileSystem'
import Users from './Users'
import MarketsScrap from './MarketsScrap'
import WebLogs from './WebLogs'

export default class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showUserBoard: false,
			showAdminBoard: false,
			showOtherBoard: false,
			currentUser: undefined
		};
	}

	componentDidMount() {
		//this.onLogout()
		let user = this.getCurrentUser()
		//console.log(`componentDidMount user`)
		//console.table( user )

		if (user) {
		  this.setState({
			currentUser: user,
			showUserBoard: (user.roles !== null) ? user.roles.includes("ROLE_USER") : null,
			showAdminBoard: (user.roles !== null) ? user.roles.includes("ROLE_ADMIN") : null,
			showOtherBoard: (user.roles !== null) ? user.roles.includes("ROLE_OTHER") : null,
		  });
		} else {}
	}

	logoutUser = () => {
		let confirmLogout = window.confirm(`Logout dari Aplikasi ?`)
		const { history } = this.props;

		if (confirmLogout) {
		  localStorage.removeItem("user");
		  //this.props.history.push('/login');
		  //history.push("/desired-pathname")
		  window.location.href = "/login"
		}
	}

	getCurrentUser = () => {
		return JSON.parse(localStorage.getItem('user'))
	}

	getUsername = () => {
		let user = this.getCurrentUser()
		return user.username
	}

	render() {
		const { currentUser, showUserBoard, showAdminBoard, showOtherBoard } = this.state;
		console.log(`showAdminBoard : ${showAdminBoard}`)

		return (
			<Router>
				<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Brand href="/">AIO eu</Navbar.Brand>
					
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="mr-auto">
							{currentUser && (
							<NavDropdown title="Reports" id="collasible-nav-dropdown">
								<NavDropdown.Item eventKey={2.1} href="/report-arbit">Arbit</NavDropdown.Item>
							</NavDropdown>
							)}

							{currentUser && (
								<NavDropdown title="Settings" id="collasible-nav-dropdown">
									{showAdminBoard && (
										<>
										<NavDropdown.Item eventKey={3.1} href="/exchange">Exchanges</NavDropdown.Item>
										<NavDropdown.Item eventKey={3.2} href="/exchange-market">Exchange Market Koin/Pair</NavDropdown.Item>
										<NavDropdown.Item eventKey={3.3} href="/search-market-aktif">Search Markets Aktif</NavDropdown.Item>
										<NavDropdown.Item eventKey={3.4} href="/konversi-market-pair">Konversi Market Pair</NavDropdown.Item>
										<NavDropdown.Divider />
										<NavDropdown.Item eventKey={3.5} href="/my-wallets">My Wallets</NavDropdown.Item>
										<NavDropdown.Item eventKey={3.6} href="/scrap-profil">Profile Scrapped</NavDropdown.Item>
										</>
									)}
									
									{currentUser && (
										<NavDropdown.Item eventKey={3.7} href="/profile-system">Profile System</NavDropdown.Item>
									)}

									{showAdminBoard && (
										<NavDropdown.Item eventKey={3.8} href="/user-management">Users Management</NavDropdown.Item>
									)}
								</NavDropdown>
							)}

							{currentUser && (
								<NavDropdown title="Tools" id="collasible-nav-dropdown">
									<NavDropdown.Item eventKey={4.1} href="/markets-scrap">Markets Scrap</NavDropdown.Item>
									{showAdminBoard && (
										<NavDropdown.Item eventKey={4.2} href="/web-logs">Web Access Logs</NavDropdown.Item>
									)}
								</NavDropdown>
							)}
							{currentUser ? (
							<Nav.Link eventKey={6} href="#logout" onClick={()=>this.logoutUser()}> Logout { this.getUsername() } </Nav.Link>
							) : (
							<Nav.Link eventKey={5} href="/login"> Login </Nav.Link>
							)}
						</Nav>

					</Navbar.Collapse>
				</Navbar>

				<Switch>
					<Route exact path="/"> <Home /> </Route>
					<Route path="/login"> <Login /> </Route>
					<Route path="/logout"> <Logout /> </Route>
				</Switch>

				{currentUser && (
				<Switch>					 
					 <Route path="/report-arbit"> <ReportArbit /> </Route>
					 <Route path="/scrap-profil">	<ScrapProfil /> </Route>
					 <Route path="/profile-system">	<ProfileSystem /> </Route>
					 <Route path="/markets-scrap">	<MarketsScrap /> </Route>
				</Switch>
				)}

				{showAdminBoard && (
					<Switch>					 
					<Route path="/exchange"> <Exchange /> </Route>
					<Route path="/exchange-market">	<ExchangeMarket /> </Route>
					<Route path="/search-market-aktif">	<ExchangeMarketLogs /> </Route>
					<Route path="/konversi-market-pair">	<MarketKonversi /> </Route>
					<Route path="/my-wallets">	<Wallets /> </Route>
					<Route path="/user-management">	<Users /> </Route>
					<Route path="/web-logs">	<WebLogs /> </Route>
			   </Switch>
				)}

			</Router>
		)
	}
}

