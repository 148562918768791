import React, {Component} from 'react'
import { Table, Button } from 'reactstrap'
import ExchangeMarketModal from '../Modals/ExchangeMarket.Modal'

class ExchangeMarketTable extends Component {

  render() {
    const markets = this.props.markets.map(market => {
      return (
        <tr key={market.exchange_market_id}>
          <th scope="row">{market.exchange_market_id}</th>
          <td>{market.koin}</td>
          <td>{market.pair}</td>
          <td>{market.lawan}</td>
          <td>{market.aktif}</td>
          <td>
            <div style={{width:"100"}}>
              <ExchangeMarketModal buttonLabel="Lihat" market={market} />
            </div>
          </td>
        </tr>
      )
    })

    return(
      <Table responsive hover striped size="sm" dark>
        <thead>
          <tr>
            <th>ID</th>
            <th> Koin </th>
            <th> Pair </th>
            {/* <th> Coinmarket Cap </th>
            <th> Explorer </th> */}
            <th> Against Exchange </th>
            <th> Aktif </th>
            <th> Aksi </th>
          </tr>
        </thead>
        <tbody>
          {markets}
        </tbody>
      </Table>
    )
  }
}

export default ExchangeMarketTable