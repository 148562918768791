import React, { Component } from 'react'
import { Table, Button } from 'reactstrap';
import ScrapProfilModal from '../Modals/ScrapProfil.Modal'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

export default class  ScrapProfilTable extends Component {

    deleteScrapProfil = (id) => {
		let confirmDelete = window.confirm(`Hapus Profil Scrap (${id}) ?`)
		if ( confirmDelete ) {
			fetch(API_URL + `scrap-profil/delete/${id}`,
				{
					method: 'delete',
					headers: authHeader(),
					//credentials: "include",
					//body: JSON.stringify({ 'market_konversi_id': id })
				}
			)
			.then(response => response.json())
			.then(item => { this.props.deleteScrapProfilFromState(id) })
			.catch(err => console.log(`err: ${err}`))
		}
	}

    render() {
        const scrap_profiles = this.props.scrap_profiles.map(profil => {
            return (
              <tr key={profil.autoid}>
                <th scope="row">{profil.nama_profil}</th>
                <td>{profil.koin}</td>
                <td>{profil.network}</td>
                <td>{profil.convert_to}</td>
                <td>{profil.aktif}</td>
                <td>
                  <div style={{width:"120px"}}>
                    <ScrapProfilModal buttonLabel="Edit" scrap_profil={profil} 
                        updateScrapProfilState={this.props.updateScrapProfilState}/>
                    {' '}
                    <Button color="danger" onClick={() => this.deleteScrapProfil(profil.autoid)}> Del </Button>
                  </div>
                </td>
              </tr>
              )
            })

        return (
            <Table responsive hover striped size="sm" dark>
                <thead>
                    <tr>
                        <th>Nama Profil</th>
                        <th>Koin</th>
                        <th>Network</th>
                        <th>Convert</th>
                        <th>Aktif</th>
                        <th>AKSI</th>
                    </tr>
                </thead>
                <tbody>
                    {scrap_profiles}
                </tbody>
            </Table>
        )
    }
}