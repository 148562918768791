import React from 'react'
import Header from './Header.js'

function App() {
	return (
		<div>
			<Header />
			
		</div>
	)
}

export default App
