import React, { Component, useState, useEffect } from 'react'
import { Container, Row, Col, Input, Button } from 'reactstrap'
import Select from 'react-select'
import ExchangeMarketTable from './Tables/ExchangeMarket.Table'

import authHeader from '../services/auth-header';
import Config from '../Config';
const API_URL = Config.API_URL

class ExchangeId extends Component {
  constructor(props) {
    super(props)
    this.state = {
      exchange_id: '',
      exchanges: [{value: 'aa', label: 'label-aa'}]
    }
  }

  componentDidMount() {
    this.addExchanges()
  }

  handleSelectedChange = (event) => {
    //this.setState({exchange_id: value.value})
    this.setState((state) => ({
      exchange_id: event.value
    }))
    //console.log(`label: ${value.label}, value: ${value.value}, exchange_id: ${this.state.exchange_id}`)
    //this.props.onSelectedChange( this.state.exchange_id )
    this.props.onSelectedChange( event.value, event.label )
  }

  addExchanges = function() {
    this.setState({exchanges: []})
    fetch(API_URL + 'exchange', {
			method: 'GET',
			headers: authHeader()
		})
      .then(res => res.json())
      .then( xchanges => xchanges.forEach( (v1, k1) => {
        this.setState(prevState => ({
          exchanges: [...prevState.exchanges, {'value': v1.exchange_id, 'label': v1.nama_exchange}]
        }))
      }))
      .catch(err => console.log(err))

  } // ./addExchanges

  render() {

    return (
      <div className="ExchangeId">
        <Select
          cacheOptions
          defaultOptions
          value={this.exchange_id}
          /*getOptionLabel={e => e.nama_exchange}
          getOptionValue={e => e.exchange_id}*/
          options={this.state.exchanges}
          /*onInputChange={handleInputChange}*/
          onChange={this.handleSelectedChange}
        />
        {/*<pre>Selected value: "{JSON.stringify(this.state.exchange_id || {}, null, 2)}"</pre>*/}
      </div>
    )

  }

}

export default class ExchangeMarket extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    exchange: '',
    nama_exchange: '',
    markets: [],
    filter: ''
  }

  componentDidMount() {
		this.getMarkets()
  }

  getMarkets = (exchange_id, nama) => {
    //console.log(`getMarkets( ${exchange_id} )`)
    this.setState({exchange: exchange_id})
    this.setState({nama_exchange: nama})
    fetch(API_URL + `exchange-market/${exchange_id}`, {
			method: 'GET',
			headers: authHeader()
		})
      .then( response => response.json())
      .then(markets => { this.setState({markets}) })
      .catch(err => console.log(err))
  }

  handleFilter = event => {
    this.setState({ filter: event.target.value })
  }

  updateExchangeMarket = (exchange_id) => {
    let confirmUpdate = window.confirm(`Update Market pada exchange ${exchange_id} ?`)
    if (confirmUpdate) {
      fetch(API_URL + `exchange-market/${this.state.exchange}/${this.state.nama_exchange}/insert`,
      {
        method: 'get',
        //headers: { 'Content-Type': 'application/json' }
        headers: authHeader(),
      }
    )
    .then( async res => alert("Berhasil Update Exchange-market") )
    .catch(err => console.log(`err: ${err}`))

      // 1. Delete all markets on selected exchange_id
/*       fetch(API_URL + `exchange-market/${exchange_id}/delete`,
        {
          method: 'delete',
          //headers: { 'Content-Type': 'application/json' },
          headers: authHeader(),
          body: JSON.stringify({ exchange_id })
        }
      )
        .then(response => {
          // 2. Then Insert all market from CoinmarketCap
          fetch(API_URL + `exchange-market/${this.state.exchange}/${this.state.nama_exchange}/insert`,
            {
              method: 'get',
              //headers: { 'Content-Type': 'application/json' }
              headers: authHeader(),
            }
          )
          .then( async res => alert("Berhasil Update Exchange-market") )

        })
        .catch(err => console.log(`err: ${err}`)) */
    }
  }

  deleteExchangeMarket = (exchange_id) => {
    let confirmDelete = window.confirm(`Hapus Semua Market pada Exchange ${exchange_id} ?`)
    if ( confirmDelete ) {
      fetch(API_URL + `exchange-market/${exchange_id}/delete`,
				{
					method: 'delete',
					//headers: { 'Content-Type': 'application/json' },
          headers: authHeader(),
					body: JSON.stringify({ exchange_id })
				}
			)
				.then(response => alert("Berhasil Hapus") )
				.catch(err => console.log(`err: ${err}`))
    }
  }

  updateMarketCMC = () => {
    let confirmUpdate = window.confirm("Update Market Digital Asset menggunakan CoinmarketCap ?")
    if (confirmUpdate) {
      fetch(API_URL + `exchange-market-cmc/delete`,
        {
          method: 'get',
          //headers: { 'Content-Type': 'application/json' }
          headers: authHeader(),
        }
      )
        .then(
          response => {
            response.json();
            fetch(API_URL + `exchange-market-cmc/insert`,
              {
                method: 'get',
                //headers: { 'Content-Type': 'application/json' }
                headers: authHeader(),
              }
            )
            .then(res => alert("Berhasil Update CMC") )
          }
        )
        .catch(err => console.log(`err: ${err}`))
    }
  }

  updateMarketExplorer = (exchange_id) => {
    let confirm = window.confirm(`Update url Explorer Semua Market ${exchange_id} ?`)
    if ( confirm ) {
      fetch(API_URL + `exchange-market/update-market-explorer/${exchange_id}`,
				{
					method: 'GET',
          headers: authHeader()
				}
			)
			.then(response => alert(`Berhasil Update url Explorer Semua Market ${exchange_id}`) )
			.catch(err => console.log(`err: ${err}`))
    }
  }

  updateMarketAktifFromOpposite = (exchange_id) => {
    let confirm = window.confirm(`Update Market Aktif untuk ${exchange_id} ?`)
    if ( confirm ) {
      fetch(API_URL + `exchange-market/update-market-aktif-from-opposite/${exchange_id}`,
				{
					method: 'GET',
          headers: authHeader()
				}
			)
			.then(response => alert(`Berhasil Update Market Aktif Opposite dari ${exchange_id}`) )
			.catch(err => console.log(`err: ${err}`))
    }
  }

  updateSettingBinanceMarket = (exchange_id) => {
    const username = this.getCurrentUser()
    let confirm = window.confirm(`Update Setting Binance Market, untuk mendapatkan koin presisi waktu beli Koin ?`)
    if ( confirm ) {
      fetch(API_URL + `exchange-market/update-exchange-info/${username}/${exchange_id}`,
				{
					method: 'GET',
          headers: authHeader()
				}
			)
			.then(response => alert(`Berhasil Update Setting Binance Market dari ${exchange_id}`) )
			.catch(err => console.log(`err: ${err}`))
    }
  }

  getCurrentUser = () => {
		let user = JSON.parse(localStorage.getItem('user'))
    return user.username
	}

  render() {
    const {exchange, markets, filter} = this.state
    const lowercasedFilter = filter.toLowerCase()
    const filterMarkets = markets.filter(item => { 
      return item.koin.toLowerCase().indexOf(lowercasedFilter)!==-1
    });

    return(
      <Container className="App">
        <Row>
          <Col md={2}>
            <ExchangeId onSelectedChange={this.getMarkets} />
          </Col>
          <Col md={2}>
            <Input type="text" onChange={this.handleFilter} placeholder="Filter Koin.." />
          </Col>
          <Col md={8}>
            <Button color="primary" onClick={() => this.updateExchangeMarket(this.state.exchange)}>Upd.Market</Button> {' '}
            <Button color="warning" onClick={() => this.updateMarketExplorer(this.state.exchange)}>Upd.Explor</Button> {' '}
            <Button color="success" onClick={() => this.updateMarketAktifFromOpposite(this.state.exchange)}>Upd.Aktif Market</Button> {' '}
            {this.state.exchange==='BINA' && (
              <Button color="info" onClick={() => this.updateSettingBinanceMarket(this.state.exchange)}>Upd.Exchange Info</Button>
            )} { ' ' }
            {this.state.exchange==='BINA_BSC' && (
              <Button color="info" onClick={() => this.updateSettingBinanceMarket(this.state.exchange)}>Upd.Exchange Info</Button>
            )} { ' ' }
            <Button color="primary" onClick={() => this.updateMarketCMC()}>Upd.CMC</Button> {' '}
            <Button color="danger" onClick={() => this.deleteExchangeMarket(this.state.exchange)}>Del.Market</Button> {' '}
          </Col>
        </Row>

        <Row>
          <Col>
            <ExchangeMarketTable markets={filterMarkets || []} />
          </Col>
        </Row>
        <Row>
          <Col>
          1. Pilih Exchange Dahulu <br />
          2. *Upd Market, update pasangan token dari CoinmarketCap <br/>
          3. Del Market, menghapus semua markets dari exchange yang dipilih <br/>
          4. Upd CMC, memperbaharui data dari Coinmarketcap(nama token, url: cmc,trade, explore) <br/>
          5. *Upd Explorer, memperbaharui kolom url-explorer tb exchange-market <br/>
          6. *Upd Aktif Market, memperbaharui market yang aktif berdasarkan/dilihat dari lawan marketnya <br/>
          *NB: Setiap memperbaharui koin/pair, url-explorer, koin aktif dari markets, jangan lupa untuk mengirimkan perubahan markets ke <b>External Server</b> (jika proses SCRAP/SIMULASI dari external-server). 
          Menu ini bisa diakses dari <b>Settings - Profile System (tab. Exchange Config)</b>
          </Col>
        </Row>
      </Container>
    )
  }
}