import React, { Component } from 'react'
import { Row, Col, Badge, Button } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class MarketsScrapCountUp extends Component {
	constructor() {
		super();
		this.state = {
			minutes_bid: 0,
			seconds_bid: 0,
			scrapMinutes_bid: 0,
			scrapSeconds_bid: 0,
			exchange: [],
			server_ext_url: '',

			minutes_ask: 0,
			seconds_ask: 0,
			scrapMinutes_ask: 0,
			scrapSeconds_ask: 0,
		};
	}

	componentDidMount() {
		this.setState({exchange: this.props.exchange})
	}

	componentDidUpdate(prevProps, prevState) {
		if ( this.props.exchange.is_scraping !== prevProps.exchange.is_scraping ) {
			this.setState({
				exchange: this.props.exchange}, 
				()=> { 
					if ( this.state.exchange.is_scraping==1 ) {
						this.startScraping()
					}
				})
		} else if ( this.props.exchange.is_scraping_dexag_ask !== prevProps.exchange.is_scraping_dexag_ask ) {
			this.setState({
				exchange: this.props.exchange}, 
				()=> { 
					if ( this.state.exchange.is_scraping_dexag_ask==1 ) {
						this.startScraping_dexag_ask()
					}
				})
		}
	}

	convertToSeconds = (minutes, seconds) => {
		//console.log(`convertToSeconds: ${ seconds + minutes * 60 }`)
		return seconds + minutes * 60;
	};

	startTimer_bid = () => {
		this.timer_bid = setInterval(this.countUp_bid, 1000);
	};

	startScraping = async () => {
		const exchange_id = this.state.exchange.exchange_id
		const user = JSON.parse(localStorage.getItem('user'));
		const username = user.username
		const convert_to = this.props.getConvertTo()
		const network = this.props.getNetwork()
		const scrap_profil = this.props.getScrapProfil()
		let exchange_cex=''

		if ( network==="ETH" )
			exchange_cex = this.props.getDexag_eth_ask_exchange()
		else if ( network==="BSC" )
			exchange_cex = this.props.getDexag_bsc_ask_exchange()

		const url = API_URL + `ext/${username}/modal/${this.props.parentModal}/${convert_to}/${network}/${scrap_profil}/${exchange_id}?side=BID&exchange_cex=${exchange_cex}`
		this.startTimer_bid()

		console.log(`url scraping BID ${url}`)
		
		await fetch(API_URL + `ext/${username}/modal/${this.props.parentModal}/${convert_to}/${network}/${scrap_profil}/${exchange_id}?side=BID&exchange_cex=${exchange_cex}`,
		{
			method: 'get', 
			headers: authHeader(),
		})
		.then(async response => { 
			await this.props.setScraping(this.state.exchange.exchange_id, 0, "ALL")
			const min_bid = this.state.minutes_bid, sec_bid = this.state.seconds_bid
			this.setState({scrapMinutes_bid: min_bid, scrapSeconds_bid: sec_bid})
			this.stopTimer_bid()
			this.resetTimer_bid()

			const eth_ask_exchange = this.props.getDexag_eth_ask_exchange().trim()
			const eth_bsc_exchange = this.props.getDexag_bsc_ask_exchange().trim()
			const getNetwork = this.props.getNetwork().trim()

			if ( getNetwork==="ETH" ) {
				if ( eth_ask_exchange===this.state.exchange.exchange_id ) {
					this.props.exchanges.map(exchange => {
						console.log(`ETH_ASK counter is over, trigger scraping for exchange: ${exchange.exchange_id}`)
						this.props.setScraping(exchange.exchange_id, 1, "DEX_AG")
					});
				}
			} else if ( getNetwork==="BSC" ) {
				if ( eth_bsc_exchange===this.state.exchange.exchange_id ) {
					this.props.exchanges.map(exchange => {
						console.log(`BSC_ASK counter is over, trigger scraping for exchange: ${exchange.exchange_id}`)
						this.props.setScraping(exchange.exchange_id, 1, "DEX_AG")
					});
				}
			}

			const res = await response.json()
			//console.log(`res server_ext_url ${res[0].server_ext_url}`)
			this.setState({'server_ext_url': res[0].server_ext_url})
		})
		.catch(err => console.log(`error fetch orderbooks ${exchange_id} : ${err}`))
		console.log(`beginning orderbooks scrap BID ${exchange_id}`)
	}

	countUp_bid = () => {
		const { minutes_bid, seconds_bid } = this.state;
		let c_seconds = this.convertToSeconds(minutes_bid, seconds_bid);

		//if (this.state.isScraping) {
			// seconds change
			//seconds ? this.setState({ seconds: seconds + 1 }) : this.setState({ seconds: 59 });
			this.setState({ seconds_bid: seconds_bid + 1 })
			// minutes change
			if (c_seconds % 60 === 0 && minutes_bid) {
				this.setState({ minutes_bid: minutes_bid + 1 });
			}

		//}
	};

	stopTimer_bid = () => {
		clearInterval(this.timer_bid);
	};

	resetTimer_bid = () => {
		this.setState({
			minutes_bid: 0,
			seconds_bid: 0
		});
	};

	startTimer_ask = () => {
		this.timer_ask = setInterval(this.countUp_ask, 1000);
	};

	startScraping_dexag_ask = async () => {
		if ( this.state.exchange.jenis !== "DEX-AG" ) return false;

		const exchange_id = this.state.exchange.exchange_id
		const user = JSON.parse(localStorage.getItem('user'));
		const username = user.username
		const convert_to = this.props.getConvertTo()
		const network = this.props.getNetwork()
		const scrap_profil = this.props.getScrapProfil()
		let exchange_cex = ''
		
		if ( network==="ETH" )
			exchange_cex = this.props.getDexag_eth_ask_exchange()
		else if ( network==="BSC" )
			exchange_cex = this.props.getDexag_bsc_ask_exchange()

		const url = API_URL + `ext/${username}/modal/${this.props.parentModal}/${convert_to}/${network}/${scrap_profil}/${exchange_id}?side=ASK&exchange_cex=${exchange_cex}`
		this.startTimer_ask()

		console.log(`url scraping ASK ${url}`)
		
		await fetch(API_URL + `ext/${username}/modal/${this.props.parentModal}/${convert_to}/${network}/${scrap_profil}/${exchange_id}?side=ASK&exchange_cex=${exchange_cex}`,
		{
			method: 'get', 
			//headers: { 'Content-Type': 'application/json' }
			headers: authHeader(),
		})
		.then(async response => { 
			await this.props.setScraping(this.state.exchange.exchange_id, 0, "DEX_AG")
			const min_ask = this.state.minutes_ask, sec_ask = this.state.seconds_ask
			this.setState({scrapMinutes_ask: min_ask, scrapSeconds_ask: sec_ask})
			this.stopTimer_ask()
			this.resetTimer_ask()

			const res = await response.json()
			this.setState({'server_ext_url': res[0].server_ext_url})
		})
		.catch(err => console.log(`error fetch orderbooks ${exchange_id} : ${err}`))
		console.log(`beginning orderbooks scrap ASK ${exchange_id}`)
	}

	countUp_ask = () => {
		const { minutes_ask, seconds_ask } = this.state;
		let c_seconds = this.convertToSeconds(minutes_ask, seconds_ask );

		//if (this.state.isScraping) {
			// seconds change
			//seconds ? this.setState({ seconds: seconds + 1 }) : this.setState({ seconds: 59 });
			this.setState({ seconds_ask: seconds_ask + 1 })
			// minutes change
			if (c_seconds % 60 === 0 && minutes_ask) {
				this.setState({ minutes_ask: minutes_ask + 1 });
			}

		//}
	};

	stopTimer_ask = () => {
		clearInterval(this.timer_ask);
	};

	resetTimer_ask = () => {
		this.setState({
			minutes_ask: 0,
			seconds_ask: 0
		});
	};

	render() {
		const { minutes_bid, seconds_bid } = this.state;
		const { minutes_ask, seconds_ask } = this.state;
	
		return (
			<>
				<Row>
					<Col md={12} style={{ fontSize: 13, fontWeight: 'bold'}}>
{/* 						
						<Button color="success" onClick={this.startTimer} size="sm">start</Button>
						<Button onClick={this.stopTimer} size="sm">stop</Button>
						<Button color="success" onClick={this.resetTimer} size="sm">reset</Button>
						<Button onClick={() => this.props.setScrapStatus(this.props.exchange, 1)} size="sm">scrap</Button>
						<Button color="success" onClick={() => this.props.setScrapStatus(this.props.exchange, 0)} size="sm">unscrap</Button>
 */}
						{" "}
			  			{minutes_bid} : {seconds_bid} {" "}
						{" "}
						<a href={`${this.state.server_ext_url}`} target={'_blank'}>
            				<Badge color="primary" style={{ cursor: "pointer" }}> BID </Badge>
            			</a> { ' ' } 
						{ this.state.scrapMinutes_bid} :  { this.state.scrapSeconds_bid} { ' | ' }
			  			{minutes_ask} : {seconds_ask} {" "}
						 
						<a href={`${this.state.server_ext_url}`} target={'_blank'}>
            				<Badge color="primary" style={{ cursor: "pointer" }}> ASK </Badge>
            			</a> {" "}
						{ this.state.scrapMinutes_ask} :  { this.state.scrapSeconds_ask} { ' ' }
					</Col>
				</Row>
			</>
		);
	  }

}

export default MarketsScrapCountUp