import React, { Component } from 'react'
import { Container, Row, Col, Input } from 'reactstrap'
import Select from 'react-select'
import WalletTable from './Tables/Wallet.Table'
import WalletModal from './Modals/Wallet.Modal.js'

import authHeader from '../services/auth-header';
import Config from '../Config';
const API_URL = Config.API_URL

class ExchangeId extends Component {
    constructor(props) {
        super(props)
        this.state = {
            exchange_id: '',
            exchanges: [{ value: 'aa', label: 'label-aa' }]
        }
    }

    componentDidMount() {
        this.addExchanges()
    }

    handleSelectedChange = (event) => {
        //this.setState({exchange_id: value.value})
        this.setState((state) => ({
            exchange_id: event.value
        }))
        //console.log(`label: ${value.label}, value: ${value.value}, exchange_id: ${this.state.exchange_id}`)
        //this.props.onSelectedChange( this.state.exchange_id )
        this.props.onSelectedChange(event.value, event.label)
    }

    addExchanges = function () {
        this.setState({ exchanges: [] })
        fetch(API_URL + 'exchange', {
			method: 'GET',
			headers: authHeader()
		})
            .then(res => res.json())
            .then(xchanges => xchanges.forEach((v1, k1) => {
                this.setState(prevState => ({
                    //exchanges: {'value': v1.exchange_id, 'label': v1.nama_exchange}
                    exchanges: [...prevState.exchanges, { 'value': v1.exchange_id, 'label': v1.nama_exchange }]
                }))
            }))
            .catch(err => console.log(err))


    } // ./addExchanges

    render() {

        return (
            <div className="ExchangeId">
                <Select
                    cacheOptions
                    defaultOptions
                    value={this.exchange_id}
                    /*getOptionLabel={e => e.nama_exchange}
                    getOptionValue={e => e.exchange_id}*/
                    options={this.state.exchanges}
                    /*onInputChange={handleInputChange}*/
                    onChange={this.handleSelectedChange}
                />
            </div>
        )

    }

}

export default class Wallets extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        exchange: '',
        nama_exchange: '',
        wallets: [],
        filter: ''
    }

    componentDidMount() {
        this.getWallets()
    }

    getWallets = (exchange_id, nama) => {
        this.setState({ exchange: exchange_id, nama_exchange: nama })
        const user = JSON.parse(localStorage.getItem('user'))
        const username = user.username

        fetch(API_URL + `wallets/${username}/${exchange_id}`, {
			method: 'GET',
			headers: authHeader()
		})
            .then(response => response.json())
            .then(wallets => { this.setState({ wallets }) })
            .catch(err => console.log(err))
    }

    handleFilter = event => {
        this.setState({ filter: event.target.value })
    }

    updateWallet = (wallet) => {
        const walletIndex = this.state.wallets.findIndex(data => data.wallet_id === wallet.wallet_id)
        const newArray = [
            // desctructure all wallets from beginning to the indexed wallet
            ...this.state.wallets.slice(0, walletIndex),
            // add the updated wallet to the array
            wallet,
            // add the rest of the wallet to the array from the index after the replaced wallet
            ...this.state.wallets.slice(walletIndex + 1)
        ]

        this.setState({ wallets: newArray })
    }

    deleteWalletFromState = (id) => {
		const updatedWallet = this.state.wallets.filter(wallet => wallet.wallet_id !== id)
		this.setState({wallets: updatedWallet})
    }
    
    render() {
        return(
			<Container className="App">
				<Row>
					<Col> <h3 style={{margin: "20px 0"}}> Wallets Data</h3> </Col>
				</Row>
                <Row>
                    <Col md={3}>
                        <ExchangeId onSelectedChange={this.getWallets} />
                    </Col>
                    <Col md={3}>
                        <Input type="text" onChange={this.handleFilter} placeholder="Filter Symbol.." />
                    </Col>
                </Row>
				<Row>
					<Col>
						<WalletTable wallets={this.state.wallets} 
							updateWalletState={this.updateWalletState}
							deleteWalletFromState={this.deleteWalletFromState}
							getWallets={this.getWallets} />
					</Col>
				</Row>
				<Row>
					<Col>
						<WalletModal buttonLabel="Tambah Wallet" exchange_id={this.state.exchange} addWalletToState={this.addWalletToState} />
						<br/>
					</Col>
				</Row>
			</Container>
        )
    }
}