import React, { Component } from 'react'
import { Table, Button } from 'reactstrap';
import LogAioModal from '../Modals/LogAioTrade.Modal'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

export default class LogAIOTrade extends Component {

    deleteLogAio = (id) => {
		let confirmDelete = window.confirm(`Hapus Data Log AIO (${id}) ?`)
        if (confirmDelete) {
            fetch(API_URL + `log-aio/delete`,
                {
                    method: 'DELETE',
                    headers: authHeader(),
                    body: JSON.stringify({ id: id })
                }
            )
            .then(response => response.json())
            .then(item => { this.props.deleteLogAIOFromState(id) })
            .catch(err => console.log(`err: ${err}`))
        }
	}

    render() {

        const logs = this.props.logs_aio.map(log => {
          return (
            <tr key={log.autoid}>
                <th scope="row">{log.autoid}</th>
                <td>{log.waktu}</td>
                <td>{log.username}</td>
                <td>{log.info}</td>
                <td>
                    <div style={{ width: "120px" }}>
                        <LogAioModal buttonLabel="Lihat" log_aio={log} />
                        {' '}
                        <Button color="danger" onClick={() => this.deleteLogAio(log.autoid)}> Del </Button>
                    </div>
                </td>
            </tr>
            )
          })
    
        return (
          <Table responsive hover striped size="sm" dark>
            <thead>
              <tr>
                <th>ID</th>
                <th>Waktu</th>
                <th>Username</th>
                <th>Info</th>
                <th>AKSI</th>
              </tr>
            </thead>
            <tbody>
              {logs}
            </tbody>
          </Table>
        )
      }
}