import React from 'react'
import { Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

export default class UsersExchangeForm extends React.Component {
    constructor(props) {
		super(props)
		this.api_keyInput = React.createRef();
		this.api_secretInput = React.createRef();
		this.api_addon1Input = React.createRef();
	}

    state = {
        users_exchange_id: null,
        waktu: null,
        username: null,
        exchange_id: null,
        api_key: null,
        api_secret: null,
        api_addon1: null,
        aktif: null
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeUsersExchangeAktif = (event) => {
        this.setState(initialState => {
            return { aktif: !initialState.aktif }
        });
    }

    componentDidMount() {
		// if user_exchange exist , populate the state with proper data 
		if (this.props.user_exchange) {
			const { users_exchange_id, waktu, username, exchange_id, api_key, api_secret, api_addon1, aktif } = this.props.user_exchange
			this.setState({users_exchange_id, waktu, username, exchange_id, api_key, api_secret, api_addon1, aktif})
            /* console.table( this.props.user_exchange )
            var gfg = this.encrypt("yanu-api-key-inserted")
            console.log(`encerypted "yanu-api-key-inserted" : `)
            console.table( gfg )
            console.log(`decrypt : ${this.decrypt(gfg)}`)
            console.log(`decrypted user-exchange api-key: ${ this.decrypt( this.props.user_exchange.api_key ) }`) */
		}
    }
    
    submitUsersExchangeFormAdd = (e) => {
        e.preventDefault()

        const user = JSON.parse(localStorage.getItem('user'));
		const username = user.username

        fetch(API_URL + 'users-exchange/insert',
            {
                method: 'post',
                //headers: { 'Content-Type': 'application/json' },
                headers: authHeader(),
                body: JSON.stringify({
                    username: username,
                    exchange_id: this.state.exchange_id,
                    api_key: this.encrypt(this.state.api_key),
                    api_secret: this.encrypt(this.state.api_secret),
                    api_addon1: this.encrypt(this.state.api_addon1),
                    aktif: this.state.aktif
                })
            }
        )
            .then(response => response.json())
            .then(user_exchange => {
                //if (Array.isArray(user_exchange)) {
                this.props.addUsersExchangeToState(this.state)
                this.props.toggle()
                //} else { console.log(`Failure add user`) }
            })
            .catch(err => console.log(`error add user-exchange: ${err}`))
    }

    submitUsersExchangeFormEdit = e => {
        e.preventDefault()
        fetch(API_URL + `users-exchange/update`, {
            method: 'put',
            //headers: {'Content-Type': 'application/json'},
            headers: authHeader(),
            body: JSON.stringify({
                users_exchange_id: this.state.users_exchange_id,
                username: this.state.username,
                exchange_id: this.state.exchange_id,
                api_key: this.encrypt( this.api_keyInput.current.value ),
                api_secret: this.encrypt( this.api_secretInput.current.value ),
                api_addon1: this.encrypt( this.api_addon1Input.current.value ),
                aktif: this.state.aktif
            })
        })
            .then(response => response.json())
            .then(user_exchange => {
                //if (Array.isArray(user_exchange)) {
                this.props.updateUsersExchangeState(user_exchange)
                this.props.toggle()
                //} else { console.log("failure submitUserFormEdit") }
            })
            .catch(err => console.log(err))
    }

    encrypt = (text) => {
        const crypto = require('crypto');

        //const ENCRYPTION_KEY = process.env.ENCRYPTION_KEY; // Must be 256 bits (32 characters)
        const algorithm = 'aes-256-ctr'
        const ENCRYPTION_KEY = "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3"
        const IV_LENGTH = 16 // For AES, this is always 16

        try {
            let iv = crypto.randomBytes(IV_LENGTH)
            let cipher = crypto.createCipheriv(algorithm, Buffer.from(ENCRYPTION_KEY), iv)
            let encrypted = cipher.update(text)
            encrypted = Buffer.concat([encrypted, cipher.final()])
    
            return iv.toString('hex') + ':' + encrypted.toString('hex')
        } catch (err) {
            console.log(`err encrypt ${err}`)
            return text
        }
    }

    decrypt = (text) => {
        const crypto = require('crypto')
        const algorithm = 'aes-256-ctr'
        //const ENCRYPTION_KEY = process.env.ENCRYPTION_KEY; // Must be 256 bits (32 characters)
        const ENCRYPTION_KEY = "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3"
        const IV_LENGTH = 16 // For AES, this is always 16

        try {
            let textParts = text.split(':')
            let iv = Buffer.from(textParts.shift(), 'hex')
            let encryptedText = Buffer.from(textParts.join(':'), 'hex')
            let decipher = crypto.createDecipheriv(algorithm, Buffer.from(ENCRYPTION_KEY), iv)
            let decrypted = decipher.update(encryptedText)
    
            decrypted = Buffer.concat([decrypted, decipher.final()])
    
            return decrypted.toString()
        } catch (err) {
            console.log(`err decrpty: ${err}`)
            return text
        }
    }

    render() { 
        const api_key = this.decrypt( this.state.api_key )
        const api_secret = this.decrypt( this.state.api_secret )
        const api_addon1 = this.decrypt( this.state.api_addon1 )

        return (
            <Col md={12}>
                <Form onSubmit={this.props.user_exchange ? this.submitUsersExchangeFormEdit : this.submitUsersExchangeFormAdd}>
                    <FormGroup row>
                        <Label for="exchange_id" sm={3}> Exchange </Label>
                        <Col sm={9}>
                        <Input type="text" name="users_exchange_id" onChange={this.handleChange}
                            value={this.state.users_exchange_id === null ? '' : this.state.users_exchange_id}
                            hidden />
                        <Input type="text" name="exchange_id" onChange={this.handleChange}
                            value={this.state.exchange_id === null ? '' : this.state.exchange_id}
                            disabled={(this.props.user_exchange) ? "disabled" : ""} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="api_key" sm={3}>API-Key</Label>
                        <Col sm={9}>
                            <Input type="text" name="api_key" innerRef={this.api_keyInput} 
                                onChange={this.handleChange} value={this.state.api_key === null ? '' : api_key} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="api_secret" sm={3}>API-Secret</Label>
                        <Col sm={9}>
                            <Input type="text" name="api_secret" innerRef={this.api_secretInput}
                                onChange={this.handleChange} value={this.state.api_secret === null ? '' : api_secret} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="api_addon1" sm={4}>API-Addon1</Label>
                        <Col sm={8}>
                            <Input type="text" name="api_addon1" innerRef={this.api_addon1Input}
                                onChange={this.handleChange} value={this.state.api_addon1 === null ? '' : api_addon1} />
                        </Col>
                    </FormGroup>
                    <FormGroup className="text-center text-md-right" row>
                        <Col md={9}>
                            <FormGroup check>
                                <Input type="checkbox" name="aktif" id="aktif"
                                    onChange={this.handleChangeUsersExchangeAktif} checked={this.state.aktif === null ? true : this.state.aktif} />
                                <Label for="aktif" check>Aktif</Label>
                            </FormGroup>
                        </Col>
                        <Col md={3}> <Button>Simpan</Button> </Col>
                    </FormGroup>
                </Form>
            </Col>
        )
    }

}