import React, { Component } from 'react'
import { Table, Button } from 'reactstrap';
import ExchangeModal from '../Modals/Exchange.Modal'

class ExchangeTable extends Component {

  render() {

    const exchanges = this.props.exchanges.map(exchange => {
      return (
        <tr key={exchange.exchange_id}>
          <th scope="row">{exchange.exchange_id}</th>
          <td>{exchange.nama_exchange}</td>
          <td>{exchange.url}</td>
          <td>{exchange.api_endpoint}</td>
          <td>{exchange.alias}</td>
          <td>{exchange.aktif}</td>
          <td>
            <div style={{width:"120px"}}>
              <ExchangeModal buttonLabel="Edit" exchange={exchange} updateExchangeState={this.props.updateExchangeState}/>
              {' '}
            </div>
          </td>
        </tr>
        )
      })

    return (
      <Table responsive hover striped size="sm" dark>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nama Exchange</th>
            <th>URL</th>
            <th>API Endpoint</th>
            <th>Alias</th>
            <th>Aktif</th>
            <th>AKSI</th>
          </tr>
        </thead>
        <tbody>
          {exchanges}
        </tbody>
      </Table>
    )
  }
}

export default ExchangeTable