import React from 'react'
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

export default class WalletForm extends React.Component {
    state = {
        wallet_id:0,
        exchange_id:null,
        network:null,
        symbol:null, 
        symbol_presisi:null, 
        nama_symbol:null,
        depo_address:null,
        chain:null,
        memo:null,
        tag:null,
        nama_wallet:null,
        username:null,
        waktu:null
     }

     handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
     }

    submitWalletFormAdd = (e) => {
        e.preventDefault()

        const username = this.getCurrentUser()

        fetch(API_URL + 'wallets/insert',
            {
                method: 'post',
                //headers: { 'Content-Type': 'application/json' },
                headers: authHeader(),
                body: JSON.stringify({
                    exchange_id: this.state.exchange_id,
                    network: this.state.network,
                    symbol: this.state.symbol,
                    symbol_presisi: this.state.symbol_presisi,
                    nama_symbol: this.state.nama_symbol,
                    depo_address: this.state.depo_address,
                    chain: this.state.chain,
                    memo: this.state.memo,
                    tag: this.state.tag,
                    nama_wallet: this.state.nama_wallet,
                    username: username,
                    aktif: this.state.aktif,
                })
            }
        )
        .then(response => response.json())
        .then(wallet => {
            console.table(wallet)
            //console.log(`id: ${wallet.id}, exchange_id: ${market_konversi.exchange_id}, convert_to: ${market_konversi.convert_to}, koin: ${market_konversi.koin}`)
            this.setState({ wallet_id: wallet.id })
            //if (Array.isArray(market_konversi)) {
            this.props.addWalletToState(this.state)
            this.props.toggle()
            //} else { console.log(`Failure add market-konversi`) }
        })
        .catch(err => console.log(`error add wallet: ${err}`))
    }

    submitWalletFormEdit = (e) => {
        e.preventDefault()

        const username = this.getCurrentUser()
        
        fetch(API_URL + `wallets/update`,
            {
                method: 'put',
                //headers: { 'Content-Type': 'application/json' },
                headers: authHeader(),
                body: JSON.stringify({
                    wallet_id: this.state.wallet_id,
                    exchange_id: this.state.exchange_id,
                    network: this.state.network,
                    symbol: this.state.symbol,
                    symbol_presisi: this.state.symbol_presisi,
                    nama_symbol: this.state.nama_symbol,
                    depo_address: this.state.depo_address,
                    chain: this.state.chain,
                    memo: this.state.memo,
                    tag: this.state.tag,
                    nama_wallet: this.state.nama_wallet,
                    username: username,
                    aktif: this.state.aktif,
                })
            }
        )
        .then(response => response.json())
        .then(wallet => {
            console.log(`wallet updated: `)
            console.table(wallet)
            this.props.updateWalletState(wallet)
            this.props.toggle()
        })
        .catch(err => console.log(`Error Update wallet: ${err}`))
    }

    componentDidMount() {
		// if wallet exist, populate the state with proper data
		if (this.props.wallet) {
			const {wallet_id, exchange_id, network, symbol, symbol_presisi, nama_symbol, depo_address, chain, memo, tag, nama_wallet, username, waktu, aktif} = this.props.wallet
			this.setState({wallet_id, exchange_id, network, symbol, symbol_presisi, nama_symbol, depo_address, chain, memo, tag, nama_wallet, username, waktu, aktif})
		} else {
            this.setState({exchange_id: this.props.exchange_id})
        }
    }

    getCurrentUser = () => {
		const user = JSON.parse(localStorage.getItem('user'))
        return user.username
	}

    onChangeWalletAktif = (event) => {
		this.setState(initialState => {
			return { aktif: !initialState.aktif }
		});
	}
    
    render() {

        return(
            <Col md={12}>
                <Form onSubmit={this.props.wallet ? this.submitWalletFormEdit : this.submitWalletFormAdd}>
                    <FormGroup row>
                        <Col sm={6}>
                            <Label sm={2}> Exch </Label>
                            <Label sm={4}> <b>{this.state.exchange_id === null ? this.props.exchange_id : this.state.exchange_id}</b> </Label>
                        </Col>
                        <Col sm={2}>
                            <Label sm={2}> NetId </Label>
                        </Col>
                        <Col sm={4}>
                            <Input type="text" name="network" onChange={this.handleChange}
                                value={this.state.network === null ? '' : this.state.network} />
                        </Col>
                    </FormGroup>
                    
                    <FormGroup row>
                        <Label for="symbol" sm={2}> Simbol </Label>
                        <Col sm={4}>
                            <Input type="text" name="symbol" onChange={this.handleChange}
                                value={this.state.symbol === null ? '' : this.state.symbol} />
                        </Col>
                        <Col sm={6}>
                            <Input type="text" name="nama_symbol" onChange={this.handleChange}
                                value={this.state.nama_symbol === null ? '' : this.state.nama_symbol} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="symbol_presisi" sm={3}>Presisi Des</Label>
                        <Col sm={2}>
                            <Input type="text" name="symbol_presisi" onChange={this.handleChange}
                                value={this.state.symbol_presisi === null ? '' : this.state.symbol_presisi} />
                        </Col>

                        <Label for="chain" sm={2}>Chain</Label>
                        <Col sm={4}>
                            <Input type="text" name="chain" onChange={this.handleChange}
                                value={this.state.chain === null ? '' : this.state.chain} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="memo" sm={2}>Memo</Label>
                        <Col sm={4}>
                            <Input type="text" name="memo" onChange={this.handleChange}
                                value={this.state.memo === null ? '' : this.state.memo} />
                        </Col>
                        <Label for="tag" sm={2}>Tag</Label>
                        <Col sm={4}>
                            <Input type="text" name="tag" onChange={this.handleChange}
                                value={this.state.tag === null ? '' : this.state.tag} />

                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="nama_wallet" sm={3}> NamaWallet </Label>
                        <Col sm={9}>
                            <Input type="text" name="nama_wallet" onChange={this.handleChange}
                                value={this.state.nama_wallet === null ? "" : this.state.nama_wallet} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="depo_address" sm={3}> DepoAddr </Label>
                        <Col sm={9}>
                            <Input type="text" name="depo_address" onChange={this.handleChange}
                                value={this.state.depo_address === null ? "" : this.state.depo_address} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <FormGroup check>
                            <Input type="checkbox" name="aktif" id="aktif"
                                onChange={this.onChangeWalletAktif} checked={this.state.aktif === null ? true : this.state.aktif} />
                            <Label for="aktif" check>Aktif</Label>
                        </FormGroup>
                        <Col md={2}>{' '}</Col>
                        <Col md={6}> <Button>Simpan</Button> </Col>
                    </FormGroup>
				</Form>
            </Col>
        )   
    }

}