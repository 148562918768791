import React from 'react'
import { Container, Row, Col, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import authHeader from '../services/auth-header';
import Config from '../Config';
const API_URL = Config.API_URL

export default class ProfileSettings extends React.Component {
	constructor(props) {
    super(props)
    this.server_ext1_urlInput = React.createRef();
    this.server_ext1Input = React.createRef();
    this.server_ext2_urlInput = React.createRef();
    this.server_ext2Input = React.createRef();
    this.server_ext3_urlInput = React.createRef();
    this.server_ext3Input = React.createRef();
  }

  state = {
    settings: [],
    server_ext1: false,
    server_ext1_url: '',
    server_ext2: false,
    server_ext2_url: '',
    server_ext3: false,
    server_ext3_url: '',
    ext_server_options: [],
  }

  componentDidMount() {
    this.getSettings()
  }

  getSettings = () => {
    const user = JSON.parse(localStorage.getItem('user'));
		const username = user.username

		fetch(API_URL + `profile-system/settings/${username}`, {
			method: 'GET',
			headers: authHeader()
		})
      .then(response => response.json() )
      .then(settings => { 
        this.setState({settings}) 
        settings.map(item => {
          // 1. *Eksternal Server-1 Aktif Status
          if ( item.id === "server_ext1" ) {
            //console.log(`server_ext1: ${item.val}`)
            if ( item.val == 0 ) {
              this.server_ext1Input.current.checked = false;
              this.state.server_ext1 = false
              //console.log(`server_ext1: FALSE`)
            } else {
              this.server_ext1Input.current.checked = true;
              this.state.server_ext1 = true
              //console.log(`server_ext1: TRUE`)
            }
          // 2. Eksternal Server-1 path URL
          } else if ( item.id === "server_ext1_url" ) {
            //console.log(`server_ext1_url: ${item.val}`)
            //this.server_ext1_urlInput.current.value = item.val;
            this.state.server_ext1_url = item.val
          // 3. **Eksternal Server-2 Aktif Status
          } else if ( item.id === "server_ext2" ) {
            if ( item.val == 0 ) {
              this.server_ext2Input.current.checked = false;
              this.state.server_ext2 = false
            } else {
              this.server_ext2Input.current.checked = true;
              this.state.server_ext2 = true
            }
          // 4. Eksternal Server-2 path URL
          } else if ( item.id === "server_ext2_url" ) {
            //this.server_ext2_urlInput.current.value = item.val;
            this.state.server_ext2_url = item.val
          // 5. **Eksternal Server-3 Aktif Status
          } else if ( item.id === "server_ext3" ) {
            if ( item.val == 0 ) {
              this.server_ext3Input.current.checked = false;
              this.state.server_ext3 = false
            } else {
              this.server_ext3Input.current.checked = true;
              this.state.server_ext3 = true
            }
          // 6. Eksternal Server-3 path URL
          } else if ( item.id === "server_ext3_url" ) {
            //this.server_ext3_urlInput.current.value = item.val;
            this.state.server_ext3_url = item.val
          }
        })
      })
      .then( res => this.getExtServerOptions() )
			.catch(err => console.log(err))
  }

  setSettings = (id, new_val) => {
    let settings_id = undefined

    this.state.settings.map(item => {
      if ( item.id === id ) {
        console.log(`settings_id: ${item.settings_id} id: ${item.id} new_val: ${new_val}`)
        settings_id = item.settings_id
      }
    })
    fetch(API_URL + 'profile-system/update', {
			method: 'post',
			//headers: {'Content-Type': 'application/json'},
      headers: authHeader(),
			body: JSON.stringify({
				settings_id: settings_id,
				val: new_val
			})
		})
  }

  getExtServerOptions = () => {
    fetch(API_URL + `profile-system/ext-server`, {
      method: 'GET',
    })
    .then(async res => {
      this.setState({ 'ext_server_options': await res.json() })
    })
    .catch(err => console.log(err))
  }
  
  onChange = (e) => {
		this.setState({[e.target.name]: e.target.value})
  }

  onChangeServer_ext1 = (event) => {
		this.setState(initialState => {
			return { server_ext1: !initialState.server_ext1 }
		});
  }

  onChangeServer_ext2 = (event) => {
		this.setState(initialState => {
			return { server_ext2: !initialState.server_ext2 }
		});
  }

  onChangeServer_ext3 = (event) => {
		this.setState(initialState => {
			return { server_ext3: !initialState.server_ext3 }
		});
  }
  
  handleUpdateExt1Server = async() => {
    const user = JSON.parse(localStorage.getItem('user'));
		const username = user.username

    await fetch(API_URL + `profile-system/export-databases/${username}`, {
			method: 'GET',
			headers: authHeader()
		})
    .then(async response => {
      await alert("Database Ext-1 Server berhasil diUpdate!")
    })
    .catch(err => console.log(err))
  }

  render() {

    return(
      <Container className="App">
        <Row form>
          <Col md={8}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">Ext-1</InputGroupAddon>
               <Input type="select" name="server_ext1_url" onChange={this.onChange} bsSize="sm">
                {this.state.ext_server_options.map((option) => (
                  <option value={option.url} selected={option.url===this.state.server_ext1_url ? true : false}>{option.url}</option>
                ))}
              </Input>
{/*               <Input type="text" name="server_ext1_url" onChange={this.onChange}
                innerRef={this.server_ext1_urlInput} /> */}

              <InputGroupAddon addonType="append">
                <Button color="secondary" 
                onClick={() => { 
                  this.setSettings('server_ext1', this.state.server_ext1) 
                  this.setSettings('server_ext1_url', this.state.server_ext1_url) 
                  } }>Upd</Button>
                <InputGroupText>
                  <Input addon type="checkbox" name="server_ext1" onChange={this.onChangeServer_ext1}
                    innerRef={this.server_ext1Input} checked={this.state.server_ext1 == 0 ? false : this.state.server_ext1 } />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md={4}>
            <Button color="primary" onClick={this.handleUpdateExt1Server }>
              Update Ext-1 Server
            </Button>
          </Col>
        </Row>

        <Row form>
          <Col md={8}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">Ext-2</InputGroupAddon>
              <Input type="select" name="server_ext2_url" onChange={this.onChange} bsSize="sm">
                {this.state.ext_server_options.map((option) => (
                  <option value={option.url} selected={option.url===this.state.server_ext2_url ? true : false}>{option.url}</option>
                ))}
              </Input>
{/*               <Input type="text" name="server_ext2_url" onChange={this.onChange}
                innerRef={this.server_ext2_urlInput} /> */}

              <InputGroupAddon addonType="append">
                <Button color="secondary"
                  onClick={() => {
                    this.setSettings('server_ext2', this.state.server_ext2)
                    this.setSettings('server_ext2_url', this.state.server_ext2_url)
                  }}>Upd</Button>
                <InputGroupText>
                  <Input addon type="checkbox" name="server_ext2" onChange={this.onChangeServer_ext2}
                    innerRef={this.server_ext2Input} checked={this.state.server_ext2 == 0 ? false : this.state.server_ext2} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md={4}>
            <Button color="primary" onClick={this.handleUpdateExt1Server}>
              Update Ext-2 Server
            </Button>
          </Col>
        </Row>

        <Row form>
          <Col md={8}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">Ext-3</InputGroupAddon>
              <Input type="select" name="server_ext3_url" onChange={this.onChange} bsSize="sm">
                {this.state.ext_server_options.map((option) => (
                  <option value={option.url} selected={option.url===this.state.server_ext3_url ? true : false}>{option.url}</option>
                ))}
              </Input>
{/*               <Input type="text" name="server_ext3_url" onChange={this.onChange}
                innerRef={this.server_ext3_urlInput} /> */}

              <InputGroupAddon addonType="append">
                <Button color="secondary"
                  onClick={() => {
                    this.setSettings('server_ext3', this.state.server_ext3)
                    this.setSettings('server_ext3_url', this.state.server_ext3_url)
                  }}>Upd</Button>
                <InputGroupText>
                  <Input addon type="checkbox" name="server_ext3" onChange={this.onChangeServer_ext3}
                    innerRef={this.server_ext3Input} checked={this.state.server_ext3 == 0 ? false : this.state.server_ext3} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md={4}>
            { '' }
          </Col>
        </Row>
      </Container>
    )
  }
}