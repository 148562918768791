import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import MarketKonversiForm from '../Forms/MarketKonversi.Form'

class MarketKonversiModal extends Component {
	constructor(props) {
		super(props)
		this.state = { modal: false }
	}

	toggle = () => {
		this.setState(prevState => ({
			modal: !prevState.modal
		}))
	}

	render() {
		const closeBtn = <button className="close" onClick={this.toggle}> &times; </button>
		const label = this.props.buttonLabel
		let button = ''
		let title = ''

		if (label === "Edit") {
			button = 
				<Button color="warning"
					onClick={this.toggle}
					style={{ float: "left", marginRight: "10px"}} >
					{label}
				</Button>
				title = "Edit Konversi Market Pair"
		} else {
			button = 
				<Button color="success"
					onClick={this.toggle}
					style={{ float: "left", marginRight: "10px" }}>
						{label}
				</Button>
				title = "Add Konversi Market Pair"
		}

		return (
			<div>
				{button}
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
					<ModalHeader toggle={this.toggle} close={closeBtn}> {title}	</ModalHeader>
					<ModalBody>
						<MarketKonversiForm 
							addMarketKonversiToState={this.props.addMarketKonversiToState}
							updateMarketKonversiState={this.props.updateMarketKonversiState}
							getMarketsKonversi={this.props.getMarketsKonversi}
							toggle={this.toggle}
							market_konversi={this.props.market_konversi} />
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.toggle}>Submit</Button>
						<Button color="secondary" onClick={this.toggle}>Batal</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

}

export default MarketKonversiModal