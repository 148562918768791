import React, {Component} from 'react'
import { Table, Button } from 'reactstrap'
import WalletModal from '../Modals/Wallet.Modal'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

export default class WalletTable extends Component {

    deleteWallet = (id) => {
		let confirmDelete = window.confirm(`Hapus Wallet id (${id}) ?`)
		if ( confirmDelete ) {
			fetch(API_URL + `wallets/delete`,
				{
					method: 'delete',
					//headers: { 'Content-Type': 'application/json' },
                    headers: authHeader(),
					body: JSON.stringify({ 'wallet_id': id })
				}
			)
				.then(response => response.json())
				.then(item => { this.props.deleteWalletFromState(id) })
				.catch(err => console.log(`err: ${err}`))
		}
	}

    render() {
        const wallets = this.props.wallets.map(wallet => {
            return (
                <tr key={wallet.wallet_id}>
                    <th scope="row">{wallet.wallet_id}</th>
                    <td>{wallet.network}</td>
                    <td>{wallet.symbol}</td>
                    <td>{wallet.symbol_presisi}</td>
                    <td>{wallet.waktu}</td>
                    <td>{wallet.nama_wallet}</td>
                    <td>{wallet.aktif}</td>
                    <td>
                        <div style={{ width: "120px" }}>
                            <WalletModal buttonLabel="Edit" wallet={wallet} />
                            { ' ' }
                            <Button color="danger" onClick={() => this.deleteWallet(wallet.wallet_id)}> Del </Button>
                        </div>
                    </td>
                </tr>
            )
        })

        return (
            <Table responsive hover striped size="sm" dark>
                <thead>
                    <tr>
                        <th> # </th>
                        <th> Network </th>
                        <th> Symbol </th>
                        <th> Presisi </th>
                        <th> Waktu </th>
                        <th> Nama Wallet </th>
                        <th> Sts </th>
                        <th> AKSI </th>
                    </tr>
                </thead>
                <tbody>
                    {wallets}
                </tbody>
            </Table>
        )
    }

}