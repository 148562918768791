import React, { Component } from 'react'
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames';
import ProfileSettings from './ProfileSettings'
import ProfileUsersExchange from './ProfileUsersExchange'

export default class ProfileSystem extends Component {
	constructor(props) {
    super(props)
  }

  state = {
    activeTab: '1'
  }

  setActiveTab = tab => {
		this.setState({activeTab: tab})
	}
  
  render() {
    const toggleTab = tab => {
			if (this.state.activeTab !== tab) this.setActiveTab(tab);
    }
    
    return(
      <Container className="App">
        <br/>
        <Nav tabs>
          <NavItem>
            <NavLink style={{ cursor: "pointer", fontSize: 12, backgroundColor: this.state.activeTab === '1' ? 'Orange' : 'DarkGrey' }}
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { toggleTab('1'); }}>
              External Server
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink style={{ cursor: "pointer", fontSize: 12, backgroundColor: this.state.activeTab === '2' ? 'Orange' : 'DarkGrey' }}
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { toggleTab('2'); }}>
              Exchanges Config
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1" style={{ backgroundColor: 'Orange' }}>
            <ProfileSettings />
          </TabPane>

          <TabPane tabId="2" style={{ backgroundColor: 'Orange' }}>
            <ProfileUsersExchange />
          </TabPane>
        </TabContent> 
      </Container>
    )
  }
}