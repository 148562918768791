import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ExchangeForm from '../Forms/Exchange.Form'

class ExchangeModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modal: false
		}
	}

	toggle = () => {
		this.setState(prevState => ({
			modal: !prevState.modal
		}))
	}

	render() {
		const closeBtn = <button className="close" onClick={this.toggle}> &times; </button>
		const label = this.props.buttonLabel
		let button = ''
		let title = ''

		if (label === 'Edit') {
			button =
				<Button color="warning"
					onClick={this.toggle}
					style={{ float: "left", marginRight: "10px" }}>
					{label}
				</Button>
			title = "Edit Exchange"
		} else {
			button =
				<Button color="success"
					onClick={this.toggle}
					style={{ float: "left", marginRight: "10px" }}>
					{label}
				</Button>
			title = "Add Exchange"
		}

		return(
			<div>
				{button}
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
					<ModalHeader toggle={this.toggle} close={closeBtn} > {title} </ModalHeader>
					<ModalBody>
						<ExchangeForm
							addExchangeToState={this.props.addExchangeToState}
							updateExchangeState={this.props.updateExchangeState}
							toggle={this.toggle}
							exchange={this.props.exchange} />
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.toggle}>Submit</Button>{' '}
						<Button color="secondary" onClick={this.toggle}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

export default ExchangeModal