import React, { Component } from 'react'
import { Container, Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Button } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL

class KalkulatorKonversi extends Component {
    state = {
        btc:0,
        eth:0,
        bnb:0,
        matic:0,
        usdt:0,
        idr:0,
        waktu_bina:'',
        waktu_indo:'',

        bina_eth_btc_bid: 0,
        bina_eth_btc_ask: 0,
        bina_btc_usdt_bid: 0,
        bina_btc_usdt_ask: 0,
        bina_eth_usdt_bid: 0,
        bina_eth_usdt_ask: 0,
        bina_bnb_eth_bid: 0,
        bina_bnb_eth_ask: 0,
        bina_bnb_btc_bid: 0,
        bina_bnb_btc_ask: 0,
        bina_bnb_usdt_bid: 0,
        bina_bnb_usdt_ask: 0,
        indo_btc_idr_bid: 0,
        indo_btc_idr_ask: 0,
        indo_eth_idr_bid: 0,
        indo_eth_idr_ask: 0,
        indo_usdt_idr_bid: 0,
        indo_usdt_idr_ask: 0,
        indo_bnb_idr_bid: 0,
        indo_bnb_idr_ask: 0,
        indo_matic_idr_bid: 0,
        indo_matic_idr_ask: 0,
    }

    componentDidMount() {
	}

    getKalkulatorKonversi() {
		console.log(`===kalkulator-konversi getKalkulatorKonversi===`)
        fetch(API_URL + `kalkulator-konversi`, {
			method: 'GET',
			headers: authHeader()
		})
        .then(async res => { 
            const konversi = await res.json()
            konversi.map(item => {
                if ( item.exchange_id.toUpperCase()==="BINA" )
                    this.setState({"waktu_bina": item.waktu})
                else this.setState({"waktu_indo": item.waktu})

                if ( item.exchange_id.toUpperCase()==="BINA" && item.koin_pair.toUpperCase()==="ETH_BTC" ) {
                    this.setState({"bina_eth_btc_bid": item.bid})
                    this.setState({"bina_eth_btc_ask": item.ask})
                } else if ( item.exchange_id.toUpperCase()==="BINA" && item.koin_pair.toUpperCase()==="BTC_USDT" ) {
                    this.setState({"bina_btc_usdt_bid": item.bid})
                    this.setState({"bina_btc_usdt_ask": item.ask})
                } else if ( item.exchange_id.toUpperCase()==="BINA" && item.koin_pair.toUpperCase()==="ETH_USDT" ) {
                    this.setState({"bina_eth_usdt_bid": item.bid})
                    this.setState({"bina_eth_usdt_ask": item.ask})
                } else if ( item.exchange_id.toUpperCase()==="BINA" && item.koin_pair.toUpperCase()==="BNB_ETH" ) {
                    this.setState({"bina_bnb_eth_bid": item.bid})
                    this.setState({"bina_bnb_eth_ask": item.ask})
                } else if ( item.exchange_id.toUpperCase()==="BINA" && item.koin_pair.toUpperCase()==="BNB_BTC" ) {
                    this.setState({"bina_bnb_btc_bid": item.bid})
                    this.setState({"bina_bnb_btc_ask": item.ask})
                } else if ( item.exchange_id.toUpperCase()==="BINA" && item.koin_pair.toUpperCase()==="BNB_USDT" ) {
                    this.setState({"bina_bnb_usdt_bid": item.bid})
                    this.setState({"bina_bnb_usdt_ask": item.ask})

                } else if ( item.exchange_id.toUpperCase()==="INDO" && item.koin_pair.toUpperCase()==="BTC_IDR" ) {
                    this.setState({"indo_btc_idr_bid": item.bid})
                    this.setState({"indo_btc_idr_ask": item.ask})
                } else if ( item.exchange_id.toUpperCase()==="INDO" && item.koin_pair.toUpperCase()==="ETH_IDR" ) {
                    this.setState({"indo_eth_idr_bid": item.bid})
                    this.setState({"indo_eth_idr_ask": item.ask})
                } else if ( item.exchange_id.toUpperCase()==="INDO" && item.koin_pair.toUpperCase()==="USDT_IDR" ) {
                    this.setState({"indo_usdt_idr_bid": item.bid})
                    this.setState({"indo_usdt_idr_ask": item.ask})
                } else if ( item.exchange_id.toUpperCase()==="INDO" && item.koin_pair.toUpperCase()==="BNB_IDR" ) {
                    this.setState({"indo_bnb_idr_bid": item.bid})
                    this.setState({"indo_bnb_idr_ask": item.ask})
                }
                
                //console.log(`koin ${item.koin} / ${item.pair} / ${item.koin_pair} | ${item.ask} / ${item.bid}`)
            })
        })
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
    }

    idrChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });

        this.setState({'usdt': this.decimal9( e.target.value / this.state.indo_usdt_idr_bid ) })
        this.setState({'btc': this.decimal9( e.target.value / this.state.indo_btc_idr_bid ) })
        this.setState({'eth': this.decimal9( e.target.value / this.state.indo_eth_idr_bid ) })
        this.setState({'bnb': this.decimal9( e.target.value / this.state.indo_bnb_idr_bid ) })
/*         $("#txUsdt").val( ($("#txRupiah").val() / indo_usdt_idr_bid).toFixed(9) );
        $("#txBtc").val( ($("#txRupiah").val() / indo_btc_idr_bid).toFixed(9) );
        $("#txEth").val( ($("#txRupiah").val() / indo_eth_idr_bid).toFixed(9) );
        $("#txBnb").val( ($("#txRupiah").val() / indo_bnb_idr_bid).toFixed(9) ); */
    }

    usdtChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });

        this.setState({'idr': this.decimal9( e.target.value * this.state.indo_usdt_idr_ask ) })
        this.setState({'btc': this.decimal9( e.target.value / this.state.bina_btc_usdt_bid ) })
        this.setState({'eth': this.decimal9( e.target.value / this.state.bina_eth_usdt_bid ) })
        this.setState({'bnb': this.decimal9( e.target.value / this.state.bina_bnb_usdt_bid ) })
        /* $("#txRupiah").val( ($("#txUsdt").val() * indo_usdt_idr_ask).toFixed(9) );
        $("#txBtc").val( ($("#txUsdt").val() / bina_btc_usdt_bid).toFixed(9) );
        $("#txEth").val( ($("#txUsdt").val() / bina_eth_usdt_bid).toFixed(9) );
        $("#txBnb").val( ($("#txUsdt").val() / bina_bnb_usdt_bid).toFixed(9) ); */
    }

    btcChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });

        this.setState({'idr': this.decimal9( e.target.value * this.state.indo_btc_idr_ask ) })
        this.setState({'usdt': this.decimal9( e.target.value * this.state.bina_btc_usdt_ask ) })
        this.setState({'eth': this.decimal9( e.target.value / this.state.bina_eth_btc_bid ) })
        this.setState({'bnb': this.decimal9( e.target.value / this.state.bina_bnb_btc_bid ) })
        /* $("#txRupiah").val( ($("#txBtc").val() * indo_btc_idr_ask).toFixed(9) );
        $("#txUsdt").val( ($("#txBtc").val() * bina_btc_usdt_ask).toFixed(9) );
        $("#txEth").val( ($("#txBtc").val() / bina_eth_btc_bid).toFixed(9) );
        $("#txBnb").val( ($("#txBtc").val() / bina_bnb_btc_bid).toFixed(9) ); */
    }

    ethChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });

        this.setState({'idr': this.decimal9( e.target.value * this.state.indo_eth_idr_ask ) })
        this.setState({'usdt': this.decimal9( e.target.value * this.state.bina_eth_usdt_ask ) })
        this.setState({'btc': this.decimal9( e.target.value * this.state.bina_eth_btc_ask ) })
        this.setState({'bnb': this.decimal9( e.target.value / this.state.bina_bnb_eth_bid ) })
        /* $("#txRupiah").val( ($("#txEth").val() * indo_eth_idr_ask).toFixed(9) );
        $("#txUsdt").val( ($("#txEth").val() * bina_eth_usdt_ask).toFixed(9) );
        $("#txBtc").val( ($("#txEth").val() * bina_eth_btc_ask).toFixed(9) );
        $("#txBnb").val( ($("#txEth").val() / bina_bnb_eth_bid).toFixed(9) ); */
    }

    bnbChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });

        this.setState({'idr': this.decimal9( e.target.value * this.state.indo_bnb_idr_bid ) })
        this.setState({'usdt': this.decimal9( e.target.value * this.state.bina_bnb_usdt_ask ) })
        this.setState({'btc': this.decimal9( e.target.value * this.state.bina_bnb_btc_ask ) })
        this.setState({'eth': this.decimal9( e.target.value * this.state.bina_bnb_eth_bid ) })
        /* $("#txRupiah").val( ($("#txBnb").val() * indo_bnb_idr_bid).toFixed(9) );
        $("#txUsdt").val( ($("#txBnb").val() * bina_bnb_usdt_ask).toFixed(9) );
        $("#txBtc").val( ($("#txBnb").val() * bina_bnb_btc_ask).toFixed(9) );
        $("#txEth").val( ($("#txBnb").val() * bina_bnb_eth_bid).toFixed(9) ); */
    }

    maticChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });

        this.setState({'idr': this.decimal9( e.target.value * this.state.indo_bnb_idr_bid ) })
        this.setState({'usdt': this.decimal9( e.target.value * this.state.bina_bnb_usdt_ask ) })
        this.setState({'btc': this.decimal9( e.target.value * this.state.bina_bnb_btc_ask ) })
        this.setState({'eth': this.decimal9( e.target.value * this.state.bina_bnb_eth_bid ) })
        /* $("#txRupiah").val( ($("#txBnb").val() * indo_bnb_idr_bid).toFixed(9) );
        $("#txUsdt").val( ($("#txBnb").val() * bina_bnb_usdt_ask).toFixed(9) );
        $("#txBtc").val( ($("#txBnb").val() * bina_bnb_btc_ask).toFixed(9) );
        $("#txEth").val( ($("#txBnb").val() * bina_bnb_eth_bid).toFixed(9) ); */
    }

    decimal9 = (x) => parseFloat(x).toFixed(10)
    
    render() {

        return (
            <Container className="App">
                <Row>
                    <Col sm="12"> 
                        <Button color="info" onClick={ ()=> this.getKalkulatorKonversi() }>Refresh</Button> { ' ' }
                        { 'INDO ' + this.state.waktu_indo + ', BINANCE ' + this.state.waktu_bina }
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <InputGroup size="lg">
                            <InputGroupAddon addonType="prepend">B T C</InputGroupAddon>
                            <Input type="number" innerRef={this.btcInput} name="btc" onChange={this.btcChange} value={this.state.btc} />
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <InputGroup size="lg">
                            <InputGroupAddon addonType="prepend">E T H</InputGroupAddon>
                            <Input type="number" innerRef={this.ethInput} name="eth" onChange={this.ethChange} value={this.state.eth} />
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <InputGroup size="lg">
                            <InputGroupAddon addonType="prepend">B N B</InputGroupAddon>
                            <Input type="number" innerRef={this.bnbInput} name="bnb" onChange={this.bnbChange} value={this.state.bnb} />
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <InputGroup size="lg">
                            <InputGroupAddon addonType="prepend">MATIC</InputGroupAddon>
                            <Input type="number" innerRef={this.maticInput} name="matic" onChange={this.maticChange} value={this.state.matic} />
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <InputGroup size="lg">
                            <InputGroupAddon addonType="prepend">USDT</InputGroupAddon>
                            <Input type="number" innerRef={this.usdtInput} name="usdt" onChange={this.usdtChange} value={this.state.usdt} />
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <InputGroup size="lg">
                            <InputGroupAddon addonType="prepend">I D R</InputGroupAddon>
                            <Input type="number" innerRef={this.idrInput} name="idr" onChange={this.idrChange} value={this.state.idr} />
                        </InputGroup>
                    </Col>
                </Row>

            </Container>
        )
    }
}

export default KalkulatorKonversi