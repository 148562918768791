import React, { Component } from 'react'
import { Table, Button, Input, InputGroup } from 'reactstrap'
import MarketsScrapCountUp from '../Forms/MarketsScrapCountUp.Form'

class MarketsScrap extends Component {

	render() {

		const exchanges = this.props.exchanges.map(exchange => {
			return(
				<tr key={exchange.exchange_id}>
					<td scope="row" style={{ fontSize: 12, fontWeight: 'bold' }}>{exchange.exchange_id}</td>
					<td>
						<MarketsScrapCountUp exchange={exchange} setScraping={this.props.setScraping}
						 parentModal={this.props.parentModal} getConvertTo={this.props.getConvertTo} 
						 getScrapProfil={this.props.getScrapProfil} getNetwork={this.props.getNetwork} 
						 getServerExtUrl={this.props.getServerExtUrl}
						 exchanges={this.props.exchanges}
						 getDexag_eth_ask_exchange={this.props.getDexag_eth_ask_exchange}
						 getDexag_bsc_ask_exchange={this.props.getDexag_bsc_ask_exchange}
						 setScraping_dexag_ask={this.setScraping_dexag_ask}
						/>
					</td>
				</tr>
			)
		})

		return(
			<Table responsive hover striped size="sm" bordered>
				<thead>
					<tr align="center" style={{ fontSize: 12, fontWeight: 'bold'}}>
						<td>I D</td>
						<td>Timer</td>
					</tr>
				</thead>
				<tbody>
					{exchanges}
				</tbody>
			</Table>
		)
	}
}

export default MarketsScrap