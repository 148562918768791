import React from 'react'
import { withRouter } from "react-router-dom";
import { Container, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import './Css/login-form.css';

import Config from '../Config';
const API_URL = Config.API_URL

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: null,
            password: null
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
        e.preventDefault()
        //console.log(`username: ${this.state.username} password: ${this.state.password}`)
        fetch(API_URL + 'users/login',
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: this.state.username,
                    password: this.state.password
                })
            }
        )
        .then(async response => { 
            let output = await response.json()

            if ( output.accessToken ) {
                localStorage.setItem("user", JSON.stringify( output ));
                this.props.history.push("./");
                window.location.reload();
            }
        })
        .catch(err => console.log(`error login user: ${err}`))
      }

    render() {
        return (
            <Container className="Appx">

                <Form className="form" onSubmit={this.handleSubmit}>
                    <Col md={8}>
                        <FormGroup>
                            <Label>Username</Label>
                            <Input
                                type="text"
                                name="username"
                                id="username"
                                placeholder="Username ..." 
                                onChange={this.handleChange} />
                        </FormGroup>
                    </Col>
                    <Col md={8}>
                        <FormGroup>
                            <Label for="examplePassword">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="********"
                                onChange={this.handleChange} />
                        </FormGroup>
                    </Col>
                    <Col md={8}> <Button type="submit" color="primary">Login</Button> </Col>
                    
                </Form>

            </Container>
        )
    }
}

export default withRouter(Login)