import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import UsersTable from './Tables/Users.Table'
import UserModal from './Modals/Users.Modal.js'

import authHeader from '../services/auth-header';
import Config from '../Config';
const API_URL = Config.API_URL

export default class Users extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            users: []
        }
    }

    componentDidMount() {
        this.getUsers()
    }

    getUsers = () => {
		fetch(API_URL + 'users', {
			method: 'GET',
			headers: authHeader()
		})
			.then(response => response.json())
			.then(users => this.setState({users}))
			.catch(err => console.log(`err: ${err}`))
	}
	
	addUserToState = (user) => {
		this.setState(prevState => ( {
			users: [...prevState.users, user]
		}))
	}

    updateUsersState = (user) => {
		const UserIndex = this.state.users.findIndex(data => data.username === user.username)
		const newArray = [
			// desctructure all users from beginning to the indexed users
			...this.state.users.slice(0, UserIndex),
			// add the updated users to the array
			user,
			// add the rest of the users to the array from the index after the replaced user
			...this.state.users.slice(UserIndex + 1)
		]

		this.setState({users: newArray})
	}

    deleteUsersFromState = (id) => {
		const updatedUsers = this.state.users.filter(user => user.username !== id)
		this.setState({users: updatedUsers})
	}

    render() {
        return(
            <Container className="App">
                <Row>
                    <Col> <h3 style={{ margin: "20px 0" }}> Users Management</h3> </Col>
                </Row>
                <Row>
					<Col>
						<UsersTable users={this.state.users} 
							updateUsersState={this.updateUsersState}
							deleteUsersFromState={this.deleteUsersFromState} />
					</Col>
				</Row>
				<Row>
					<Col>
						<UserModal buttonLabel="Tambah User" addUserToState={this.addUserToState} />
						<br/>
					</Col>
				</Row>
            </Container>
        )
    }

}