import React from 'react'
import { Row, Col, Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Draggable, { DraggableCore } from "react-draggable";
import classnames from 'classnames';
import SimulasiArbitBeliForm from '../Forms/SimulasiArbitBeli.Form'
import SimulasiArbitJualForm from '../Forms/SimulasiArbitJual.Form'
import SimulasiArbitScanForm from '../Forms/SimulasiArbitScan.Form'
import SimulasiArbitAssetsForm from '../Forms/SimulasiArbitAssets.Form'

export default class ModalSimulasiArbit extends React.Component {

    state = { 
        modal: false, 
        activeTab: '1', 
        activeDrags: 0, 
        hargaBeli: 0, 
        jumlahBeli: 0, 
        totalBeli: 0 
    }

    setModal = (value) => this.setState({modal: value})
    toggle = () => { 
        this.setModal(!this.state.modal)
        if ( this.state.modal ) this.setState({'activeTab': '1'})
    }
    setActiveTab = (value) => this.setState({activeTab: value})
    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) this.setActiveTab(tab);
    }
    onStart = () => this.setState({activeDrags: ++this.state.activeDrags})
    onStop = () => this.setState({activeDrags: --this.state.activeDrags})
    setHargaBeli = (value) => this.setState({hargaBeli: value})
    setJumlahBeli = (value) => this.setState({jumlahBeli: value})
    setTotalBeli = (value) => this.setState({totalBeli: value})

    render() {
        const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
        const closeBtn = <button className="close" onClick={this.toggle}> &times; </button>
        let button =
            <h5>
                <Badge color="warning" onClick={this.toggle} style={{ cursor: "pointer" }}> SIMULASI </Badge>
            </h5>
        let title = 
            <div className="box no-cursor" style={{ cursor: "pointer" }}>
                <div className="box no-cursor">
                    <strong className="cursor">
                        <div style={{fontSize: 14, fontWeight: 'bold'}}>
                            {this.props.arbit.koin} ({this.props.arbit.pair_a}/{this.props.arbit.exchange_id_a}) {"=>"} +({this.props.arbit.pair_b}/{this.props.arbit.exchange_id_b})=opit {this.props.arbit.profit}%
                        </div>
                    </strong>
                </div>
            </div>

        return (
            <div>
                {button}
                
                <Draggable handle="strong" {...dragHandlers}>
                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle} close={closeBtn} tag="h6"> {title} </ModalHeader>
                    
                    <ModalBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink style={{ backgroundColor: this.state.activeTab === '1' ? 'LightGreen ' : 'Gainsboro', cursor: "pointer", fontSize: 12, fontWeight: 'bold' }}
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggleTab('1'); }}>
                                    { `BELI#R${this.props.arbit.ask_row}` }
                                    </NavLink>
                            </NavItem>
    
                            <NavItem>
                                <NavLink style={{ backgroundColor: this.state.activeTab === '2' ? 'Crimson ' : 'Gainsboro', cursor: "pointer", fontSize: 12, fontWeight: 'bold' }}
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggleTab('2'); }}>
                                    {`JUAL#R${this.props.arbit.bid_row}`}
                                    </NavLink>
                            </NavItem>
    
                            <NavItem>
                                <NavLink style={{ backgroundColor: this.state.activeTab === '3' ? 'LightSkyBlue ' : 'Gainsboro', cursor: "pointer", fontSize: 12, fontWeight: 'bold' }}
                                    className={classnames({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.toggleTab('3'); }}>
                                    SCAN
                                    </NavLink>
                            </NavItem>
    
                            <NavItem>
                                <NavLink style={{ backgroundColor: this.state.activeTab === '4' ? 'Orange ' : 'Gainsboro', cursor: "pointer", fontSize: 12, fontWeight: 'bold' }}
                                    className={classnames({ active: this.state.activeTab === '4' })}
                                    onClick={() => { this.toggleTab('4'); }}>
                                    ASSETS
                                    </NavLink>
                            </NavItem>
                        </Nav>
    
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1" style={{ backgroundColor: 'DarkGrey' }}>
                                <Row>
                                    <Col sm="12">
                                        <SimulasiArbitBeliForm arbit={this.props.arbit} settings={this.props.settings} 
                                            market_konversi={this.props.market_konversi} setHargaBeli={this.setHargaBeli}
                                            setJumlahBeli={this.setJumlahBeli} setTotalBeli={this.setTotalBeli} 
                                            settings_main={this.props.settings_main} />
                                    </Col>
                                </Row>
                            </TabPane>
    
                            <TabPane tabId="2" style={{ backgroundColor: 'DarkGrey' }}>
                                <Row>
                                    <Col sm="12">
                                        <SimulasiArbitJualForm arbit={this.props.arbit} settings={this.props.settings} 
                                            market_konversi={this.props.market_konversi} 
                                            jumlahBeli={this.state.jumlahBeli} totalBeli={this.state.totalBeli} />
                                    </Col>
                                </Row>
                            </TabPane>
    
                            <TabPane tabId="3" style={{ backgroundColor: 'DarkGrey' }}>
                                <Row>
                                    <Col sm="12">
                                        <SimulasiArbitScanForm url_explorer={this.props.arbit.url_explorer} />
                                    </Col>
                                </Row>
                            </TabPane>
    
                            <TabPane tabId="4" style={{ backgroundColor: 'DarkGrey' }}>
                                <Row>
                                    <Col sm="12">
                                        <SimulasiArbitAssetsForm arbit={this.props.arbit} settings={this.props.settings}
                                            market_konversi={this.props.market_konversi} hargaBeli={this.state.hargaBeli} 
                                            settings_main={this.props.settings_main} />
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
                </Draggable>
            </div>
        )
    }
}
