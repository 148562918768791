import React from 'react'
import { Badge, Table, Row, Col, Button, InputGroup, InputGroupAddon,
     InputGroupText, Input, FormText } from 'reactstrap'

import authHeader from '../../services/auth-header';
import Config from '../../Config';
const API_URL = Config.API_URL
let ws = null

class SimulasiArbitJualForm extends React.Component {
    constructor(props) {
        super(props)
        this.hargaInput = React.createRef();
        this.jumlahInput = React.createRef();
        this.totalConvertToInput = React.createRef();
        this.totalInput = React.createRef();
    }

    state = {
        arbit: [],
        orderbook: [],
        modal: 0,
        total_profit: 0,
        prosen_profit: 0,
        depo_sts: true,

        convert_to: '',
        network: '',
        dataFromServer: null
    }

    componentDidMount() {
        this.getSettings()
     }

    componentWillUnmount() {
        this.closeWS()
    }

     componentDidUpdate(prevProps) {
         if ( prevProps.jumlahBeli !== this.props.jumlahBeli ) {
            this.jumlahInput.current.value = this.props.jumlahBeli
         }
     }

     getOrderbook = () => {
        const exchange_id = this.props.arbit.exchange_id_b

        // CEX using websocket
        if ( exchange_id=="BINA" || exchange_id=="BINA_BSC") {
            this.closeWS()
            this.openWS()
        } else { // using rest-api
//////////////////
            const market = this.props.arbit.koin + '_' + this.props.arbit.pair_b
            let modal = this.jumlahInput.current.value, convert_to = this.props.arbit.convert_to, network = this.props.arbit.network
            const side =
                ((exchange_id === "1INC") || (exchange_id === "1INC_BSC") || (exchange_id === "OPOC") ||
                    (exchange_id === "OPOC_BSC") || (exchange_id === "KYBR") || (exchange_id === "PARA") ||
                    (exchange_id === "PARA_BSC")) ? 'ask' : 'bid'
            const user = JSON.parse(localStorage.getItem('user'));
            const username = user.username

            this.hargaInput.current.value = 'Proses'

            /* this.props.settings.map(item => {
                if (item.id === "convert_to") {
                    convert_to = item.val
                } else if (item.id === "network") {
                    network = item.val
                }
            }) */
            //ext/:username/modal/:modal/mkt/:market/:side/:convert_to/:network/:exchange_id
            fetch(API_URL + `ext/${username}/modal/${modal}/mkt/${market}/${side}/${convert_to}/${network}/${exchange_id}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(async orderbook => {
                    this.setState({ orderbook })

                    const objs = Object.entries(await orderbook)
                    objs.map(([k1, v1]) => {
                        if (k1 === "bids") {
                            const sum_row1 = this.decimal4(parseFloat(v1[0][2]))
                            const sum_row2 = this.decimal4(parseFloat(v1[0][2]) + parseFloat(v1[1][2]))
                            const sum_row3 = this.decimal4(parseFloat(v1[0][2]) + parseFloat(v1[1][2]) + parseFloat(v1[2][2]))

                            if (v1[0][1] >= parseFloat(this.jumlahInput.current.value))
                                this.hargaInput.current.value = v1[0][0]
                            else if (v1[1][1] >= parseFloat(this.jumlahInput.current.value))
                                this.hargaInput.current.value = v1[1][0]
                            else
                                this.hargaInput.current.value = v1[2][0]

                            this.handleHargaChange()

                        } else if (exchange_id === "BINA" || exchange_id === "BINA_BSC") {
                            if (k1 === "depo_sts") {
                                if (v1 === 1) this.setState({ 'depo_sts': true })
                                else this.setState({ 'depo_sts': false })

                                console.log("depo_sts ", v1, ' /', this.state.depo_sts)
                            }
                        }
                    });

                })
                .then(() => {
                    this.handleTotalConvertToChange(this.props.arbit.exchange_id_b, this.state.convert_to, this.props.arbit.pair_b)
                })
                .catch(err => console.log(err))
//////////////////
        }
        
    }

    openWS = () => {
        const exchange_id = this.props.arbit.exchange_id_b

        if ( exchange_id=="BINA" || exchange_id=="BINA_BSC" ) {
            const market = this.props.arbit.koin + this.props.arbit.pair_b

            if ( !ws || ws.readyState !== ws.OPEN  )
                //ws = new WebSocket('wss://stream.binance.com:9443/ws');
                ws = new WebSocket('wss://stream.binance.com:9443/ws/' + market.toLowerCase() + '@depth5@1000ms');

            const msg = {
                method: 'SUBSCRIBE',
                //params: ['btcusdt@depth'],
                params: [market.toLowerCase() +'@depth@1000ms'],
                id: 1,
            };

            ws.onopen = () => {
                console.log("connected");
                //ws.send(JSON.stringify(msg));
            }
    
            ws.onmessage = evt => {
                // listen to data sent from the websocket server
                //const message = JSON.parse(evt.data);
                const message = JSON.parse( evt.data );
                this.setState({ dataFromServer: message });
                //console.log('onmessage', message);
            }

            ws.onclose = () => {
                console.log('disconnected')
                // automatically try to reconnect on connection loss
            }
        }
        
    }

    closeWS = () => {
        if ( !!ws && ws.readyState === ws.OPEN  ) ws.close()
    }

    decimal9 = (x) => parseFloat(x).toFixed(10)
    decimal4 = (x) => Number.parseFloat(x).toFixed(3)

    getSettings = () => {
        /* this.props.settings.map(item => {
            if ( item.id === "convert_to" ) {
                this.setState({'convert_to': item.val})
            } else if ( item.id === "network") {
                this.setState({'network': item.val})
            }
        }) */
        this.setState({'convert_to': this.props.arbit.convert_to})
        this.setState({'network': this.props.arbit.network})
    }

    handleHargaChange = () => {
        try {
            //this.jumlahInput.current.value = this.decimal4( this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value) )
            //this.handleJumlahChange(this.props.arbit.exchange_id_b, this.state.convert_to, this.props.arbit.pair_b)
            ////////////
            //let totalInput = this.decimal4( this.hargaInput.current.value * this.jumlahInput.current.value )

            this.totalInput.current.value = this.decimal4( this.hargaInput.current.value * this.jumlahInput.current.value )
            //this.handleTotalConvertToChange(this.props.arbit.exchange_id_b, this.state.convert_to, this.props.arbit.pair_b) 
            this.handleTotalChange(this.props.arbit.exchange_id_b, this.state.convert_to, this.props.arbit.pair_b)
            this.hitungProfit()
            //console.log(`handleHargaChange: totalInput ${this.hargaInput.current.value} x ${this.jumlahInput.current.value}`)
        } catch (e) { 
            console.log(`err handleHargaChange: ${e}`) 
        }
    }

    handleJumlahChange = (exchange_id, market_koin, market_pair) => {
        try {
            const market_konversi = Object.entries(this.props.market_konversi)
            const network = this.state.network

            market_konversi.map( ([k1, v1]) => {
                if ( v1.exchange_id===exchange_id && v1.market_koin===market_koin && v1.market_pair===market_pair && v1.network.search(network)>=0 ) {
                    try {
                        this.totalInput.current.value = this.decimal4(this.hargaInput.current.value * this.jumlahInput.current.value )
                        this.totalConvertToInput.current.value = this.decimal4( this.decimal4(this.totalInput.current.value) / this.decimal9(v1.ask) )
                        this.setState({'modal': this.totalInput.current.value})
                    } catch (e) { console.log(`err change Total ${this.state.convert_to}: ${e}`)  }
                } else if ( v1.exchange_id===exchange_id && v1.market_pair===market_koin && v1.market_koin===market_pair && v1.network.search(network)>0 ) {
                    try {
                        this.totalInput.current.value = this.decimal4(this.hargaInput.current.value * this.jumlahInput.current.value )
                        this.totalConvertToInput.current.value = this.decimal4( this.decimal4(this.totalInput.current.value) * this.decimal9(v1.ask) )
                        this.setState({'modal': this.totalInput.current.value})
                    } catch (e) { console.log(`err change Total ${this.state.convert_to}: ${e}`)  }
                }
                
            })
        } catch (e) { console.log(`err handleJumlahChange ${e}`) }
    }

    handleTotalConvertToChange = (exchange_id, market_koin, market_pair) => {
        market_koin = market_koin.toUpperCase()
        market_pair = market_pair.toUpperCase()
        const market_konversi = Object.entries(this.props.market_konversi)
        const network = this.state.network

        market_konversi.map( ([k1, v1]) => {
            //console.log(`exchange_id: ${exchange_id} market_koin: ${market_koin} market_pair: ${market_pair} | v1.exchange: ${v1.exchange_id} v1.market_koin: ${v1.market_koin} v1.market_pair: ${v1.market_pair}`)
            if ( v1.exchange_id===exchange_id && v1.market_koin===market_koin && v1.market_pair===market_pair && v1.network.search(network)>=0 ) {
                try {
                    this.totalInput.current.value = this.decimal4(this.decimal4(this.jumlahInput.current.value) * this.decimal9(this.hargaInput.current.value))
                    //this.totalConvertToInput.current.value = this.decimal4(this.totalInput.current.value / this.decimal9(v1.ask))
                } catch (e) { 
                    console.log(`err handleTotalConvertToChange: ${e}`) 
                }
            } else if ( v1.exchange_id===exchange_id && v1.market_pair===market_koin && v1.market_koin===market_pair && v1.network.search(network)>=0 ) {
                try {
                    this.totalInput.current.value = this.decimal4(this.decimal4(this.jumlahInput.current.value) * this.decimal9(this.hargaInput.current.value))
                    //this.totalConvertToInput.current.value = this.totalInput.current.value
                } catch (e) { 
                    console.log(`err handleTotalConvertToChange: ${e}`) 
                }
            }
            
        })
    }

    handleTotalChange = (exchange_id, market_koin, market_pair) => {
        market_koin = market_koin.toUpperCase()
        market_pair = market_pair.toUpperCase()
        const market_konversi = Object.entries( this.props.market_konversi.filter(item => item.exchange_id==exchange_id && item.convert_to==this.props.arbit.convert_to) )
        const network = this.state.network
        
        market_konversi.map( ([k1, v1]) => {
            if ( v1.exchange_id===exchange_id && v1.market_koin===market_koin && v1.market_pair===market_pair && v1.network.search(network)>=0 ) {

                try {
                    this.totalConvertToInput.current.value = this.decimal4( this.decimal4(this.totalInput.current.value) / this.decimal9(v1.ask) )
                    //this.jumlahInput.current.value = this.decimal4( this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value) )
                } catch (e) { 
                    console.log(`err handleTotalChange: ${e}`) 
                }
            } else if ( v1.exchange_id===exchange_id && v1.market_pair===market_koin && v1.market_koin===market_pair && v1.network.search(network)>=0 ) {

                try {
                    this.totalConvertToInput.current.value = this.decimal4( this.decimal4(this.totalInput.current.value) * this.decimal9(v1.ask) )
                    //this.jumlahInput.current.value = this.decimal4( this.decimal4(this.totalInput.current.value) / this.decimal9(this.hargaInput.current.value) )
                } catch (e) { 
                    console.log(`err handleTotalChange: ${e}`) 
                }
            }
        })
        this.setState({'modal': this.totalInput.current.value})
    }

    getJumlahBeli = () => {
        this.jumlahInput.current.value = this.props.jumlahBeli
    }

    hitungProfit = () => {
        try {
            let profit_total = this.decimal4(this.totalConvertToInput.current.value - this.props.totalBeli)
            let profit_prosen = ((profit_total / this.props.totalBeli) * 100).toFixed(1)
            this.setState({total_profit: profit_total, prosen_profit: profit_prosen})
            console.log(`profit_total: ${profit_total} profit_prosen: ${profit_prosen}`)
        } catch (e) { console.log(`err hitungProfit: ${e}`) }
    }

     render() {
        let bid_price=0, bid_vol=0, exchange_id = this.props.arbit.exchange_id_b

        if ( this.props.arbit.bid_row==2 ) {
            bid_price = this.decimal9(this.props.arbit.bid2)
            bid_vol = this.decimal4( this.props.arbit.bid2_vol + this.props.arbit.bid1_vol )
        } else if ( this.props.arbit.bid_row==1 ) {
            bid_price = this.decimal9(this.props.arbit.bid1)
            bid_vol = this.decimal4( this.props.arbit.bid1_vol )
        }

        const objs = Object.entries(this.state.orderbook)
        let orderbook = null

        // CEX using websocket
        if ( exchange_id=="BINA" || exchange_id=="BINA_BSC" ) {
            if (!!this.state.dataFromServer && this.state.dataFromServer.hasOwnProperty('bids')) {
                let ask_count = 0, sum = 0;

                orderbook = this.state.dataFromServer.bids.map((item) => {
                    const item_price = parseFloat(item[0]).toFixed(9);
                    const item_amount = parseFloat(item[1]).toFixed(4);
                    const item_total = this.decimal4(parseFloat((item_price * item_amount).toFixed(4)));
                    sum += parseFloat(this.decimal4(item_total))
                    ask_count++;

                    if (item_amount > 0 && ask_count <= 3) {

                        return (
                            <tr align="right" key={ask_count} onClick={() => { this.hargaInput.current.value = item_price; this.handleHargaChange() }}
                                style={(sum >= this.totalInput.current.value) ? { backgroundColor: "Orange" } : {}}>
                                <td scope="row" align="right"> {ask_count} </td>
                                <td align="right"> {item_price} </td>
                                <td align="right"> {item_amount} </td>
                                <td align="right"> {item_total} </td>
                                <td align="right"> {this.decimal4(sum)} </td>
                            </tr>
                        )
                    }
                });

            }
        } else { // using rest-api
//////////////////
            orderbook = objs.map(([k1, v1]) => {
                if (k1 === "bids") {
                    const sum_row1 = this.decimal4(parseFloat(v1[0][2]))
                    const sum_row2 = this.decimal4(parseFloat(v1[0][2]) + parseFloat(v1[1][2]))
                    const sum_row3 = this.decimal4(parseFloat(v1[0][2]) + parseFloat(v1[1][2]) + parseFloat(v1[2][2]))

                    return (
                        <>
                            <tr align="right" key={0} onClick={() => { this.hargaInput.current.value = v1[0][0]; this.handleHargaChange() }}
                                style={(sum_row1 >= this.totalInput.current.value) ? { backgroundColor: "Orange" } : {}}>
                                <td scope="row"> {1} </td>
                                <td> {v1[0][0]} </td>
                                <td> {v1[0][1]} </td>
                                <td> {v1[0][2]} </td>
                                <td> {v1[0][2]} </td>
                            </tr>
                            <tr align="right" key={1} onClick={() => { this.hargaInput.current.value = v1[1][0]; this.handleHargaChange() }}
                                style={(sum_row2 >= this.totalInput.current.value) ? { backgroundColor: "Orange" } : {}}>
                                <td scope="row"> {2} </td>
                                <td> {v1[1][0]} </td>
                                <td> {v1[1][1]} </td>
                                <td> {v1[1][2]} </td>
                                <td> {this.decimal4(parseFloat(v1[0][2]) + parseFloat(v1[1][2]))} </td>
                            </tr>
                            <tr align="right" key={2} onClick={() => { this.hargaInput.current.value = v1[2][0]; this.handleHargaChange() }}
                                style={(sum_row3 >= this.totalInput.current.value) ? { backgroundColor: "Orange" } : {}}>
                                <td scope="row"> {3} </td>
                                <td> {v1[2][0]} </td>
                                <td> {v1[2][1]} </td>
                                <td> {v1[2][2]} </td>
                                <td> {this.decimal4(parseFloat(v1[0][2]) + parseFloat(v1[1][2]) + parseFloat(v1[2][2]))} </td>
                            </tr>
                        </>
                    )
                }
            })
//////////////////
        }

        return (
            <>
                <Row>
                    <Col md={12} className="text-center text-md-left">
                        <Badge color="danger"> {(this.props.arbit.bid_ag !== 'undefined') ? this.props.arbit.bid_ag : ''} </Badge>
                        <Table responsive hover striped size="sm" dark>
                            <thead>
                                <tr>
                                    <th> # </th>
                                    <th> Price </th>
                                    <th> Amount </th>
                                    <th> Total </th>
                                    <th> SUM(Total) </th>
                                </tr>
                            </thead>
                            <tbody key={10} style={{cursor: "pointer"}}>
                            { orderbook }
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Row form>
                    <Col md={12}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">Hrg</InputGroupAddon>
                        <Input innerRef={this.hargaInput} onChange={()=>this.handleHargaChange()} name="harga" />
                        <InputGroupAddon addonType="append">
							<InputGroupText> { bid_price } </InputGroupText>
						</InputGroupAddon>
                    </InputGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col md={12}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button color="primary" onClick={this.getJumlahBeli}>Jml</Button>
                        </InputGroupAddon>
                        <Input innerRef={this.jumlahInput} onChange={()=>this.handleHargaChange()} name="jumlah" />
                        <InputGroupAddon addonType="append">
							<InputGroupText> { bid_vol } </InputGroupText>
						</InputGroupAddon>
                    </InputGroup>
                    <FormText color="muted"> 
                        <b> { 'canDEPO: ' + this.state.depo_sts } </b> 
                    </FormText>
                    </Col>
                </Row>

                <Row form>
                    <Col md={12}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend"> 
                            <InputGroupText> {this.state.convert_to} </InputGroupText>
                        </InputGroupAddon>
                        <Input innerRef={this.totalConvertToInput} onChange={()=>this.handleTotalConvertToChange(this.props.arbit.exchange_id_b, this.state.convert_to, this.props.arbit.pair_b)} name="total_eth" />
                        <InputGroupAddon addonType="append">
							<InputGroupText> { this.props.arbit.pair_b } </InputGroupText>
						</InputGroupAddon>
                        <Input innerRef={this.totalInput} onChange={() => this.handleTotalChange(this.props.arbit.exchange_id_b, this.state.convert_to, this.props.arbit.pair_b)} name="total_jual" />
                        <InputGroupAddon addonType="append">
							<InputGroupText> { this.props.arbit.bid_vol_tot } </InputGroupText>
						</InputGroupAddon>
                    </InputGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={12} className="text-center text-md-right">
                        <span style={{fontSize: 13, fontWeight: 'bold'}}>{ `Opt ${this.state.total_profit}/${this.state.prosen_profit}% ` }</span>
                        <Button color="danger" onClick={ ()=> this.getOrderbook() }>OBs</Button> { ' ' }
                        <a href={this.state.orderbook.url_ext ? `${this.state.orderbook.url_ext}` : ``} target={'_blank'}>
                            <Button color="danger"> R </Button>
                        </a> { ' ' }
                        <a href={this.state.orderbook.url_api ? `${this.state.orderbook.url_api}` : ``} target={'_blank'}>
                            <Button color="danger"> A </Button>
                        </a> { ' ' }
                        <a href={this.state.orderbook.url_trade ? `${this.state.orderbook.url_trade}` : ``} target={'_blank'}>
                            <Button color="danger">M</Button>
                        </a>
                    </Col>
                </Row>
            </>
        )
     }
}

export default SimulasiArbitJualForm