import React, { Component } from 'react'
import { Container, Row, Col, Input, Button } from 'reactstrap'
import ExchangeMarketLogsTable from './Tables/ExchangeMarketLogs.Table'

import authHeader from '../services/auth-header';
import Config from '../Config';
const API_URL = Config.API_URL

export default class ExchangeMarketLogs extends Component {
	constructor(props) {
		super(props)
	}

	state = {
		marketLogs: [],
		koin: ''
	}

	componentDidMount() {

	}

	getMarketLogs = () => {
		console.log(`getMarketLogs-koin: ${this.state.koin}`)
		fetch(API_URL + `exchange-market-logs/${this.state.koin}`, {
			method: 'GET',
			headers: authHeader()
		})
			.then(response => response.json())
			.then(marketLogs => { this.setState({marketLogs: marketLogs}) })
			.catch(err => console.log(`err: ${err}`))
	}

	handleFilter = event => {
		this.setState({koin: event.target.value})
	}

	render() {
		return (
			<Container className="App">
				<Row>
					<Col md={8}>
						<Input type="text" onChange={this.handleFilter} placeholder="Cari Koin..." />
					</Col>
					<Col md={4}>
						<Button color="secondary" onClick={this.getMarketLogs}>Search Koin</Button>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<ExchangeMarketLogsTable marketLogs={this.state.marketLogs || []} getMarketLogs={this.getMarketLogs} ref="child" />
					</Col>
				</Row>
			</Container>
    )
	}
}